.ss {
    &-jjj {
        &-l {
            margin-bottom: 3rem;

            @media (min-width: 768px) {
                margin-bottom: 0;
            }
        }

        &-r {
            display: flex;
            flex-direction: column;

            @media (min-width: 768px) {
                align-items: flex-end;
            }

            .ss-v-subscribe-box {
                @media (min-width: 768px) {
                    max-width: 27rem;
                }
            }
        }

        &-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (min-width: 1200px) {
                padding-right: 6rem;
            }

            > :last-child {
                margin-bottom: 0;
            }

            > a {
                margin-bottom: 1.6rem;
            }
        }
    }
}
