.ss {
    &-eee {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-template {
            &-mainbox {
                padding: 2rem 3rem;
                border-radius: 2rem;
                background-color: $gray;

                @media (min-width: 992px) {
                    padding: 5rem 4.8rem;
                }

                .col,
                [class*=col-] {
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                }
            }

            &-box {
                padding: 0.9rem 1.4rem 3.2rem 1.4rem;
                height: 100%;
                background-color: $white;
                border-radius: 1rem;
                text-align: center;
                cursor: pointer;

                &:hover {
                    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.18);

                    .btn-light {
                        color: $white !important;
                        background-color: $gray-dark !important;
                        border-color: $gray-dark !important;
                    }
                }

                .h4 {
                    font-size: 1.8rem;
                }
            }

            &-imgbox {
                margin: 0 auto 0.4rem auto;
                width: 100%;
                max-width: 21rem;
                position: relative;
                display: block;

                &::before {
                    width: 100%;
                    padding-top: 71.42857142857143%;
                    content: '';
                    display: block;
                }
            }

            &-body {
                .btn-spacer {
                    padding-top: 1.1rem;

                    .btn {
                        @media (min-width: 1200px) {
                            min-width: 18rem;
                        }
                    }
                }
            }
        }
    }
}
