.lazy-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;

    &::before {
        opacity: 1;
        background-image: url(../images/loader-black.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40px 40px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        display: block;
        content: "";
        transition: opacity 1s;
    }

    .lazyload {
        opacity: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: block;
        transition: opacity 1s;
        object-fit: cover;
    }

    &.loaded {
        .lazyload {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }
    }

    &.white {
        &::before {
            background-image: url(../images/loader-white.svg);
        }
    }

    span.lazyload ~ img.lazyload {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &.contain {
        .lazyload {
            background-size: contain;
            object-fit: contain;
        }
    }

    &.no-loader {
        &::before {
            background-image: none !important;
        }
    }

    &.b-error {
        &::before {
            opacity: 0;
        }
    }

    &.static {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .lazyload {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
