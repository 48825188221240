.ss {
    &-h {
        &-head {
            margin-bottom: 5.4rem;

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-imgbox {
            margin-bottom: 3rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border: 0.1rem solid $gray-35;
            border-radius: 2rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 54.91228070175439%;
                content: '';
                display: block;
            }
        }

        &-desc {
            padding-bottom: 2.6rem;

            h3 {
                a {
                    color: $gray-300 !important;
                    text-decoration: none !important;
                }
            }
        }

        &-date {
            margin: 0;
            font-size: 1.8rem;
            line-height: 1.556;
            font-weight: 400;
            color: rgba($gray-300, 0.5);
            display: block;
        }

        &-btn-space {
            display: flex;
            justify-content: center;

            @media (min-width: 992px) {
                padding-top: 4rem;
            }
        }
    }
}
