.ss {
    &-f {
        &-imgbox {
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 2rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 60.08547008547009%;
                content: '';
                display: block;
            }
        }
    }
}
