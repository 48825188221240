.ss {
    &-m {
        &-head {
            margin-bottom: 5rem;

            @media (min-width: 1200px) {
                margin-bottom: 9.4rem;
            }

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-lmbox {
            margin: 1.5rem 0;
            padding: 2rem 1.6rem 0 1.6rem;
            border-radius: 1rem;
            background-color: rgba($gray-35, 0.35);
            display: flex;
            flex-direction: column;
            position: relative;

            @media (min-width: 576px) {
                min-height: 25.6rem;
                padding: 2.2rem 19rem 2.8rem 3rem;
            }

            @media (min-width: 992px) {
                margin: 2.5rem 0;
            }

            &-head {
                margin-bottom: 1.8rem;
                display: flex;
                align-items: center;

                .h4 {
                    margin: 0;
                }
            }

            &-desc {
                margin-bottom: 1rem;

                @media (min-width: 576px) {
                    max-width: 28rem;
                }
            }

            &-footer {
                margin-top: auto;

                .btn {
                    font-weight: 600;
                }
            }

            &-icon {
                margin-right: 1.3rem;
                width: 4.6rem;
                max-width: 4.6rem;
                flex: 0 0 4.6rem;
                height: 4.6rem;
                font-size: 2.7rem;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                background-color: $gray-100;

                @media (min-width: 1200px) {
                    margin-right: 2.3rem;
                }

                &.light-blue {
                    background-color: $light-blue;
                }

                &.light-green {
                    background-color: $light-green;
                }

                &.green {
                    background-color: $green;
                }
            }

            &-imgbox {
                margin: 2rem auto 0 auto;
                width: 100%;
                max-width: 16.6rem;
                position: relative;
                display: block;

                @media (min-width: 576px) {
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    right: 2.1rem;
                }

                &::before {
                    width: 100%;
                    padding-top: 144.578313253012%;
                    content: '';
                    display: block;
                }
            }
        }

        &-pattern {
            width: 100%;
            max-width: 15rem;
            display: block;
            top: 0;
            left: 0;
            position: absolute;

            @media (min-width: 768px) {
                max-width: 20rem;
            }

            &::before {
                width: 100%;
                padding-top: 94%;
                content: '';
                display: block;
            }
        }
    }
}
