.ss {
    &-i {
        background-color: $gray-300;
        
        .ss-section-bg {
            &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($black, 0.8);
                display: block;
                content: '';
            }
        }

        &.light {
            background-color: $gray-1500;

            .ss-i-features-row {
                color: $gray-300;
            }

            .ss-i-features-m p {
                font-weight: 600;
            }
        }

        .ss-section-inner {
            padding: 6.5rem 0;
        }

        &-features {
            &-row {
                display: flex;
                color: $white;
                align-items: center;
                flex-wrap: wrap;
            }

            &-l {
                font-size: 4.2rem;
                font-weight: 700;
                flex-shrink: 0;
                line-height: 0.9;
                align-self: flex-start;

                @media (min-width: 768px) {
                    font-size: 5.2rem;
                }
            
                @media (min-width: 992px) {
                    font-size: 6.4rem;
                }

                strong {
                    display: block;
                }
            }

            &-m {
                width: 1%;
                max-width: 75.7rem;
                flex: 1 1 auto;
                padding: 0 2.4rem;

                p {
                    @media (min-width: 1200px) {
                        font-size: 2.4rem;
                    }
                }
            }

            &-r {
                width: 100%;
                flex-shrink: 0;
                margin: 3rem 0 0 7rem;

                @media (min-width: 576px) {
                    width: auto;
                    margin: 0 0 0 auto;
                }

                .btn {
                    @media (min-width: 992px) {
                        min-width: 17rem;
                    }
                }
            }
        }
    }
}
