.ss {
    &-c {
        &-head {
            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-feature {
            &.reverse {
                @media (min-width: 992px) {
                    flex-direction: row-reverse;
                }
            }

            padding: 5rem 0;
            margin: 0 -1.6rem;
            display: flex;
            flex-direction: column;

            @media (min-width: 992px) {
                align-items: flex-end;
                flex-direction: row;
            }

            @media (min-width: 1200px) {
                margin: 0 -3.4rem;
                padding: 5.4rem 0 10.2rem 0;
            }

            &-cell {
                padding: 0 1.6rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 992px) {
                    padding: 0 3.4rem;
                    width: 50%;
                    max-width: 50%;
                    flex: 0 0 50%;
                }

                &.left {
                    @media (min-width: 992px) {
                        width: 56%;
                        max-width: 56%;
                        flex: 0 0 56%;
                    }

                    @media (min-width: 1200px) {
                        width: 64.62035541195477%;
                        max-width: 64.62035541195477%;
                        flex: 0 0 64.62035541195477%;
                    }
                }

                &.right {
                    padding-top: 3rem;

                    @media (min-width: 992px) {
                        padding-top: 0;
                        width: 44%;
                        max-width: 44%;
                        flex: 0 0 44%;
                    }

                    @media (min-width: 1200px) {
                        padding-top: 0;
                        width: 35.37964458804523%;
                        max-width: 35.37964458804523%;
                        flex: 0 0 35.37964458804523%;
                    }
                }

                h2 strong {
                    font-weight: 700;
                }

                .btn.btn-sm {
                    font-size: 1.6rem;
                }

                .ss-cc-btns {
                    margin: 0 -1.5rem;

                    .btn {
                        margin-bottom: 0.5rem;
                    }

                    .btn-link {
                        margin: 0 1.5rem;
                    }
                }
            }

            &-imgbox {
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;

                &::before {
                    width: 100%;
                    padding-top: 60.65573770491803%;
                    content: '';
                    display: block;
                }
            }

            &-badge {
                margin-bottom: 3rem;
                padding: 0.6rem 1.5rem 0.6rem 1.5rem;
                font-size: 1.4rem;
                font-weight: 600;
                border-radius: 0.3rem;
                text-transform: uppercase;
                display: inline-block;
            }
        }

        &-feature:last-child {
            padding-bottom: 0;
        }
    }
}
