.text {
    &-primary {
        color: $primary !important;
    }

    &-secondary,
    &-success {
        color: $secondary !important;
    }

    &-danger {
        color: $danger !important;
    }

    &-yellow,
    &-warning {
        color: $yellow !important;
    }

    &-gray,
    &-light {
        color: $gray !important;
    }

    &-dark {
        color: $gray-dark !important;
    }

    &-black {
        color: $black !important;
    }

    &-body {
        color: $body-color !important;
    }

    &-pink {
        color: $pink !important;
    }

    &-dark-orange {
        color: $dark-orange !important;
    }

    &-red,
    &-orange {
        color: $orange !important;
    }

    &-purple {
        color: $purple !important;
    }

    &-green {
        color: $green !important;
    }

    &-light-green {
        color: $light-green !important;
    }

    &-light-blue {
        color: $light-blue !important;
    }

    &-yellow-dark-2 {
        color: $yellow-dark-2 !important;
    }
}

.bg {
    &-primary,
    &-info {
        background-color: $primary !important;
    }

    &-success,
    &-secondary {
        background-color: $secondary !important;
    }

    &-yellow,
    &-warning {
        background-color: $yellow !important;
    }

    &-danger {
        background-color: $danger !important;
    }

    &-light,
    &-gray {
        background-color: $gray !important;
    }

    &-gray-2 {
        background-color: $gray-35 !important;
    }

    &-dark {
        background-color: $gray-dark !important;
    }

    &-black {
        background-color: $black !important;
    }

    &-body {
        background-color: $body-color !important;
    }

    &-primary60,
    &-info60 {
        background-color: rgba($primary, 0.06) !important;
    }

    &-pink {
        background-color: $pink !important;
    }

    &-pink60 {
        background-color: rgba($pink, 0.06) !important;
    }

    &-red,
    &-orange {
        background-color: $orange !important;
    }

    &-orange60 {
        background-color: rgba($orange, 0.06) !important;
    }

    &-dark-orange {
        background-color: $dark-orange !important;
    }

    &-dark-orange-2 {
        background-color: $dark-orange-100 !important;
    }

    &-dark-orange60 {
        background-color: rgba($dark-orange, 0.06) !important;
    }

    &-purple {
        background-color: $purple !important;
    }

    &-purple60 {
        background-color: rgba($purple, 0.06) !important;
    }

    &-green {
        background-color: $green !important;
    }

    &-lime-green {
        background-color: $lime-green !important;
    }

    &-green60 {
        background-color: rgba($green, 0.06) !important;
    }

    &-light-green {
        background-color: $light-green !important;
    }

    &-light-blue {
        background-color: $light-blue !important;
    }

    &-yellow-dark-2 {
        background-color: $yellow-dark-2 !important;
    }

    &-yellow-dark60-2 {
        background-color: rgba($yellow-dark-2, 0.06) !important;
    }

    &-soft-blue {
        background-color: $soft-blue !important;
    }

    &-gray-dark {
        background-color: $gray-dark !important;
    }

    &-primary-200 {
        background-color: $primary-200 !important;
    }
}

.w {
    &-1 {
        width: 1%;
    }
}

.fw {
    &-600 {
        font-weight: 600 !important;
    }
}

.mt {
    &-25 {
        margin-top: 2.5rem !important;
    }
}

.mb {
    &-20 {
        margin-bottom: 2rem !important;
    }
}

.opacity {
    &-10 {
        opacity: 0.1 !important;
    }

    &-25 {
        opacity: 0.25 !important;
    }

    &-50 {
        opacity: 0.5 !important;
    }

    &-75 {
        opacity: 0.75 !important;
    }

    &-100 {
        opacity: 1 !important;
    }
}

h1,
.h1 {
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        font-size: 4.8rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 992px) {
        font-size: 5.6rem;
    }

    @media (min-width: 1400px) {
        font-size: 6.4rem;
    }
}

h2,
.h2 {
    font-size: 3.2rem;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        font-size: 3.6rem;
        margin-bottom: 3rem;
    }

    @media (min-width: 1400px) {
        font-size: 4rem;
    }
}

h3,
.h3 {
    font-size: 2.6rem;
    line-height: 1.25;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        font-size: 3.2rem;
        margin-bottom: 3rem;
    }
}

h4,
.h4 {
    font-size: 2rem;
    line-height: 1.33;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        font-size: 2.4rem;
    }
}

h5,
.h5,
h6,
.h6 {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 2rem;
}

p {
    margin-bottom: 1.8rem;

    @media (min-width: 768px) {
        margin-bottom: 2.6rem;
    }
}

strong,
b {
    font-weight: 600;
}

.p {
    &1 {
        font-size: 1.6rem;
        line-height: 1.45;

        @media (min-width: 768px) {
            font-size: 1.8rem;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &2 {
        font-size: 1.4rem;
        line-height: 1.5;

        @media (min-width: 768px) {
            font-size: 1.6rem;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }
}

ol,
ul {
    padding-left: 3rem;
}

dl,
ol,
ul {
    margin-bottom: 1.8rem;

    @media (min-width: 768px) {
        margin-bottom: 2.6rem;
    }
}

.small {
    font-size: inherit;
}

.ss-cc {
    &-block {
        > :last-child {
            margin-bottom: 0;
        }
    }

    &-desc {
        margin-bottom: 2rem;

        @media (min-width: 768px) {
            margin-bottom: 3rem;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &-btns {
        display: flex;
        margin: 2rem 0 -2rem 0;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            margin: 3rem 0 -2rem 0;
        }

        .btn {
            margin: 0 2rem 2rem 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-head {
        text-align: center;

        h2 {
            margin-bottom: 2rem;
        }

        .ss-cc-desc {
            max-width: 54rem;
            margin: 0 auto;
        }
    }
}

.ss {
    &-section {
        margin: 5rem 0;

        @media (min-width: 992px) {
            margin: 10rem 0;
        }

        @media (min-width: 1200px) {
            margin: 15rem 0;
        }

        &.tb-space-b {
            margin: 5rem 0;

            @media (min-width: 1200px) {
                margin: 6.9rem 0;
            }
        }
    }

    &-hr {
        margin: 0;

        hr {
            margin: 0;
            border-color: $gray-800;
        }
    }

    &-post {
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;

        &.theme-b {
            .ss-entry-thumb::before {
                padding-top: 59.18918918918919%;
            }
        }
    }

    &-entry {
        &-thumb {
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 1rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 74.07407407407407%;
                content: '';
                display: block;
            }

            &:hover {
                .ss-entry-overlay {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-overlay {
            padding: 2.4rem;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($black, 0.6);
            transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
            opacity: 0;
            visibility: hidden;

            .btn {
                min-width: 17rem;
            }

            &.bottom-left {
                justify-content: flex-start;
                align-items: flex-end;
            }
        }

        &-author {
            margin-bottom: 1rem;
            font-size: 1.4rem;
            line-height: 1.286;
            font-weight: 600;
            color: $primary;
            text-transform: uppercase;
            display: block;
        }

        &-header {
            .h4 {
                margin-bottom: 1rem;

                & a {
                    color: $gray-300;
                    text-decoration: none;
                }
            }
        }

        &-body {
            padding: 2rem 0;
        }

        &-date {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &-text {
                margin-bottom: 0;
                font-size: 1.4rem;
                line-height: 1.429;
                font-weight: 400;
                color: rgba($gray-dark-400, 0.6);
                text-decoration: none !important;
                display: block;
            }
        }

        &-date-column {
            margin: 0 1rem;
            font-size: 1.4rem;
            line-height: 1.429;
            font-weight: 400;
            color: rgba($gray-dark-400, 0.6);
            display: block;
        }
    }
}

ul {
    &.checklist {
        padding-left: 0;

        &.primary {
            .checklist-icon {
                color: $primary;
            }
        }

        &.light-blue {
            .checklist-icon {
                color: $light-blue;
            }
        }

        &.purple {
            .checklist-icon {
                color: $purple;
            }
        }

        &.green {
            .checklist-icon {
                color: $green;
            }
        }

        &.dark-orange {
            .checklist-icon {
                color: $dark-orange;
            }
        }

        &.orange {
            .checklist-icon {
                color: $orange;
            }
        }

        &.pink {
            .checklist-icon {
                color: $pink;
            }
        }

        &.yellow-dark-2 {
            .checklist-icon {
                color: $yellow-dark-2;
            }
        }

        li {
            margin-bottom: 2.6rem;
            padding-left: 4.1rem;
            list-style: none;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .checklist-icon {
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2rem;
            top: 0.3rem;
            left: 0;
            position: absolute;
        }
    }
}

.badge {
    &-row {
        margin-bottom: 3rem;
    }

    &-box {
        padding: 0.6rem 1.3rem 0.6rem 1.3rem;
        font-size: 1.4rem;
        font-weight: 600;
        border-radius: 0.3rem;
        color: $gray-dark-400;
        text-transform: uppercase;
        display: inline-block;
        background-color: rgba($gray-35, 0.35);
    }
}

.resources {
    &-title-row {
        margin-bottom: 2.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            flex-direction: row;
        }

        h2 {
            margin-bottom: 1rem;

            @media (min-width: 768px) {
                margin-bottom: 0;
                margin-right: 2rem;
            }
        }
    }

    &-see-all-link {
        @media (min-width: 768px) {
            margin-left: auto;
        }
    }
}

.ss-breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 400;

    a {
        color: inherit;
        text-decoration: none !important;
        font-weight: 400;
    }

    .ss-icon-chevron-right {
        font-size: 0.75em;
        margin: 0 1.6rem;
    }
}

img {
    &.alignleft {
        max-width: 100%;
        height: auto;
        float: right;
        margin: 0 1.8rem 1.8rem 0;

        @media (min-width: 768px) {
            margin: 0 2.6rem 2.6rem 0;
        }
    }

    &.alignright {
        max-width: 100%;
        height: auto;
        float: right;
        margin: 0 0 1.8rem 1.8rem;

        @media (min-width: 768px) {
            margin: 0 0 2.6rem 2.6rem;
        }
    }

    &.aligncenter {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto 1.8rem auto;

        @media (min-width: 768px) {
            margin: 0 auto 2.6rem auto;
        }
    }
}
