.ss {
    &-bb {
        &-feature-row {
            margin-bottom: 3rem;

            > .col,
            > [class*=col-] {
                margin: 1.5rem 0;
            }
        }

        &-row-a {
            align-items: center;
            flex-direction: column-reverse;

            @media (min-width: 768px) {
                flex-direction: row;
            }

            @media (min-width: 992px) {
                flex-direction: column-reverse;
            }

            @media (min-width: 1200px) {
                flex-direction: row;
            }
        }

        &-box {
            padding: 3.2rem 3.4rem 3.8rem 3rem;
            background-color: $black;
            color: $white;
            border-radius: 1rem;
            overflow: hidden;
            text-align: center;

            @media (min-width: 768px) {
                text-align: left;
            }

            @media (min-width: 992px) {
                text-align: center;
            }

            @media (min-width: 1200px) {
                text-align: left;
            }

            h2 {
                margin-bottom: 0;
            }

            &-left {
                padding: 2rem 0;
            }

            &-imgbox {
                margin: 0 auto;
                width: 100%;
                max-width: 20.5rem;
                position: relative;
                display: block;
                border-radius: 1rem;
                background-color: $gray;

                @media (min-width: 1200px) {
                    margin: 0 0 0 auto;
                }

                &::before {
                    width: 100%;
                    padding-top: 125.8536585365854%;
                    content: '';
                    display: block;
                }

                &::after {
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    position: absolute;
                    top: -3rem;
                    left: -3rem;
                    border-radius: 1rem;
                    background-color: $white;
                }

                .lazy-loader {
                    z-index: 1;

                    .lazyload {
                        border-radius: 1rem;
                        overflow: hidden;
                    }
                }
            }
        }

        &-meta {
            margin-bottom: 5rem;
            font-size: 1.6rem;
            line-height: 1.75;
            font-weight: 700;
            color: $white;
            display: block;

            @media (min-width: 768px) {
                margin-bottom: 3rem;
            }
        }

        &-bedge {
            padding: 0.9rem 1.5rem 0.8rem 1.5rem;
            margin-bottom: 2.6rem;
            font-size: 1.4rem;
            line-height: 1.286;
            font-weight: 600;
            color: $white;
            text-transform: uppercase;
            display: inline-block;
            border-radius: 0.3rem;
            background-color: rgba($gray-35, 0.15);
        }

        .ss-post {
            @media (min-width: 768px) {
                padding-bottom: 0;
            }
        }

        &-link-topost {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 4;
        }

        &-link-totag {
            position: relative;
            z-index: 6;
        }
    }
}
