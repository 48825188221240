.ss {
    &-r {
        &-head {
            margin-bottom: 9rem;
            text-align: center;
        }

        &-ws-box {
            padding: 1.6rem 2rem;
            background-color: $gray-dark-500;
            border-radius: 2rem;

            @media (min-width: 992px) {
                padding: 2.6rem 2.5rem;
            }

            @media (min-width: 1200px) {
                padding: 4.6rem 3.5rem;
            }
        }

        &-list {
            margin: 0 -1.5rem;
            padding: 0;
            position: relative;
            display: flex;
            flex-wrap: wrap;

            @media (min-width: 1400px) {
                margin: 0 -3.5rem;
                padding: 0 3.5rem;
            }

            li::before {
                display: none;
            }

            &.pink {
                .ss-r-check-icon {
                    color: $pink !important;
                }
            }

            &-box {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
                padding: 0.5rem 1.5rem;
                position: relative;
                display: flex;
                font-size: 1.8rem;
                color: $gray-300;

                @media (min-width: 768px) {
                    width: 50%;
                    max-width: 50%;
                    flex: 0 0 50%;
                }

                @media (min-width: 1200px) {
                    width: 33.33%;
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                    padding: 1.3rem 1.5rem;
                }

                @media (min-width: 1400px) {
                    padding: 1.3rem 3.5rem;
                }
            }
        }

        &-check-icon {
            margin-top: 0.1rem;
            margin-right: 1.7rem;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2rem;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
