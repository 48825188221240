.ss {
    &-a {
        padding-bottom: 0.5rem;
        position: relative;

        &.reverse {
            .container {
                > .row {
                    @media (min-width: 992px) {
                        flex-direction: row-reverse;
                    }
                }
            }

            .ss-a-imgwrap {
                margin-right: 0;

                @media (min-width: 992px) {
                    margin-left: -10.5rem;
                }

                @media (min-width: 1400px) {
                    margin-left: -13.5rem;
                }
            }
        }

        &-bottom-b {
            content: '';
            display: block;
            height: 0.5rem;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: matrix(1, 0, 0, -1, 0, 0);

            &.blue-green {
                background: linear-gradient(102.96deg, #539cc1 28.13%, #6ecc52 112.33%);
            }

            &.purple {
                background: linear-gradient(102.96deg, #8462ee 28.13%, rgba(132, 98, 238, 0.156632) 112.33%);
            }

            &.green {
                background: linear-gradient(102.96deg, #64ba79 28.13%, rgba(100, 186, 121, 0.0698754) 112.33%);
            }

            &.dark-orange {
                background: linear-gradient(247.07deg, #d9925b 35.59%, rgba(217, 146, 91, 0.100169) 109.83%);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }

            &.red {
                background: linear-gradient(102.96deg, #e5574f 28.13%, rgba(229, 87, 79, 0.0752021) 110.69%);
            }

            &.light-green {
                background: linear-gradient(102.96deg, #58a5a9 28.13%, rgba(91, 161, 173, 0.0829873) 110.69%);
            }

            &.light-blue {
                background: linear-gradient(56.97deg, #539eb9 33.7%, #daf3ff 100%);
            }

            &.pink {
                background: linear-gradient(56.97deg, #df4469 33.7%, rgba(215, 70, 116, 0.286986) 100%);
            }

            &.yellow-dark-2 {
                background: linear-gradient(56.97deg, #fea114 33.7%, rgba(254, 162, 12, 0.286986) 100%);
            }
        }

        &-gredient {
            opacity: 0.16;
            height: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;

            &.purple {
                background: linear-gradient(180.56deg, rgba(132, 98, 238, 0.0001) 25.95%, rgba(132, 98, 238, 0.15) 71.9%, #8462ee 121.61%);
            }

            &.green {
                background: linear-gradient(180.56deg, rgba(100, 186, 121, 0.0001) 25.95%, rgba(100, 186, 121, 0.15) 71.9%, #64ba79 121.61%);
            }

            &.dark-orange {
                background: linear-gradient(180.56deg, rgba(217, 146, 91, 0.0001) 25.95%, rgba(220, 156, 107, 0.152262) 71.9%, #d9925b 121.61%);
            }

            &.red {
                background: linear-gradient(180.56deg, rgba(229, 87, 79, 0.0001) 25.95%, rgba(229, 87, 79, 0.15) 71.12%, #e5574f 121.61%);
            }

            &.light-green {
                background: linear-gradient(180.56deg, rgba(88, 165, 169, 0.0001) 25.95%, rgba(88, 165, 169, 0.15) 70.69%, #58a5a9 120.87%);
            }

            &.light-blue {
                background: linear-gradient(180.56deg, rgba(83, 158, 185, 0.0001) 25.95%, rgba(83, 158, 185, 0.15) 71.9%, #539eb9 121.61%);
            }

            &.pink {
                background: linear-gradient(180.56deg, rgba(223, 68, 105, 0.0001) 25.95%, rgba(223, 68, 105, 0.15) 71.6%, #df4469 121%);
            }

            &.yellow-dark-2 {
                background: linear-gradient(180.56deg, rgba(254, 161, 20, 0.0001) 25.95%, rgba(254, 161, 20, 0.15) 71.9%, #fea114 121.61%);
            }
        }

        &-left {
            padding: 5rem 0;

            .badge-row {
                margin-bottom: 1.8rem;
            }

            .h1 {
                @media (min-width: 768px) {
                    margin-bottom: 2.4rem;
                }

                strong {
                    font-weight: 700;
                }
            }

            p {
                max-width: 50.3rem;

                @media (min-width: 1200px) {
                    font-size: 2rem;
                }
            }

            .btn-spacer {
                @media (min-width: 992px) {
                    padding-top: 1.2rem;
                }

                .btn {
                    margin-right: 1rem;

                    @media (min-width: 768px) {
                        min-width: 13.9rem;
                        margin-right: 2.1rem;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &-right {
            width: 100%;
            padding: 0;
        }

        &-imgwrap {
            display: block;

            @media (min-width: 992px) {
                margin-right: -10.5rem;
            }

            @media (min-width: 1400px) {
                margin-right: -13.5rem;
            }
        }

        &-imgbox {
            width: 100%;
            position: relative;
            display: block;

            &::before {
                width: 100%;
                padding-top: 74.28571428571429%;
                content: '';
                display: block;
            }
        }

        &.product-intro {
            .ss-a-imgwrap {
                @media (min-width: 1200px) {
                    margin-right: -13.5rem;
                    display: block;
                }

                @media (min-width: 1400px) {
                    margin-right: -21.5rem;
                }
            }

            .ss-a-imgbox::before {
                padding-top: 62.45468669083376%;
            }
        }
    }
}
