.ss {
    &-u {
        border-bottom: 0;
        position: fixed;
        background-color: #fff;
        z-index: 100;
        box-shadow: 0 0 3rem rgba(68, 68, 68, 0.2);
        margin-top: 0 !important;
        width: 100%;
        top: 5.8rem;

        @media (min-width: 1200px) {
            top: 7.8rem;
        }

        &-nav-wrapper {
            overflow-x: auto;
            overflow-y: hidden;
        }

        &-nav {
            margin: 0;
            padding: 1.6rem 0;
            list-style: none;
            display: flex;

            &.tab-nav {
                > li {
                    > a {
                        font-weight: 400;
                        color: $gray-dark-400;

                        &.active {
                            font-weight: 700;
                        }
                    }
                }
            }

            @media (min-width: 992px) {
                justify-content: space-between;
                flex-wrap: wrap;
            }

            > li {
                margin: 0;
                padding: 0;
                list-style: none;
                display: block;
                white-space: nowrap;

                ::before {
                    display: none;
                }

                > a {
                    padding: 1rem;
                    font-size: 1.4rem;
                    line-height: 1.556;
                    font-weight: 600;
                    color: $gray-dark-400 !important;
                    display: block;
                    text-decoration: none !important;

                    &:not(.active):hover {
                        color: $primary !important;
                    }

                    @media (min-width: 992px) {
                        font-size: 1.6rem;
                    }

                    @media (min-width: 1400px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
