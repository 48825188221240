:root {
    scroll-behavior: auto;
}

html {
    font-size: 10px;
}

body,
html {
    position: relative;
    background-color: $white;
}

body {
    color: $body-color;
    font-size: 1.6rem;
    line-height: 1.45;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    min-width: 320px;

    @media (min-width: 768px) {
        font-size: 1.8rem;
    }
}

::-webkit-input-placeholder {
    color: lighten($black, 20%);
    opacity: 1;
}

:-moz-placeholder {
    color: lighten($black, 20%);
    opacity: 1;
}

::-moz-placeholder {
    color: lighten($black, 20%);
    opacity: 1;
}

:-ms-input-placeholder {
    color: lighten($black, 20%);
    opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

input:-webkit-autofill {
    transition: background-color 50000s ease-in-out, color 50000s ease-in-out;
}

a {
    color: $primary;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-weight: 600;
    transition: color 0.15s;
}

a:hover:not(.btn) {
    color: $primary;
    text-decoration: underline;
}

a.nostyle:hover {
    text-decoration: none;
}

.container {
    padding: 0 1.5rem;

    @media (min-width: 1400px) {
        max-width: 1200px;
    }
}

.row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.col,
[class*="col-"] {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

[class*="ss-icon-"] {
    font-size: 1em;
    width: 1em;
    display: block;
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &.ss-icon-logo,
    &.ss-icon-logo-colored {
        &::before {
            padding-top: 19.02%;
        }
    }

    svg,
    img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
}

.layout {
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding-top: 6.4rem;

    @media (min-width: 1200px) {
        padding-top: 8rem;
    }

    > :first-child:not([class*="ss-gc-"]) {
        margin-top: 0;
    }
}

.ss-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba($white, 0.75);
    background-image: url(../images/loader-black.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70px 70px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15s, opacity 0.15s;

    .loading & {
        visibility: visible;
        opacity: 1;
    }
}

.ss-section {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &.fs {
        &::before {
            width: 0;
            height: calc(100vh - 8rem);
            display: block;
            content: '';
        }
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 0;
    }

    &-inner {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        z-index: 1;
    }
}

.ss-caption {
    color: $primary;
}

.ss-spacer-wrapper {
    display: block;
    clear: both;
    width: 100%;
}

.pageid_108 .layout {
    padding-top: 14rem;

    @media (min-width: 768px) {
        padding-top: 20rem;
    }
}
