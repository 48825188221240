.ss {
    &-l {
        .ss-cc-desc {
            @media (min-width: 1200px) {
                max-width: 50rem;
            }
        }

        .ss-cc-block {
            h2 {
                @media (min-width: 1200px) {
                    max-width: 50rem;
                }
            }
        }

        .badge-row {
            margin-bottom: 2rem;
        }

        &-imgbox {
            margin: 0 auto 5rem auto;
            width: 100%;
            max-width: 57rem;
            position: relative;
            display: block;

            @media (min-width: 1200px) {
                margin: 0 0 0 -4.4rem;
            }

            &::before {
                width: 100%;
                padding-top: 100%;
                content: '';
                display: block;
            }

            img {
                border-radius: 2rem;
            }
        }

        .flex-row-reverse {
            .ss-l-imgbox {
                margin-left: 0;
            }
        }

        &-partner-form {
            padding-top: 4.5rem;

            .btn {
                min-width: 17rem;
            }
        }
    }
}
