.ss {
    &-header {
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 11;
        display: block;
        background-color: $white;

        &-inner {
            position: relative;
            border-bottom: 0.2rem solid $gray-500;
        }

        @media (max-width: 1199px) {
            max-height: 100%;
            overflow: auto;
        }

        .container {
            @media (max-width: 1199px) {
                max-width: 100%;
            }
        }

        .nav-overlay {
            width: 100%;
            height: 100%;
            background-color: rgba($white, 0.7);
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
            -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
            transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

            @media (min-width: 1200px) {
                display: none;
            }
        }

        &-content {
            padding: 1rem 0;

            @media (min-width: 1200px) {
                padding: 0;
            }

            .navbar {
                padding: 0;
                position: static;

                .nav-link {
                    color: $gray-300;
                    padding: 1.5rem;
                    font-size: 1.6rem;
                    font-weight: 400;
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    @media (min-width: 1200px) {
                        padding: 2.7rem 2rem 2.7rem 1.9rem;
                    }

                    &-arrow {
                        margin-left: auto;
                        width: 1rem;
                        font-size: 1rem;
                        display: block;
                        transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                        @media (max-width: 1199px) {
                            width: 5rem;
                            height: 5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            margin: -1.5rem -1.5rem -1.5rem auto;
                            flex-shrink: 0;
                        }

                        @media (min-width: 1200px) {
                            margin: 0 0 0 1rem;
                        }

                        .ss-icon-chevron-down {
                            pointer-events: none;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        color: $primary;
                    }

                    &.active {
                        color: $primary;
                        font-weight: 600;
                    }

                    &::after {
                        display: none;
                    }

                    &.show {
                        font-weight: 600;
                        color: $primary;

                        & .nav-link-arrow {
                            transform: rotate(180deg);
                        }
                    }

                    &.show & {
                        transform: rotate(90deg);
                    }
                }

                .navbar-collapse {
                    padding-top: 0;

                    @media (max-width: 1199px) {
                        display: block !important;
                        position: fixed;
                        height: calc(100% - 6.2rem);
                        width: 100%;
                        max-width: 34rem;
                        top: 6.4rem;
                        right: 0;
                        border-left: 0.2rem solid #eee;
                        background-color: $white;
                        overflow: hidden;
                        overflow-y: auto;
                        scrollbar-color: #0072cd #434243;
                        scrollbar-width: thin;
                        opacity: 0;
                        -webkit-transform: translateX(30%);
                        -ms-transform: translateX(30%);
                        transform: translateX(30%);
                        visibility: hidden;
                        -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
                        -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
                        transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                        &::-webkit-scrollbar {
                            width: 8px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: $gray;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $gray-100;
                        }

                        &.show {
                            opacity: 1;
                            -webkit-transform: translateX(0);
                            -ms-transform: translateX(0);
                            transform: translateX(0);
                            visibility: visible;
                        }
                    }

                    &-content {
                        padding: 1.5rem 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        @media (min-width: 1200px) {
                            padding: 0;
                            flex-direction: row;
                            align-items: center;
                        }
                    }
                }

                .dropdown {
                    position: static;
                }
            }
        }
    }

    &-dd-section {
        width: 100%;
        max-height: none;
        border: none;
        border-radius: 0;
        box-shadow: 0px 1.6rem 2.8rem -0.4rem rgba(0, 0, 0, 0.18);
        padding: 0;
        overflow: hidden;
        margin-top: 0rem !important;
        border-top: 0.2rem solid $gray-500;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: $gray;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-100;
        }

        @media (max-width: 1199px) {
            box-shadow: none;
            border-top: none;
        }
    }
}

.eda-logo {
    margin-right: 2rem;
    width: 13.77rem;
    display: block;
    max-height: 42px;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.nav-right {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        padding: 0;
        margin-left: auto;
        align-items: center;
        flex-direction: row;
    }

    .btn-primary {
        min-width: 14.6rem;
        padding: 0.7rem 1.2rem;
    }
}

.login-link {
    color: $gray-300;
    padding: 1.3rem 0;
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none !important;

    @media (min-width: 1200px) {
        padding: 1.3rem 2.2rem 1.2rem 2.2rem;
    }
}

.nav-toggle {
    &-btn {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media (min-width: 1200px) {
            display: none;
        }
    }

    &-bars {
        position: relative;
        display: block;
        width: 1.6rem;
        height: 1.2rem;

        .bar {
            transition: all 0.25s ease;
            height: 0.2rem;
            background-color: $black;
            position: absolute;

            &.top {
                top: 0;
                width: 50%;

                &.left {
                    transform-origin: 0 0;

                    .nav-expanded & {
                        transform: translate(2px, -1px) rotate(45deg) scaleX(1.25);
                    }
                }

                &.right {
                    transform-origin: 100% 0;

                    .nav-expanded & {
                        transform: translate(-2px, -1px) rotate(-45deg) scaleX(1.25);
                    }
                }
            }

            &.middle {
                width: 100%;
                top: 0.5rem;

                .nav-expanded & {
                    transform: scaleX(0);
                }
            }

            &.bottom {
                width: 50%;
                bottom: 0;

                &.left {
                    width: 60%;
                    transform-origin: 0 100%;

                    .nav-expanded & {
                        width: 50%;
                        transform: translate(2px, 2px) rotate(-45deg) scaleX(1.25);
                    }
                }

                &.right {
                    width: 40%;
                    transform-origin: 100% 100%;
                    background-color: transparent;

                    .nav-expanded & {
                        width: 50%;
                        background-color: $black;
                        transform: translate(-2px, 2px) rotate(45deg) scaleX(1.25);
                    }
                }
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }
}

.dd {
    &-content {
        &-cell {
            padding: 0;
            position: relative;

            @media (min-width: 1200px) {
                padding: 3.4rem 0 2.4rem 0;
            }

            &-inr {
                &.two-col {
                    @media (min-width: 1200px) {
                        column-count: 2;
                    }
                }
            }

            &-title {
                min-height: 2.6rem;
                margin-bottom: 2.3rem;
                padding-bottom: 0.6rem;
                border-bottom: 0.1rem solid $gray-600;
                position: relative;

                .h6 {
                    text-transform: uppercase;
                    margin-bottom: 0;
                    font-size: 1.4rem;
                }
            }

            &.width-bg {
                padding: 1.5rem 0;

                @media (min-width: 1200px) {
                    padding: 3.4rem 2rem 2.4rem 2rem;
                }

                &::before {
                    width: 1000rem;
                    pointer-events: none;
                    z-index: 0;
                    display: block;
                    content: '';
                    top: 0;
                    bottom: 0;
                    left: -1.5rem;
                    position: absolute;
                    background-color: $gray;
                }
            }
        }
    }

    &-items {
        margin-bottom: 2rem;
        padding: 0;
        display: inline-flex;
        color: $gray-dark;
        text-decoration: none !important;
        font-weight: 400;

        @media (min-width: 1200px) {
            margin-bottom: 1rem;
            padding: 1rem 0 0 0;
        }

        &-title-row {
            margin-bottom: 0.4rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .h5 {
                padding-right: 1rem;
                margin-bottom: 0;
                line-height: normal;
                color: $gray-300;
            }
        }

        &.active {
            .dd-items-title-row .h5 {
                color: $primary;
                font-weight: 600;
            }
        }

        &-icon {
            margin-right: 0.7rem;
            width: 2rem;
            height: 2rem;
            font-size: 1.2rem;
            border-radius: 0.3rem;
            background-color: $gray;
            color: $gray-dark;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            &.color-icon {
                font-size: 2rem;
            }
        }

        &-desc {
            flex: 1;

            p {
                font-size: 1.4rem;
                color: rgba($gray-dark-400, 0.85);
            }
        }

        &-badge {
            padding: 0.1rem 1rem 0.1rem 1rem;
            font-size: 1.2rem;
            font-weight: 600;
            border-radius: 0.3rem;
            background-color: $gray;
            color: $gray-dark-400;
        }
    }

    &-product {
        &-box {
            padding-top: 1rem;
            position: relative;
        }

        &-imgbox {
            margin-bottom: 1.2rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 0.6rem;
            overflow: hidden;
            background-color: $white;
            border: 0.1rem solid rgba($gray-inactive, 0.78);

            &::before {
                padding-top: 50.63829787234043%;
                content: '';
                display: block;
                max-width: 100%;
                width: 100%;
            }
        }

        &-desc {
            p {
                font-size: 1.4rem;
                margin-bottom: 0.9rem;
            }
        }

        &-link {
            display: inline-flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 400;
            color: $gray-300;
        }
    }

    &-more-icon {
        margin-left: 0.6rem;
        padding-top: 0.1rem;
        width: 2rem;
        height: 2rem;
        font-size: 1.4rem;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-highlight {
        &-box {
            margin-bottom: 1.5rem;
            padding: 1.7rem 1.2rem 0.8rem 1.6rem;
            background-color: $white;
            position: relative;
            border-radius: 1rem;
        }

        &-row-a {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: flex-start;
        }

        &-row-b {
            display: flex;
            align-items: center;
        }

        &-imgbox {
            width: 6rem;
            position: relative;
            display: block;
            border-radius: 0.6rem;
            overflow: hidden;
            border: 0.1rem solid rgba($gray-inactive, 0.78);

            &::before {
                padding-top: 100%;
                content: '';
                display: block;
                max-width: 100%;
                width: 100%;
            }
        }

        &-desc {
            padding-left: 1.5rem;
            flex: 1;

            p {
                font-size: 1.4rem;
                margin-bottom: 0;
            }
        }
    }

    &-news-lbl {
        font-size: 1.4rem;
        line-height: 1.286;
        font-weight: 600;
        color: $gray-inactive;
        text-transform: uppercase;
    }

    &-news-right {
        margin-left: auto;
    }
}

html.nav-expanded {
    body {
        overflow: hidden;
    }

    .nav-overlay {
        opacity: 1;
        visibility: visible;
    }
}
