.ss {
    &-n {
        &-pattern {
            width: 100%;
            max-width: 10rem;
            display: block;
            top: 100%;
            margin-top: -3rem;
            right: 0;
            position: absolute;

            @media (min-width: 992px) {
                margin-top: 1rem;
            }

            @media (min-width: 1200px) {
                margin-top: 4rem;
                max-width: 13.9rem;
            }

            &::before {
                width: 100%;
                padding-top: 69.7841726618705%;
                content: '';
                display: block;
            }
        }

        .ss-cc-block {
            margin-bottom: 3rem;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            h2 {
                @media (min-width: 992px) {
                    max-width: 40rem;
                    margin-bottom: 5.2rem;
                }
            }

            .ss-cc-desc {
                @media (min-width: 992px) {
                    max-width: 47rem;
                }
            }
        }

        &-service-form {
            .btn {
                width: 100%;
            }
        }
    }
}
