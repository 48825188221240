.ss {
    &-uu {
        &-back-row {
            padding: 5rem 0;

            @media (min-width: 992px) {
                padding: 6.2rem 0 5.6rem 0;
            }

            .btn {
                > i {
                    color: $primary;
                }
            }
        }

        &-bio {
            @media (min-width: 992px) {
                padding-right: 1.5rem;
            }

            h2 {
                margin-bottom: 0.7rem;
                color: $primary;
            }

            p {
                margin-bottom: 3rem;
            }

            &-left {
                margin-bottom: 3rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 768px) {
                    float: left;
                    width: calc(30% - 3rem);
                    max-width: 30%;
                    flex: 0 0 30%;
                    margin-right: 3rem;
                }

                @media (min-width: 1200px) {
                    width: calc(30% - 7rem);
                    margin-right: 7rem;
                }

                .ss-ll-nav {
                    padding-top: 0.8rem;
                }
            }

            &-right {
                margin-bottom: 3rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 768px) {
                    float: left;
                    width: 70%;
                    max-width: 70%;
                    flex: 0 0 70%;
                }
            }

            &-imgbox {
                margin: 0 0 1.3rem 0;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                border-radius: 2rem;
                overflow: hidden;
                background-color: $gray;

                &::before {
                    width: 100%;
                    padding-top: 97.16666666666667%;
                    content: '';
                    display: block;
                }
            }

            &-social {
                margin: 0 0rem 2.6rem 0rem;
                padding: 0;
                list-style: none;
                display: flex;

                @media (min-width: 1200px) {
                    padding-top: 2.3rem;
                }

                > li {
                    margin: 0.7rem 0.8rem 0.8rem 0.7rem;
                    padding: 0;
                    list-style: none;
                    display: block;

                    &:first-child {
                        margin-left: 0;
                    }

                    > a {
                        width: 5.1rem;
                        height: 5.1rem;
                        font-size: 2.6rem;
                        color: $gray-dark;
                        background-color: $gray;
                        border-radius: 0.6rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                        &:hover {
                            color: $white;
                            background-color: $gray-dark;
                        }
                    }
                }
            }
        }
    }
}
