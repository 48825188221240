.ss {
    &-pp {
        &-expert {
            padding: 0;
            background: rgba($gray-35, 0.35);
            border-radius: 2rem;

            & > .row {
                flex-direction: column-reverse;
                align-items: center;

                @media (min-width: 992px) {
                    flex-direction: row;
                }
            }

            &-imgbox {
                margin: 0 auto;
                width: 100%;
                max-width: 46.8rem;
                position: relative;
                display: block;

                @media (min-width: 992px) {
                    margin: 0;
                }

                &::before {
                    width: 100%;
                    padding-top: 116.2393162393162%;
                    content: '';
                    display: block;
                }
            }

            &-right {
                text-align: center;
                padding: 4rem 2rem;

                @media (min-width: 992px) {
                    text-align: left;
                    padding: 2rem 2rem 2rem 0;
                }

                @media (min-width: 1200px) {
                    padding-right: 7rem;
                }

                .badge-row {
                    margin-bottom: 2rem;
                }

                .btn {
                    min-width: 17.7rem;
                }
            }
        }
    }
}
