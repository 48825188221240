.ss {
    &-o {
        h2 {
            text-align: center;
        }

        &-list {
            &-group {
                max-width: 92rem;
                margin: 0 auto;
                padding-top: 1rem;

                @media (min-width: 1200px) {
                    padding-top: 2.9rem;
                }
            }

            &-row {
                position: relative;
                padding-left: 4rem;
                padding-bottom: 4.2rem;

                @media (min-width: 1200px) {
                    padding-left: 7.8rem;
                }

                &::before {
                    content: '';
                    display: block;
                    width: 0.1rem;
                    height: 100%;
                    position: absolute;
                    top: 0.2rem;
                    left: 1.3rem;
                    background-color: $gray-50;

                    @media (min-width: 1200px) {
                        left: 2.3rem;
                    }
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                .h4 {
                    margin-bottom: 1.4rem;
                }
            }
        }

        &-ractangle {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.3rem;
            transform: rotate(45deg);
            position: absolute;
            top: 0.5rem;
            left: 0;
            display: block;

            @media (min-width: 1200px) {
                width: 3.5rem;
                height: 3.5rem;
                left: 0.6rem;
                top: 0rem;
            }
        }
    }
}
