.ss {
    &-ccc {
        flex-direction: column;

        &-sec-a {
            padding: 9.5rem 0 6.8rem 0;

            .btn-spacer {
                margin-top: 3.7rem;
            }
        }

        &-eda-logo {
            margin: 0 auto 3rem auto;
            width: 100%;
            max-width: 16.9rem;
            position: relative;
            display: block;

            @media (min-width: 992px) {
                margin-bottom: 5.6rem;
            }

            &::before {
                width: 100%;
                padding-top: 18.93491124260355%;
                content: '';
                display: block;
            }
        }

        &-sec-b {
            width: 100%;
            position: relative;

            &-bg {
                transform: translateY(-50%);
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                display: block;

                &-one {
                    width: 100%;
                    height: 2rem;
                    display: block;

                    @media (min-width: 992px) {
                        height: 4.8rem;
                    }
                }

                &-two {
                    width: 100%;
                    height: 6.6rem;
                    display: block;

                    @media (min-width: 992px) {
                        height: 13.3rem;
                    }
                }
            }

            &-imgbox {
                margin: 0 auto;
                width: 100%;
                max-width: 97rem;
                position: relative;
                display: block;

                &::before {
                    width: 100%;
                    padding-top: 57.62886597938144%;
                    content: '';
                    display: block;
                }
            }
        }
    }
}
