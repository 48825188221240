.ss {
    &-tt {
        &.tt-b {
            .ss-cc-head {
                margin-bottom: 15.9rem;
            }
        }

        &-expert {
            &-group {
                .ss-tt-expert-row:last-child {
                    margin-bottom: 0;
                }
            }

            &-row {
                margin-bottom: 5rem;

                @media (min-width: 992px) {
                    margin-bottom: 8rem;
                }

                @media (min-width: 1200px) {
                    margin-bottom: 10rem;
                }

                @media (min-width: 1400px) {
                    margin-bottom: 14.9rem;
                    padding-top: 0.6rem;
                }
            }

            &-box {
                padding: 2rem 0;

                @media (min-width: 992px) {
                    padding: 3rem 0 1.5rem 0;
                }

                @media (min-width: 1200px) {
                    padding: 4rem 0 2rem 0;
                }

                @media (min-width: 1400px) {
                    padding: 5.2rem 0 3.1rem 0;
                }

                &:hover .ss-tt-overlay {
                    opacity: 1;
                    overflow: visible;
                }
            }

            &-imgwrap {
                position: relative;
                cursor: pointer;
            }

            &-imgbox {
                margin-bottom: 2.3rem;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                border-radius: 2rem;
                overflow: hidden;
                background-color: $gray;

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-category {
                margin-bottom: 0;
                font-size: 1.8rem;
                line-height: 1.45;
                font-weight: 400;
                color: rgba($gray-300, 0.5);
                display: block;
            }

            &-info {
                > div {
                    @media (min-width: 768px) {
                        min-height: 12rem;
                    }
                }

                .h4 {
                    margin-bottom: 1rem;
                }

                hr {
                    margin: 1.5rem 0;
                }
            }
        }

        &-overlay {
            width: 100%;
            height: 100%;
            padding: 2rem;
            top: 0rem;
            left: 0rem;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            overflow: hidden;
            border-radius: 2rem;
            background: rgba(0, 0, 0, 0.7);
            transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
        }

        &-social {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            > li {
                margin: 0.6rem;
                padding: 0;
                list-style: none;
                display: block;

                > a {
                    width: 4rem;
                    height: 4rem;
                    font-size: 3.3rem;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                    &:hover {
                        color: #6cceff;
                    }
                }
            }
        }

        &-readlink {
            margin-bottom: 0;
            font-size: 1.4rem;
            line-height: 1.429;
            font-weight: 600;
            color: $primary;
            display: block;
        }
    }
}
