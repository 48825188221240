.ss {
    &-ddd {
        &-platform {
            margin: 5rem 0;
            background: rgba($gray-35, 0.25);
            border-radius: 2rem;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            @media (min-width: 992px) {
                margin: 10rem 0;
                flex-direction: row;
            }

            @media (min-width: 1200px) {
                margin: 14rem 0;
            }

            &-group {
                .ss-ddd-platform:last-child {
                    margin-bottom: 0;
                }
            }

            &.reverse {
                @media (min-width: 992px) {
                    flex-direction: row-reverse;
                }

                .ss-ddd-platform-box-pattern {
                    left: auto;
                    right: -0.8rem;

                    @media (min-width: 992px) {
                        right: -1.2rem;
                    }
                }
            }

            &-left {
                padding: 3rem;

                @media (min-width: 768px) {
                    padding: 3rem 4rem;
                }

                @media (min-width: 992px) {
                    flex: 1;
                }

                @media (min-width: 1200px) {
                    padding: 3.8rem 7.1rem;
                }

                .ss-aaa-list {
                    padding: 1.9rem 0 0 0;
                }

                .ss-aaa-list-box {
                    padding: 0.7rem 1rem 0.8rem 1rem;
                }
            }

            &-right {
                padding: 3rem 3rem 0 3rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                position: relative;

                @media (min-width: 992px) {
                    padding: 4rem 4rem 0 4rem;
                }

                @media (min-width: 992px) {
                    padding: 0;
                    width: 38.2rem;
                    max-width: 38.2rem;
                    flex: 0 0 38.2rem;
                }

                @media (min-width: 1200px) {
                    width: 51.2rem;
                    max-width: 51.2rem;
                    flex: 0 0 51.2rem;
                }
            }

            &-box-pattern {
                width: 100%;
                top: 1.5rem;
                bottom: 1.5rem;
                left: -0.8rem;
                position: absolute;
                border-radius: 2rem;

                @media (min-width: 992px) {
                    left: -1.2rem;
                }
            }

            &-imgwrap {
                position: relative;

                @media (min-width: 992px) {
                    margin: -2.5rem 0;
                }
            }

            &-imgbox {
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                background-color: $gray;
                border: 0.1rem solid $gray-dark-1200;
                border-radius: 2rem;
                overflow: hidden;
                cursor: pointer;

                &::before {
                    width: 100%;
                    padding-top: 78.125%;
                    content: '';
                    display: block;
                }

                &:hover {
                    .ss-ddd-platform-overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &-overlay {
                background: rgba($black, 0.75);
                border-radius: 2rem;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
                -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
                transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                .btn {
                    min-width: 17rem;
                }
            }
        }
    }
}
