.ss {
    &-rr {
        .ss-cc-head {
            text-align: center;
            margin-bottom: 2rem;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }

        &-team {
            &-box {
                margin-top: 2rem;
                display: flex;
                align-items: center;

                @media (min-width: 1200px) {
                    margin-top: 6.4rem;
                }
            }

            &-avtar {
                margin-right: 2rem;
                width: 5rem;
                height: 5rem;
                flex-shrink: 0;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                background-color: $gray;

                @media (min-width: 1200px) {
                    width: 8rem;
                    height: 8rem;
                }

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-desc {
                flex: 1;

                > strong {
                    font-size: 1.8rem;
                    line-height: 1.333;
                    font-weight: 600;
                    color: $gray-300;
                    display: block;
                    text-decoration: none;

                    @media (min-width: 1200px) {
                        font-size: 2rem;
                    }

                    @media (min-width: 1200px) {
                        font-size: 2.4rem;
                    }
                }

                > span {
                    font-size: 1.4rem;
                    line-height: 1.556;
                    font-weight: 400;
                    color: rgba($gray-300, 0.5);
                    display: block;
                    text-decoration: none;

                    @media (min-width: 1200px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
