.ss {
    &-k {
        background-color: rgba($gray-1100, 0.11);

        .ss-section-inner {
            padding: 5rem 0;

            @media (min-width: 992px) {
                padding: 7rem 0;
            }

            @media (min-width: 1200px) {
                padding: 12.5rem 0 12.8rem 0;
            }
        }

        &-head {
            text-align: center;
            margin-bottom: 4.4rem;

            h2 {
                margin-bottom: 2rem;
            }

            .ss-cc-desc {
                max-width: 54rem;
                margin: 0 auto;
            }
        }

        &-btn-spacer {
            padding-top: 3.9rem;
            display: flex;
            justify-content: center;

            .btn {
                min-width: 15.2rem;
            }
        }
    }
}
