.ss {
    &-qq {
        .ss-cc-block {
            padding: 3rem 0;

            @media (min-width: 992px) {
                max-width: 42.6rem;
                padding: 4rem 0;
            }

            @media (min-width: 1400px) {
                padding: 7.3rem 0;
            }
        }

        &-advising {
            padding: 3rem 2rem;
            background: $gray-35;
            border-radius: 3rem;

            @media (min-width: 992px) {
                padding: 3rem;
            }

            @media (min-width: 1200px) {
                padding: 4rem;
            }

            @media (min-width: 1400px) {
                padding: 6.7rem 7.7rem 5.6rem 7.7rem;
            }

            &-cell {
                padding: 0.7rem 0.8rem 0.8rem 0.7rem;
                width: 50%;
                max-width: 50%;
                flex: 0 0 50%;

                @media (min-width: 576px) {
                    width: 33.33%;
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                }
            }

            &-logos {
                display: flex;
                flex-wrap: wrap;
            }

            &-logo {
                margin: 0;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                background-color: $white;
                box-shadow: 0px 0.1rem 0.2rem rgba(0, 0, 0, 0.18);
                border-radius: 1rem;
                overflow: hidden;

                > .lazy-loader {
                    padding: 1.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > .lazyload {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-btnrow {
                padding-top: 2rem;
                display: flex;
                justify-content: center;

                @media (min-width: 1400px) {
                    padding-top: 5.4rem;
                }

                .btn {
                    min-width: 17rem;
                }
            }
        }
    }
}
