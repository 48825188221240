.ss {
    &-ff {
        .btn {
            margin: 3rem auto;
            display: block;
            max-width: 17rem;

            @media (min-width: 576px) {
                margin: 0;
                max-width: 35.4rem;
            }
        }

        @media (min-width: 1400px) {
            min-width: 35.4rem;
        }

        &-row {
            flex-direction: column-reverse;
            align-items: center;

            @media (min-width: 576px) {
                flex-direction: row;
            }
        }

        &-imgwrap {
            margin-right: -11.5rem;
        }

        &-imgbox {
            width: 100%;
            position: relative;
            display: block;

            &::before {
                width: 100%;
                padding-top: 49.42857142857143%;
                content: '';
                display: block;
            }
        }
    }
}
