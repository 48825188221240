.ss {
    &-kk {
        background-color: $gray-dark-700;

        .ss-section-inner {
            padding: 5rem 0;

            @media (min-width: 992px) {
                padding: 6rem 0;
            }

            @media (min-width: 1200px) {
                padding: 8.1rem 0 7.7rem 0;
            }
        }

        &-box {
            max-width: 80rem;
            padding: 3rem 2rem;
            background-color: $white;
            box-shadow: 0px 0.1rem 2.2rem rgba(0, 0, 0, 0.037205);
            border-radius: 2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (min-width: 768px) {
                padding: 3rem;
                flex-direction: row;
            }

            @media (min-width: 992px) {
                padding: 7rem 5.1rem 7.3rem 6.5rem;
            }

            &-left {
                margin-bottom: 3rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 768px) {
                    margin-bottom: 0;
                    width: 37rem;
                    max-width: 37rem;
                    flex: 0 0 37rem;
                }
            }

            &-right {
                flex: 1;

                @media (min-width: 768px) {
                    padding-left: 3rem;
                }

                @media (min-width: 992px) {
                    padding-left: 7.2rem;
                }
            }
        }

        &-form {
            .ss-checkradio-text {
                font-size: 1.8rem;
                color: $gray-300;
            }

            .btn {
                width: 100%;
            }
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: block;

            > li {
                margin: 0 0 2rem 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;

                @media (min-width: 992px) {
                    margin: 0 0 3.8rem 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-icon {
                flex-shrink: 0;
                margin-right: 1.9rem;
                width: 4rem;
                height: 4rem;
                font-size: 1.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                background-color: rgba($primary, 0.1);
                border-radius: 50%;
            }

            &-text {
                font-size: 1.8rem;
                line-height: 1.556;
                font-weight: 400;
                color: $gray-300;
                display: block;
            }
        }
    }
}
