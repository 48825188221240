.ss {
    &-fff {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-report {
            display: flex;
            flex-direction: column;

            @media (min-width: 992px) {
                flex-direction: row;
            }

            &-left {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 992px) {
                    width: 40rem;
                    max-width: 40rem;
                    flex: 0 0 40rem;
                }

                @media (min-width: 1200px) {
                    width: 50rem;
                    max-width: 50rem;
                    flex: 0 0 50rem;
                }
            }

            &-right {
                flex: 1;
                display: flex;
                flex-direction: column;

                @media (min-width: 992px) {
                    padding-left: 3rem;
                }

                @media (min-width: 1400px) {
                    padding-left: 6.9rem;
                }
            }

            &-imgbox {
                margin: 0 auto 4rem auto;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                border-radius: 2rem;
                overflow: hidden;
                background-color: $gray;
                border: 0.1rem solid $gray-1600;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                }

                &::before {
                    width: 100%;
                    padding-top: 81.8%;
                    content: '';
                    display: block;
                }
            }

            &-tabs {
                margin: 0 -1.5rem 1rem -1.5rem;
                flex-wrap: wrap;

                @media (min-width: 1200px) {
                    margin-bottom: 1.5rem;
                }

                @media (min-width: 1400px) {
                    margin-bottom: 3.5rem;
                }

                .nav-item {
                    margin: 0 1rem 1.5rem 1rem;

                    @media (min-width: 1400px) {
                        margin: 0 1.5rem 1.5rem 1.5rem;
                    }
                }

                .nav-link {
                    padding: 1.2rem 1.5rem;
                    flex-shrink: 0;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 1.5;
                    text-transform: uppercase;
                    color: $gray-300;
                    border-radius: 1rem;
                    background-color: $gray;
                    border: none;
                    outline: none;

                    @media (min-width: 1200px) {
                        padding: 1.4rem 1rem;
                        width: 18rem;
                        font-size: 1.6rem;
                    }

                    @media (min-width: 1400px) {
                        padding: 1.8rem 1rem;
                    }

                    &.active {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            &-pane {
                > .btn-spacer {
                    padding-top: 1rem;

                    @media (min-width: 1200px) {
                        padding-top: 2rem;
                    }

                    @media (min-width: 1400px) {
                        padding-top: 4.6rem;
                    }

                    > .btn {
                        min-width: 20rem;
                    }
                }
            }
        }
    }
}
