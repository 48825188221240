.ss {
    &-bbb {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-slider {
            &-wrap {
                margin-top: 8rem;
                position: relative;

                @media (min-width: 1200px) {
                    margin-top: 0;
                }
            }

            &-box-bg {
                display: block;
                width: 100%;
                max-width: 52.7rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0.15;
                pointer-events: none;
                z-index: 0;

                @media (min-width: 768px) {
                    opacity: 0.35;
                }

                @media (min-width: 1200px) {
                    opacity: 1;
                }
            }

            &-left {
                margin-bottom: 3rem;
                padding: 3rem;
                border-radius: 2rem;
                position: relative;

                @media (min-width: 768px) {
                    padding: 5rem;
                }

                @media (min-width: 992px) {
                    margin-bottom: 0;
                }

                .ss-cc-block {
                    > h3 {
                        margin-bottom: 2rem;
                    }
                }

                &-inr {
                    max-width: 33.5rem;
                }
            }

            &-right {
                height: 100%;
                padding: 3rem;
                background: rgba($gray-35, 0.15);
                border-radius: 2rem;
                display: flex;
                flex-direction: column;

                @media (min-width: 768px) {
                    padding: 5rem 2rem 3.5rem 2rem;
                }

                @media (min-width: 1200px) {
                    padding: 7.9rem 3rem 3.5rem 3rem;
                }

                .ss-cc-desc {
                    p {
                        @media (min-width: 1200px) {
                            font-size: 2.4rem;
                        }
                    }
                }

                .btn-spacer {
                    margin-top: auto;

                    .btn {
                        margin: 0 auto;
                        width: 100%;
                        max-width: 37rem;
                    }
                }
            }

            &-arrow {
                z-index: 11;
                margin-top: -5rem;
                top: 0;
                width: 5rem;
                height: 5rem;
                font-size: 2.6rem;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (min-width: 992px) {
                    top: 50%;
                    margin-top: -2.5rem;
                }

                &.prev {
                    left: 40%;
                    margin-left: -5rem;

                    @media (min-width: 992px) {
                        left: -3.2rem;
                        margin-left: 0;
                    }

                    @media (min-width: 1200px) {
                        left: -5.2rem;
                    }

                    @media (min-width: 1367px) {
                        left: -9.2rem;
                    }
                }

                &.next {
                    right: 40%;
                    margin-right: -5rem;

                    @media (min-width: 992px) {
                        right: -3.2rem;
                        margin-right: 0;
                    }

                    @media (min-width: 1200px) {
                        right: -5.2rem;
                    }

                    @media (min-width: 1367px) {
                        right: -9.2rem;
                    }
                }
            }
        }

        &-list {
            margin: 0;
            padding: 2.5rem 0 3.6rem 0;
            position: relative;
            display: flex;
            flex-wrap: wrap;

            li::before {
                display: none;
            }

            &-box {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
                padding: 0.7rem 0 0.8rem 0;
                position: relative;
                display: flex;
                font-size: 1.6rem;
                line-height: 1.75;
                font-weight: 600;
            }
        }

        &-check-icon {
            margin-top: 0.1rem;
            margin-right: 1.7rem;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2rem;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-eda-logo {
            margin: 0 auto 3rem auto;
            width: 100%;
            max-width: 16.9rem;
            position: relative;
            display: block;

            @media (min-width: 992px) {
                margin-bottom: 6.5rem;
            }

            &::before {
                width: 100%;
                padding-top: 18.93491124260355%;
                content: '';
                display: block;
            }
        }

        &-thing-text {
            font-size: 1.8rem;
            line-height: 1.333;
            color: $gray-300;

            @media (min-width: 1200px) {
                font-size: 2.4rem;
            }
        }
    }
}
