.ss {
    &-ggg {
        .ss-section-bg {
            background-color: $primary;

            &::after {
                width: 100%;
                height: 100%;
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(49, 144, 193, 0.896361), rgba(49, 144, 193, 0.896361));
            }
        }

        .ss-section-inner {
            padding-top: 4rem;
            padding-bottom: 4rem;

            @media (min-width: 768px) {
                padding-top: 8rem;
                padding-bottom: 10.5rem;
            }

            @media (min-width: 1200px) {
                padding: 12.2rem 0 12.9rem 0;
            }
        }

        &-content {
            max-width: 77rem;
            margin: 0 auto;

            .ss-icon-quote-left {
                font-size: 5.2rem;
                margin-bottom: 1.9rem;

                @media (min-width: 768px) {
                    font-size: 7.7rem;
                }
            }

            .blockquote {
                font-size: 2.8rem;
                line-height: 1.25;
                font-weight: 600;
                margin: 0;

                @media (min-width: 768px) {
                    font-size: 3.2rem;
                }
            }

            .blockquote-footer {
                font-size: 1.6rem;
                line-height: 1.33;
                text-transform: uppercase;
                color: $white;
                font-weight: 600;
                margin-top: 3.2rem;

                @media (min-width: 1200px) {
                    margin-top: 6.2rem;
                }

                &::before {
                    display: none;
                }

                cite {
                    font-size: 1.4rem;
                    line-height: 1.556;
                    display: block;
                    font-weight: 400;
                    color: rgba($white, 0.5);
                    text-transform: none;
                    font-style: normal;
                    margin-top: 0;

                    @media (min-width: 768px) {
                        font-size: 1.6rem;
                    }

                    @media (min-width: 768px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
