.ss {
    &-oo {
        &-row-a {
            padding: 3rem 0;

            @media (min-width: 992px) {
                padding: 5rem 0;
            }

            @media (min-width: 1200px) {
                padding: 10rem 0;
            }
        }

        .ss-cc-head {
            margin-bottom: 2rem;

            @media (min-width: 992px) {
                margin-bottom: 4rem;
            }

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-imgbox {
            margin: 0 0 3rem 0;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 2rem;
            overflow: hidden;
            background-color: $gray;

            @media (min-width: 768px) {
                margin-bottom: 0;
            }

            &::before {
                width: 100%;
                padding-top: 94%;
                content: '';
                display: block;
            }
        }

        &-list {
            margin: 0;
            padding: 1.3rem 0 0 0;
            list-style: none;
            display: block;

            > li {
                margin: 0 0 2rem 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: flex-start;

                @media (min-width: 1200px) {
                    margin: 0 0 3.2rem 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-icon {
                margin-right: 1.6rem;
                flex-shrink: 0;
                width: 4rem;
                height: 4rem;
                font-size: 3.3rem;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 1200px) {
                    margin-right: 2.1rem;
                }
            }

            &-desc {
                > h4 {
                    margin-bottom: 0.7rem;
                }

                > p {
                    color: rgba($gray-300, 0.5);
                }
            }
        }

        &.m-b-no-title {
            margin-top: -5rem;

            @media (min-width: 992px) {
                margin-top: -10rem;
            }

            @media (min-width: 1200px) {
                margin-top: -15rem;
            }
        }
    }
}
