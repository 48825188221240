.ss {
    &-d {
        &-content {
            padding: 2rem;
            background-color: $gray-300;
            border-radius: 2rem;

            @media (min-width: 992px) {
                padding: 6.6rem 2rem 7.2rem 2rem;
            }

            .ss-cc-block {
                max-width: 57rem;
                margin: 0 auto;
                color: $white;
                text-align: center;

                @media (min-width: 992px) {
                    margin-bottom: 3rem;
                }

                h2 {
                    margin-bottom: 2rem;
                }
            }
        }

        &-form {
            max-width: 77rem;
            margin: 0 auto;
            padding-top: 2.7rem;

            > .row {
                > .col {
                    @media (max-width: 767px) {
                        width: 100%;
                        flex: 0 0 auto;
                    }
                }
            }

            .btn {
                border-radius: 0.6rem;

                @media (min-width: 992px) {
                    min-width: 17rem;
                }
            }
        }
    }
}
