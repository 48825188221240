.ss {
    &-w {
        &-article {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 45px;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            &.theme-b {
                @media (min-width: 576px) {
                    flex-direction: row;
                }

                .ss-w-imgbox {
                    @media (min-width: 576px) {
                        max-width: 17rem;
                        margin: 0 3rem 0 0;
                    }
                }

                .h4 {
                    font-size: 1.8rem;
                }
            }

            &-group {
                @media (min-width: 1200px) {
                    padding-left: 3rem;
                }

                .ss-w-article {
                    padding: 2rem 0;
                    border-bottom: 0.1rem solid rgba($gray-800, 0.5);

                    @media (max-width: 575px) {
                        border-top: 0.1rem solid rgba($gray-800, 0.5);
                    }

                    @media (min-width: 1200px) {
                        padding: 3rem 0;
                    }

                    &:first-child {
                        @media (min-width: 992px) {
                            padding-top: 0;
                        }
                    }

                    &:last-child {
                        @media (max-width: 991px) {
                            padding-bottom: 0;
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        &-imgbox {
            margin-bottom: 1.7rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 0.9rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 64.03508771929825%;
                content: '';
                display: block;
            }
        }

        &-desc {
            h3 {
                margin-bottom: 1rem;

                a {
                    color: $gray-300 !important;
                    text-decoration: none !important;
                }
            }

            &-meta {
                margin-bottom: 0;
                font-size: 1.4rem;
                line-height: 1.429;
                font-weight: 400;
                color: rgba($gray-dark-400, 0.6);
                display: block;
            }
        }

        &-hr-lbl {
            margin-bottom: 1rem;
            font-size: 1.4rem;
            line-height: 1.429;
            font-weight: 600;
            color: $primary;
            display: block;
        }
    }
}
