.ss {
    &-xx {
        &-additional {
            margin: 0 -1.5rem;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            > li {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0 0 1.5rem 0;
                padding: 0 1.5rem;
                display: block;
                list-style: none;

                @media (min-width: 576px) {
                    width: 50%;
                    max-width: 50%;
                    flex: 0 0 50%;
                }

                @media (min-width: 992px) {
                    width: 33.33%;
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                }

                @media (min-width: 1400px) {
                    width: 25%;
                    max-width: 25%;
                    flex: 0 0 25%;
                }
            }

            &-lbl {
                font-size: 1.8rem;
                line-height: 1.556em;
                font-weight: normal;
                color: $gray-dark-400 !important;
                text-decoration: none !important;
                display: block;
            }
        }
    }
}
