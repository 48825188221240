.ss {
    &-p {
        background-color: $gray-300;

        .container {
            max-width: 1440px;
        }

        .ss-section-inner {
            padding: 5rem 0;

            @media (min-width: 992px) {
                padding: 6.1rem 0 7rem 0;
            }
        }

        h2 {
            margin-bottom: 2rem;
            color: $white;
            text-align: center;

            @media (min-width: 1200px) {
                margin-bottom: 7rem;
            }
        }

        &-slider {
            border-radius: 2rem;

            .swiper-slide {
                height: auto;
            }

            &-wrap {
                padding: 0 2rem;
                position: relative;

                @media (min-width: 1200px) {
                    padding: 0 10rem;
                }

                @media (min-width: 1400px) {
                    padding: 0 13.5rem;
                }
            }

            &-arrow {
                width: 2rem;
                font-size: 2.6rem;
                height: 100%;
                top: 0;
                border-radius: 1.9rem;
                position: absolute;
                z-index: 11;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 992px) {
                    width: 3rem;
                }

                @media (min-width: 1200px) {
                    width: 7.4rem;
                    background-color: rgba($white, 0.1);
                }

                @media (min-width: 1400px) {
                    width: 9.4rem;
                }

                &.next {
                    right: -1rem;

                    @media (min-width: 1400px) {
                        right: 0;
                    }
                }

                &.prev {
                    left: -1rem;

                    @media (min-width: 1400px) {
                        left: 0;
                    }
                }
            }

            &-box {
                height: 100%;
                padding: 2rem 4rem 0 4rem;
                background: #fff;
                box-shadow: -0.5rem 0px 0.9rem -0.3rem rgba(0, 0, 0, 0.180288);
                border-radius: 1.9rem;

                @media (min-width: 576px) {
                    display: flex;
                }

                @media (min-width: 992px) {
                    padding: 2rem 2rem 0 2rem;
                }

                @media (min-width: 1400px) {
                    padding: 2rem 3.1rem 0rem 4.6rem;
                }

                .ss-cc-block {
                    padding-top: 1.3rem;
                    display: flex;
                    flex-direction: column;

                    .btn {
                        margin-top: auto;
                        margin-bottom: 3.7rem;
                        align-self: flex-start;
                    }
                }
            }

            &-imgbox {
                margin: 2rem auto 0 auto;
                width: 100%;
                max-width: 13rem;
                position: relative;
                align-self: flex-end;
                display: none;

                @media (min-width: 576px) {
                    margin: 0 0 0 auto;
                }

                @media (min-width: 992px) {
                    display: block;
                }

                @media (min-width: 1200px) {
                    max-width: 17.7rem;
                }

                &::before {
                    width: 100%;
                    padding-top: 144.578313253012%;
                    content: '';
                    display: block;
                }
            }

            .checklist {
                li {
                    font-size: 1.4rem;
                    margin-bottom: 0.4rem;
                    color: rgba($gray-dark-400, 0.6);
                }
            }
        }

        &-solutions-btn-wrap {
            padding-top: 5rem;
            display: flex;
            justify-content: center;

            @media (min-width: 992px) {
                padding-top: 6.5rem;
            }

            .btn {
                color: $white;
                border-color: $white;

                i {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
