.ss {
    &-ll {
        &-box {
            background: $gray-dark-500;
            border-radius: 1rem;
            overflow: hidden;

            .row {
                align-items: center;
            }
        }

        &-left {
            position: relative;
        }

        &-right {
            padding: 3rem 2rem;

            @media (min-width: 1200px) {
                padding: 2rem 4.5rem;
            }

            h2 {
                margin-bottom: 2.6rem;
                padding-bottom: 3.8rem;
                position: relative;

                &::before {
                    width: 14.1rem;
                    height: 0.1rem;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    content: '';
                    display: block;
                    background-color: $gray-800;
                }
            }
        }

        &-client {
            &-detail {
                display: flex;
                align-items: center;
            }

            &-avtar {
                margin: 0;
                width: 5.8rem;
                height: 5.8rem;
                display: block;
                border-radius: 50%;
                overflow: hidden;
                background-color: $gray-dark;
                position: relative;

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }

                & > strong {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2.4rem;
                    line-height: 1.333;
                    font-weight: 600;
                    color: $white !important;
                    text-decoration: none !important;
                    text-transform: uppercase;
                }
            }

            &-name {
                padding-left: 1rem;
                font-size: 1.8rem;
                line-height: 1.556;
                font-weight: 400;
                color: $gray-300;
                text-decoration: none !important;

                @media (min-width: 1200px) {
                    padding-left: 1.7rem;
                }
            }
        }

        &-imgbox {
            margin: 0;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 1rem;
            overflow: hidden;

            &::before {
                width: 100%;
                padding-top: 100.8403361344538%;
                content: '';
                display: block;
            }
        }

        &-social {
            top: 2.3rem;
            right: 1.8rem;
            position: absolute;
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            > li {
                margin: 1.1rem;
                padding: 0;
                list-style: none;
                display: block;

                > a {
                    width: 5.1rem;
                    height: 5.1rem;
                    font-size: 2.6rem;
                    color: $gray-dark;
                    background-color: $gray;
                    border-radius: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                    &:hover {
                        color: $white;
                        background-color: $gray-dark;
                    }
                }
            }
        }

        .badge-row {
            @media (min-width: 1200px) {
                margin-bottom: 4.1rem;
            }
        }

        &-nav {
            margin: 0 -1rem;
            padding: 2.6rem 0 0 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            > li {
                margin: 0.5rem 1rem;
                list-style: none;

                > a {
                    padding: 0.6rem 1.5rem;
                    font-size: 1.4rem;
                    line-height: 1.75;
                    font-weight: 600;
                    color: $gray-300;
                    text-transform: uppercase;
                    background-color: rgba($gray, 0.96);
                    border-radius: 0.3rem;
                    text-decoration: none !important;
                    display: block;

                    &:hover,
                    &.active {
                        color: $white;
                        background-color: $gray-inactive;
                    }
                }
            }
        }
    }
}
