.ss {
    &-q {
        background-color: $gray-300;
        color: $white;

        .ss-section-inner {
            padding: 5rem 0;

            @media (min-width: 992px) {
                padding: 6rem 0;
            }
        }

        h2 {
            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }
    }
}
