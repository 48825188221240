.ss {
    &-hh {
        &-post {
            padding: 1.5rem 0;
            display: flex;
            flex-direction: column;

            @media (min-width: 1200px) {
                padding-bottom: 4.5rem;
            }

            &.theme-row {
                padding: 0;
                
                @media (min-width: 768px) {
                    margin: 0 -1.5rem;
                    flex-direction: row;
                    align-items: center;
                }

                .ss-hh-imgbox-wrap {
                    @media (min-width: 768px) {
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                        width: 54.25%;
                        max-width: 54.25%;
                        flex: 0 0 54.25%;
                    }
                }

                .ss-hh-desc-content {
                    @media (min-width: 768px) {
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                        width: 45.75%;
                        max-width: 45.75%;
                        flex: 0 0 45.75%;
                    }
                }

                .ss-hh-desc {
                    @media (min-width: 1200px) {
                        padding: 0 3.9rem;
                        max-width: 45rem;
                    }
                }

                .ss-hh-imgbox::before {
                    padding-top: 69.72624798711755%;
                }
            }
        }

        &-imgbox {
            margin: 0;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            background-color: $gray;
            border-radius: 1rem;
            overflow: hidden;

            &::before {
                width: 100%;
                padding-top: 75.47169811320755%;
                content: '';
                display: block;
            }
        }

        &-caption-lbl {
            padding: 0.8rem 2rem;
            left: 2.4rem;
            bottom: 2.5rem;
            position: absolute;
            background: $gray;
            opacity: 0.96;
            border-radius: 0.3rem;
            font-size: 1.4rem;
            line-height: 1.286;
            font-weight: 600;
            color: $gray-dark-400 !important;
            text-decoration: none !important;
            text-transform: uppercase;
            display: block;
        }

        &-desc {
            &-content {
                padding: 2rem 0 1rem 0;

                @media (min-width: 1200px) {
                    padding-bottom: 2.8rem;
                }
            }

            > h4 {
                margin-bottom: 0;

                > a {
                    color: $gray-300 !important;
                    text-decoration: none !important;
                }
            }
        }

        &-author-category {
            margin-bottom: 2rem;
            font-size: 1.6rem;
            line-height: 1.75;
            font-weight: 400;
            color: rgba($gray-300, 0.6);
            text-decoration: none !important;
            display: block;
        }

        &-nav {
            margin: 0 -1.3rem;
            padding: 2.4rem 0 0 0;
            list-style: none;
            display: flex;

            > li {
                margin: 0 1.3rem;
                padding: 0;
                list-style: none;
                display: flex;

                > a {
                    width: 3.6rem;
                    height: 3.6rem;
                    font-size: 3.6rem;
                    color: $gray-dark-1100;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
