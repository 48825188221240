.ss {
    &-x {
        &-box {
            background-color: $light-blue-100;
            border-radius: 1rem;
        }

        &-imgbox {
            margin: 0 auto;
            width: 100%;
            max-width: 28rem;
            position: relative;
            display: block;
            border-radius: 0.9rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 90.71428571428571%;
                content: '';
                display: block;
            }
        }

        &-right {
            &-content {
                padding: 2rem;

                .btn {
                    min-width: 20rem;
                }
            }
        }

        &-meta {
            margin-bottom: 0.7rem;
            font-size: 1.6rem;
            line-height: 1.75;
            font-weight: 400;
            color: $gray-300;
            display: block;
        }
    }
}
