.ss {
    &-ii {
        margin-bottom: 24rem;
        position: relative;
        z-index: 2;
        background-color: $gray;
        border-bottom: 2.8rem solid $soft-orange;

        .ss-section-inner {
            padding-top: 26.8rem;

            @media (min-width: 768px) {
                padding-top: 28rem;
            }

            @media (min-width: 992px) {
                padding-top: 32.8rem;
            }
        }

        &-imgbox {
            margin: 0 auto -15rem auto;
            width: 100%;
            max-width: 27.5rem;
            position: relative;
            display: block;
            filter: drop-shadow(0px 0.8rem 3rem rgba(0, 0, 0, 0.18));

            &::before {
                width: 100%;
                padding-top: 100%;
                content: '';
                display: block;
            }
        }
    }
}
