.ss {
    &-z {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 992px) {
                margin-bottom: 6.6rem;
            }
        }

        &-box {
            color: $white;
            padding: 3rem 4rem 3rem 3rem;
            height: 100%;
            background-color: $black;
            border-radius: 1rem;
            position: relative;

            @media (min-width: 768px) {
                padding-right: 6rem;
            }

            @media (min-width: 992px) {
                padding-right: 7rem;
            }

            &-row {
                > .col,
                > [class*=col-] {
                    margin: 1.5rem 0;
                }
            }

            &-meta {
                margin-bottom: 0.8rem;
                font-size: 1.4rem;
                line-height: 1.429;
                font-weight: 600;
                color: rgba($white, 0.8);
                display: block;
            }

            &-icon {
                width: 4rem;
                height: 4rem;
                font-size: 2rem;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 1.2rem;
                right: 1.1rem;
                border-radius: 50%;
                background-color: rgba($white, 0.3);

                @media (min-width: 768px) {
                    width: 5.8rem;
                    height: 5.8rem;
                    font-size: 2.8rem;
                }

                @media (min-width: 992px) {
                    top: 2.2rem;
                    right: 2.1rem;
                }
            }

            .btn {
                margin: 0 1rem 0.5rem 0;
                text-transform: uppercase;

                @media (min-width: 992px) {
                    margin-right: 2;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
