.ss {
    &-gc {
        &-a {
            &-img {
                display: block;
                position: relative;
                background-color: $gray;
                border-radius: 2rem;
                overflow: hidden;

                &::before {
                    width: 100%;
                    display: block;
                    content: '';
                    padding-top: 43.6%;
                }
            }
        }

        &-b {
            &-row {
                > .col-12 {
                    margin-bottom: 3rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }

            &-post {
                color: inherit !important;
                text-decoration: none !important;
            }

            &-thumb {
                display: block;
                position: relative;
                overflow: hidden;
                border-radius: 1rem;
                background-color: $gray;
                margin-bottom: 2rem;

                @media (min-width: 992px) {
                    margin-bottom: 3rem;
                }

                &::before {
                    width: 100%;
                    content: '';
                    display: block;
                    padding-top: 73.17%;
                }
            }
        }

        &-c {
            &-post {
                margin-bottom: 3rem;
                color: inherit !important;
                text-decoration: none !important;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                }

                .entry-no {
                    font-size: 10rem;
                    color: $primary;
                    display: block;
                    line-height: 1;
                    margin-bottom: 0.5rem;
                }

                .entry-title {
                    margin-bottom: 2rem;
                }
            }
        }

        &-d {
            &.right-aligned {
                .ss-gc-d-l {
                    @media (min-width: 992px) {
                        align-items: flex-end;
                    }
                }

                .ss-gc-d-r {
                    @media (min-width: 992px) {
                        order: -1;
                    }
                }
            }

            &-row {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1.5rem;
            }

            &-l {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;
                display: flex;
                flex-direction: column;
                margin-bottom: 3rem;

                @media (min-width: 992px) {
                    width: 42%;
                    flex: 0 0 42%;
                    max-width: 42%;
                    margin-bottom: 0;
                }

                .ss-cc-block {
                    @media (min-width: 992px) {
                        max-width: 37rem;
                        padding-top: 5.2rem;
                    }
                }
            }

            &-r {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 58%;
                    flex: 0 0 58%;
                    max-width: 58%;
                }

                &-row {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -1.5rem;
                }

                &-l {
                    width: 57.3%;
                    flex: 0 0 57.3%;
                    max-width: 57.3%;
                    padding: 0 1.5rem;

                    @media (max-width: 575px) {
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-bottom: 3rem;
                    }

                    &-img {
                        display: block;
                        position: relative;
                        overflow: hidden;
                        background-color: $gray;
                        border-radius: 2rem;

                        &::before {
                            width: 100%;
                            display: block;
                            content: '';
                            padding-top: 125.74%;
                        }
                    }
                }

                &-r {
                    width: 42.7%;
                    flex: 0 0 42.7%;
                    max-width: 42.7%;
                    padding: 0 1.5rem;

                    @media (max-width: 575px) {
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                    }

                    &-img {
                        display: block;
                        position: relative;
                        overflow: hidden;
                        background-color: $gray;
                        border-radius: 2rem;
                        margin-bottom: 3rem;

                        &::before {
                            width: 100%;
                            display: block;
                            content: '';
                            padding-top: 86.24%;
                        }
                    }
                }
            }
        }

        &-e {
            &.right-aligned {
                @media (min-width: 992px) {
                    .ss-gc-e-r {
                        order: -1;
                    }
                }
            }

            &-box {
                padding: 3rem;
                border: 1px solid $gray-1200;
                border-radius: 2rem;
            }

            &-row {
                display: flex;
                margin: 0 -1.5rem;
                align-items: center;
                flex-wrap: wrap;
            }

            &-l {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .ss-cc-block {
                    margin-bottom: 3rem;

                    @media (min-width: 992px) {
                        max-width: 37rem;
                        margin: auto;
                    }
                }
            }

            &-r {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }

            &-img {
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;
                border-radius: 1rem;

                &::before {
                    width: 100%;
                    display: block;
                    content: '';
                    padding-top: 100%;
                }

                &-grid {
                    display: flex;
                    margin: 0 -1.5rem;
                    flex-wrap: wrap;
                }

                &-col {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 1.5rem;
                    margin-top: 3rem;

                    @media (min-width: 576px) {
                        width: 50%;
                        flex: 0 0 50%;
                        max-width: 50%;
                    }

                    &:nth-child(1) {
                        margin-top: 0;
                    }

                    &:nth-child(2) {
                        @media (min-width: 576px) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &-f {
            &.right-aligned {
                @media (min-width: 992px) {
                    .ss-gc-f-r {
                        order: -1;
                    }
                }
            }

            &-box {
                border: 1px solid $gray-1200;
                position: relative;
                overflow: hidden;
                border-radius: 2rem;
            }

            &-row {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &-l {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                align-self: stretch;

                @media (min-width: 992px) {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }

            &-r {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 3rem;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media (min-width: 992px) {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .ss-cc-block {
                    @media (min-width: 992px) {
                        max-width: 37rem;
                        margin: auto;
                    }
                }
            }

            &-img {
                display: block;
                position: relative;
                background-color: $gray;
                height: 100%;
                min-height: 34rem;

                @media (min-width: 768px) {
                    min-height: 61rem;
                }
            }
        }

        &-g {
            &-sep {
                background-color: $gray-1200;
                opacity: 1;
                margin: 3.3rem 0;

                @media (min-width: 768px) {
                    margin: 5.3rem 0;
                }
            }

            &-p {
                &-l {
                    .ss-cc-block {
                        margin-bottom: 3rem;

                        @media (min-width: 992px) {
                            max-width: 37rem;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &-s {
                &-col {
                    display: flex;
                    flex-direction: column;

                    @media (max-width: 767px) {
                        margin-top: 2rem;

                        &:nth-child(1),
                        &:nth-child(2) {
                            margin-top: 0;
                        }
                    }

                    > strong {
                        font-size: 6.4rem;
                        line-height: 1;
                        font-weight: 600;
                    }

                    > span {
                        font-size: 1.6rem;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
            }
        }

        &-h {
            &-l {
                .ss-cc-block {
                    margin-bottom: 3rem;

                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-i {
            &.theme-b {
                .ss-section-inner {
                    padding: 5rem 0;

                    @media (min-width: 992px) {
                        padding: 8rem 0;
                    }
                }
            }

            &.theme-c {
                .ss-cc-desc {
                    @media (min-width: 576px) {
                        column-count: 2;
                        column-gap: 3rem;
                    }
                }
            }

            &.theme-d {
                .ss-cc-desc {
                    padding: 3rem;
                    background-color: rgba($gray-35, 0.35);
                    border-radius: 2rem;

                    @media (min-width: 576px) {
                        column-count: 2;
                        column-gap: 3rem;
                    }

                    @media (min-width: 992px) {
                        padding: 6rem;
                    }
                }
            }
        }

        &-j {
            &-content {
                max-width: 23rem;
                margin: 0 auto 3rem auto;
                text-align: center;

                @media (min-width: 768px) {
                    margin: auto;
                }
            }

            &-head {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-bottom: 1.6rem;

                strong {
                    font-size: 7rem;
                    font-weight: 700;
                    line-height: 0.9;

                    @media (min-width: 992px) {
                        font-size: 10rem;
                    }
                }

                sup {
                    font-size: 2.4rem;
                    font-weight: 600;
                    line-height: 1;
                    margin: 2rem 0 0 0.5rem;

                    @media (min-width: 992px) {
                        margin: 2.5rem 0 0 0.5rem;
                    }
                }
            }
        }

        &-k {
            &-img {
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;
                border-radius: 1rem;
                margin-bottom: 2rem;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                }

                &::before {
                    width: 100%;
                    display: block;
                    content: '';
                    padding-top: 112.43%;
                }
            }

            &-box {
                padding: 1rem;
                background: $gray;
                border-radius: 1rem;
                min-height: 12.5rem;
                margin-bottom: 1rem;

                @media (min-width: 992px) {
                    padding: 2.5rem;
                    margin-bottom: 2rem;
                }

                @media (min-width: 1400px) {
                    padding: 3rem;
                }
            }
        }

        &-l {
            &-icons {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1rem;

                &-c {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 3rem;

                    @media (min-width: 576px) {
                        width: 33.33%;
                        flex: 0 0 33.33%;
                        max-width: 33.33%;
                    }

                    @media (min-width: 768px) {
                        margin-bottom: 5rem;
                    }

                    > [class*="ss-icon-"] {
                        font-size: 4.1rem;
                        color: $primary;
                        margin-bottom: 1.2rem;
                    }

                    > strong {
                        font-size: 1.6rem;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &-m {
            &-row {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1.5rem;
            }

            &-l {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 55.84%;
                    flex: 0 0 55.84%;
                    max-width: 55.84%;
                }

                .ss-cc-block {
                    margin-top: 2rem;

                    @media (min-width: 992px) {
                        max-width: 57rem;
                    }
                }
            }

            &-r {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 44.16%;
                    flex: 0 0 44.16%;
                    max-width: 44.16%;
                }
            }

            &-img {
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;
                border-radius: 1rem;
                margin-top: 3rem;

                @media (min-width: 992px) {
                    margin-top: 0;
                }

                &::before {
                    width: 100%;
                    display: block;
                    content: '';
                    padding-top: 93%;
                }
            }
        }

        &-n {
            &.theme-b {
                .ss-section-inner {
                    padding: 5rem 0;

                    @media (min-width: 992px) {
                        padding: 9rem 0;
                    }
                }
            }

            &-col {
                text-align: center;

                &:not(:nth-child(1)),
                &:not(:nth-child(2)) {
                    @media (max-width: 767px) {
                        margin-top: 2rem;
                    }
                }

                > strong {
                    font-size: 4rem;
                    font-weight: 600;
                    display: block;
                    line-height: 1;
                    margin-bottom: 0.7rem;
                }

                > span {
                    font-size: 1.8rem;
                    display: block;
                }
            }
        }

        &-o {
            .ss-section-bg {
                background-color: $gray;
            }

            .ss-section-inner {
                padding: 5rem 0;

                @media (min-width: 992px) {
                    padding: 9rem 0;
                }

                @media (min-width: 1200px) {
                    padding: 12.8rem 0;
                }
            }

            .ss-cc-block {
                background-color: $white;
                border-radius: 2rem;
                box-shadow: 0 0.1rem 3rem rgba($black, 0.08);
                padding: 2.4rem;

                @media (min-width: 576px) {
                    padding: 4.4rem;
                }
            }
        }

        &-p {
            &-head {
                max-width: 57rem;
                margin: 0 auto 4rem auto;

                @media (min-width: 992px) {
                    margin: 0 auto 6rem auto;
                }

                .h2 {
                    max-width: 45.5rem;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &-row {
                > .col-12 {
                    margin-bottom: 3rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }

            &-post {
                color: inherit !important;
                text-decoration: none !important;
                border: 1px solid $gray-1200;
                border-radius: 1rem;
                position: relative;
                overflow: hidden;

                .entry-body {
                    padding: 2rem;
                    text-align: center;

                    @media (min-width: 576px) {
                        padding: 3rem;
                    }
                }

                .entry-footer {
                    margin-top: 2rem;
                }
            }

            &-thumb {
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;

                &::before {
                    width: 100%;
                    content: '';
                    display: block;
                    padding-top: 60.54%;
                }
            }
        }

        &-q {
            &-row {
                > .col-12 {
                    margin-bottom: 3rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }
            }

            &-post {
                color: inherit !important;
                text-decoration: none !important;

                .entry-body {
                    text-align: center;
                }
            }

            &-thumb {
                max-width: 15rem;
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;
                border-radius: 1rem;
                margin: 0 auto 2.6rem auto;

                &::before {
                    width: 100%;
                    content: '';
                    display: block;
                    padding-top: 85.333%;
                }
            }
        }

        &-r {
            &-row {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -1.5rem;
            }

            &-l {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;

                @media (min-width: 992px) {
                    width: 44.16%;
                    flex: 0 0 44.16%;
                    max-width: 44.16%;
                }
            }

            &-r {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 1.5rem;
                display: flex;
                flex-direction: column;

                @media (min-width: 992px) {
                    width: 55.84%;
                    flex: 0 0 55.84%;
                    max-width: 55.84%;
                    align-items: flex-end;
                }

                .ss-cc-block {
                    margin-top: 2rem;

                    @media (min-width: 992px) {
                        max-width: 57rem;
                    }
                }
            }

            &-img {
                display: block;
                position: relative;
                overflow: hidden;
                background-color: $gray;
                border-radius: 1rem;
                margin-bottom: 3rem;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                }

                &::before {
                    width: 100%;
                    display: block;
                    content: '';
                    padding-top: 89.6%;
                }
            }

            &-subrow {
                .col-6 {
                    strong {
                        font-size: 4.8rem;
                        display: block;
                        line-height: 1;
                        margin-bottom: 0.5rem;

                        @media (min-width: 768px) {
                            font-size: 6.4rem;
                        }
                    }
                }
            }
        }

        &-s {
            &-head {
                max-width: 57rem;
                margin: 0 auto 4rem auto;

                @media (min-width: 992px) {
                    margin: 0 auto 6rem auto;
                }
            }

            &-prgm {
                padding: 2.4rem;
                border: 1px solid $gray-1200;
                border-radius: 2rem;
                margin-bottom: 3rem;
                display: flex;
                flex-wrap: wrap;

                @media (min-width: 768px) {
                    padding: 4.4rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &-l {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 2rem;

                    @media (min-width: 992px) {
                        width: 33%;
                        flex: 0 0 33%;
                        max-width: 33%;
                        padding-right: 4rem;
                        margin-bottom: 0;
                    }

                    .badge-row {
                        margin-bottom: 2rem;
                    }

                    > :last-child {
                        margin-bottom: 0;
                    }
                }

                &-m {
                    width: 100%;
                    flex: 0 0 100%;
                    margin-bottom: 2rem;

                    @media (min-width: 992px) {
                        width: 1%;
                        flex: 1 1 auto;
                        padding: 0 4rem;
                        margin-bottom: 0;
                    }

                    .checklist {
                        max-width: 45rem;
                        margin: 0;

                        li:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                    }
                }

                &-r {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;

                    @media (min-width: 992px) {
                        padding: 0 4rem;
                    }

                    .btn {
                        min-width: 13rem;
                        text-transform: uppercase;
                        margin-bottom: 2rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
