.ss {
    &-t {
        .ss-cc-head {
            padding: 5rem 0;

            @media (min-width: 768px) {
                padding: 6rem 0;
            }

            @media (min-width: 1200px) {
                padding: 9rem 0 7.8rem 0;
            }
        }

        &-row {
            @media (min-width: 992px) {
                margin: 0 -3.8rem;
            }

            @media (min-width: 1200px) {
                margin: 0 -4.8rem;
            }

            .col,
            [class*=col-] {
                @media (min-width: 992px) {
                    padding: 0 3.8rem;
                }

                @media (min-width: 1200px) {
                    padding: 0 4.8rem;
                }
            }

            > :first-child {
                @media (min-width: 992px) {
                    border-right: 0.1rem solid $gray-dark-700;
                }
            }
        }

        &-imgbox {
            margin-bottom: 2rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border: 0.1rem solid $gray-35;
            border-radius: 2rem;
            overflow: hidden;
            background-color: $gray;

            &::before {
                width: 100%;
                padding-top: 54.91228070175439%;
                content: '';
                display: block;
            }
        }

        &-desc {
            h3 {
                a {
                    color: $gray-300 !important;
                    text-decoration: none !important;
                }
            }
        }

        &-hr-lbl {
            margin-bottom: 1rem;
            font-size: 1.4rem;
            line-height: 1.429;
            font-weight: 600;
            color: $primary;
            display: block;
        }

        &-right {
            h4 {
                @media (min-width: 1200px) {
                    margin-bottom: 3.6rem;
                }
            }

            .ss-t-hr-lbl {
                color: rgba($white, 0.8);
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        &-rf {
            &-box {
                margin-bottom: 3rem;
                padding: 2.6rem 3rem 3rem 3rem;
                border-radius: 1rem;
                background-color: $gray;
                position: relative;
                color: $white;
                overflow: hidden;

                .ss-cc-block {
                    max-width: 36rem;

                    .btn {
                        @media (min-width: 1200px) {
                            min-width: 17rem;
                        }
                    }
                }
            }

            &-bg-icon {
                font-size: 21rem;
                bottom: -1.5rem;
                right: 0;
                position: absolute;
                transform: rotate(-30deg);
                opacity: 0.15;
            }
        }
    }
}
