.ss {
    &-hhh {
        &-l {
            margin-bottom: 5rem;

            .ss-cc-block {
                @media (min-width: 768px) {
                    max-width: 27rem;
                }
            }

            .ss-cc-btns {
                margin: 0;

                .btn {
                    margin: 0;
                }
            }

            .ss-v-subscribe-box {
                margin-top: 5rem;

                @media (min-width: 768px) {
                    max-width: 27rem;
                }
            }
        }
    }
}
