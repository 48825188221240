.progress {
    height: 0.8rem;
    border-radius: 3rem;
    
    &-row {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-lbls {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}
