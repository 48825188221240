.ss {
    &-s {
        &-head {
            margin-bottom: 2.5rem;
            text-align: center;
        }

        &-org {
            &-wrap {
                margin: 0;
                padding: 2rem 2rem;
                background-color: $gray-dark-500;
                border-radius: 2rem;

                @media (min-width: 1200px) {
                    padding: 4rem 5rem;
                }

                @media (min-width: 1400px) {
                    padding: 6.3rem 6.9rem;
                }
            }

            &-box {
                min-height: 28rem;
                margin: 1.5rem 0;
                background: $white;
                border-radius: 1rem;
                position: relative;

                @media (min-width: 1200px) {
                    margin: 2.5rem 0;
                }
            }

            &-head {
                padding: 1rem;
                display: flex;
                align-items: center;
                border-bottom: 0.1rem solid $gray-35;

                @media (min-width: 576px) {
                    padding: 1.5rem 15rem 1.5rem 2rem;
                }

                @media (min-width: 1200px) {
                    padding: 1.5rem 15rem 1.5rem 2rem;
                }

                @media (min-width: 1400px) {
                    padding: 2rem 15rem 2rem 2.7rem;
                }

                .h4 {
                    margin-bottom: 0;
                }
            }

            &-body {
                padding: 1.5rem;

                @media (min-width: 576px) {
                    padding: 1.5rem 15rem 1.5rem 1.5rem;
                }

                @media (min-width: 1200px) {
                    padding: 1.5rem 15rem 1.5rem 2rem;
                }

                @media (min-width: 1400px) {
                    padding: 1.5rem 15rem 1.5rem 2.9rem;
                }
            }

            &-points {
                margin: 0;
                padding: 0;
                list-style: none;
                display: block;

                li::before {
                    display: none;
                }

                li::after {
                    width: 1.3rem;
                    height: 1.3rem;
                    top: 1rem;
                    left: 1rem;
                    background: $gray-dark-600;
                    opacity: 0.2;
                    border-radius: 0.2rem;
                    transform: rotate(45deg);
                    content: '';
                    display: block;
                    position: absolute;
                }

                li {
                    margin: 0.2rem 0;
                    padding: 0.3rem 0 0.3rem 4.1rem;
                    list-style: none;
                    display: block;
                    position: relative;
                }
            }

            &-icon {
                margin-right: 1.4rem;
                width: 4.5rem;
                height: 4.5rem;
                font-size: 3.7rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $light-green;
            }

            &-imgbox {
                margin: 2rem auto 0 auto;
                width: 100%;
                max-width: 15.6rem;
                position: relative;
                display: block;

                @media (min-width: 576px) {
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                &::before {
                    width: 100%;
                    padding-top: 169.2307692307692%;
                    content: '';
                    display: block;
                }
            }
        }
    }
}
