.wpcf7-form {
    .row > .col {
        @media (max-width: 767px) {
            width: 100%;
            flex: 0 0 auto;
        }
    }

    .wpcf7-spinner {
        background-color: $primary;
        margin: 5px 24px 0 0;
    }

    .wpcf7-response-output {
        color: #fff;
    }

    .wpcf7-checkbox {
        display: flex;
        position: relative;
        user-select: none;
        cursor: pointer;
        padding: 1rem 0;
        border: 1px solid #ccc;
        border-radius: 1rem;
        margin-bottom: 2rem;
        align-items: center;
        min-height: 7.4rem;

        input:not(.form-control) {
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        input + span.wpcf7-list-item-label {
            padding-left: 46px;
            display: inline-block;
            position: relative;

            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 2.4rem;
                height: 2.4rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border: 1px solid $gray-inactive;
                font-size: 0;
                border-radius: 0.3rem;
                background-color: $white;
                color: $white;
                transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
                cursor: pointer;
            }

            &::after {
                display: none;
            }
        }

        input:checked ~ span.wpcf7-list-item-label {
            position: relative;

            &::before {
                content: '';
                display: block;
                background-color: $secondary;
                border-color: $secondary;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='ss-icon-check-outline' viewBox='0 0 19 19'%3E%3Cpath fill='%23ffffff' d='M16.9,2.4L6.1,13.2l-4-4c-0.2-0.2-0.5-0.2-0.7,0l-1.2,1.2c-0.2,0.2-0.2,0.5,0,0.7l5.5,5.5 c0.2,0.2,0.5,0.2,0.7,0L18.7,4.3c0.2-0.2,0.2-0.5,0-0.7l-1.2-1.2C17.4,2.2,17.1,2.2,16.9,2.4z'%3E%3C/path%3E%3C/svg%3E");
                background-position: 60% center;
                background-size: 60%;
                background-repeat: no-repeat;
            }
        }
    }

    .tab-pane .tab-content {
        border: 1px solid #ddd;
        border-radius: 2rem;
        padding: 4rem;
        padding-bottom: 0;
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ss-kkk-odcp {
        .ss-kkk-box {
            border: 1px solid #ddd;
            border-radius: 2rem;
            padding: 4rem;
        }
    }

    .ss-kkk-box {
        border: 0;
        padding: 0;
    }

    .ss-wrapper-infos {
        border: 1px solid #ddd;
        border-radius: 2rem;
        padding: 4rem;
        padding-top: 0;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.ss-l,
.ss-kk,
.ss-kkk,
.ss-n {
    .wpcf7-form {
        .wpcf7-response-output {
            color: $body-color;
        }
    }
}

.ss-kkk-sol-clps-t {
    .wpcf7-checkbox {
        border: 0;
        margin: 0;
    }
}
