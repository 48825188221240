.ss {
    &-v {
        &-gc-link {
            margin-top: 1rem;
            font-size: 1.8rem;
            line-height: 1.556;
            font-weight: 600;
            color: $primary;
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            @media (min-width: 992px) {
                margin: 0 0 0 auto;
            }
        }

        &-topics {
            &-row {
                > .col,
                > [class*=col-] {
                    margin: 1.5rem 0;
                }
            }

            &-box {
                margin: 0;
                min-height: 8rem;
                padding: 1.5rem;
                border-radius: 1rem;
                background-color: $gray;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none !important;

                &.active {
                    background-color: $primary;
                    color: $white !important;

                    .ss-v-topics-name {
                        color: inherit;
                    }
                }

                @media (min-width: 576px) {
                    min-height: 13.7rem;
                }
            }

            &-name {
                font-size: 1.8rem;
                line-height: 1.333;
                font-weight: 600;
                color: $gray-dark-400;
                text-align: center;
                display: block;

                @media (min-width: 576px) {
                    max-width: 14rem;
                }
            }
        }

        &-subscribe {
            &-box {
                margin: 1.5rem 0;
                padding: 3.2rem 3rem 3.3rem 3rem;
                border-radius: 1.2rem;
                background-color: $gray;
                display: flex;
                flex-direction: column;

                &.light-blue {
                    background-color: rgba($light-blue, 0.1);
                }

                @media (min-width: 768px) {
                    margin-top: 0;
                }
            }

            &-imgbox {
                margin-bottom: 1.7rem;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                border-radius: 0.9rem;
                overflow: hidden;
                background-color: $white;

                &::before {
                    width: 100%;
                    padding-top: 61.42857142857143%;
                    content: '';
                    display: block;
                }
            }

            &-footer {
                padding-top: 1rem;

                @media (min-width: 992px) {
                    padding-top: 2.9rem;
                }

                .btn {
                    width: 100%;
                    max-width: 24rem;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}
