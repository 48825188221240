.ss {
    &-nn {
        .ss-cc-head {
            padding: 9rem 0 6.6rem 0;
        }

        &-culture {
            padding: 0 3rem;
            background: rgba($gray-35, 0.35);
            border-radius: 2rem;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;

            @media (min-width: 992px) {
                flex-direction: row;
            }

            &-left {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 992px) {
                    margin-top: -7.4rem;
                    padding-top: 0;
                    width: 37.6rem;
                    max-width: 37.6rem;
                    flex: 0 0 37.6rem;
                }
            }

            &-right {
                padding-top: 2rem;
                flex: 1;
                align-self: flex-start;

                @media (min-width: 992px) {
                    padding: 4.2rem 0 2.2rem 4.2rem;
                }

                @media (min-width: 1200px) {
                    padding-right: 2.2rem;
                    padding-left: 7rem;
                }
            }

            &-imgbox {
                margin: 0 auto;
                width: 100%;
                max-width: 37.6rem;
                position: relative;
                display: block;

                &::before {
                    width: 100%;
                    padding-top: 104.5212765957447%;
                    content: '';
                    display: block;
                }
            }
        }

        &-row-b {
            .col,
            [class*=col-] {
                margin-top: 2.5rem;

                @media (min-width: 1200px) {
                    margin-top: 4.5rem;
                }
            }
        }

        &-box {
            height: 100%;
            background: rgba($gray-35, 0.35);
            border-radius: 2rem;
            overflow: hidden;
            text-align: center;

            &-head {
                background: #64ba79;
                padding: 1.4rem;
                color: $white;

                h4 {
                    margin-bottom: 0;
                }
            }

            .ss-cc-desc {
                margin-bottom: 0;
                padding: 2rem;

                @media (min-width: 1200px) {
                    padding: 2.9rem 3rem 3.4rem 3rem;
                }
            }
        }
    }
}
