.ss {
    &-g {
        &-head {
            margin-bottom: 3.3rem;

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-expert {
            padding: 3rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 2rem;
            overflow: hidden;
            background-color: $gray;

            @media (min-width: 768px) {
                padding: 8rem 5rem;
            }

            @media (min-width: 992px) {
                padding: 8rem 10rem;
            }

            &::before {
                width: 100%;
                height: 100%;
                content: '';
                display: block;
            }

            &-box {
                padding: 3rem 1.6rem 2rem 1.6rem;
                width: 100%;
                max-width: 25rem;
                background-color: $white;
                border-radius: 2rem;
                box-shadow: 0px 0.8rem 3rem rgba(0, 0, 0, 0.0802557);
                position: relative;

                @media (min-width: 768px) {
                    max-width: 34rem;
                }

                @media (min-width: 992px) {
                    max-width: 37rem;
                    min-height: 38.4rem;
                    padding: 3.9rem 3.2rem 3.2rem 3.2rem;
                }

                > p {
                    margin: 0;
                    font-size: 1.6rem;
                    line-height: 1.333;
                    font-weight: 600;
                    color: $gray-300;

                    @media (min-width: 768px) {
                        font-size: 2rem;
                    }

                    @media (min-width: 992px) {
                        font-size: 2.4rem;
                    }
                }

                &-footer {
                    padding-top: 3.3rem;
                }
            }

            &-qoute {
                width: 2.8rem;
                height: 2.8rem;
                font-size: 2.4rem;
                color: $primary;
                top: 1rem;
                right: 1.5rem;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 768px) {
                    width: 3.2rem;
                    height: 3.2rem;
                    font-size: 2.8rem;
                }

                @media (min-width: 992px) {
                    width: 4.3rem;
                    height: 4.3rem;
                    font-size: 3.6rem;
                    top: 1.5rem;
                    right: 2.5rem;
                }
            }

            &-name {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 600;
                color: $gray-300;
                text-transform: uppercase;
                display: block;
            }

            &-post {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 400;
                color: rgba($gray-300, 0.5);
                display: block;
            }
        }

        &-slider {
            padding-bottom: 4.6rem;

            &-wrap {
                padding-top: 5.9rem;
            }

            .swiper-slide {
                height: auto;
            }

            .swiper-pagination-bullets {
                bottom: -0.8rem;
            }

            .swiper-pagination-bullet {
                margin: 0 !important;
                padding: 0.8rem;
                width: auto;
                height: auto;
                background-color: transparent;
                opacity: 1;

                &::before {
                    width: 0.8rem;
                    height: 0.8rem;
                    display: block;
                    content: '';
                    background-color: $gray-900;
                    border-radius: 50%;
                }

                &-active {
                    &::before {
                        background-color: $secondary;
                    }
                }
            }

            &-box {
                padding: 1.6rem 1.1rem 1.6rem 1.6rem;
                background-color: $gray-1000;
                border-radius: 2rem;
                height: 100%;

                @media (min-width: 992px) {
                    min-height: 26.5rem;
                    padding: 2.2rem 1.1rem 2.9rem 2.2rem;
                }

                &-head {
                    margin-bottom: 1.4rem;
                    display: flex;
                    align-items: center;

                    &-r {
                        flex: 1;
                    }
                }
            }

            &-user-expert {
                margin-right: 1.8rem;
                width: 5.1rem;
                height: 5.1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-shrink: 0;
                background-color: $white;
                border-radius: 50%;
                transition: background-color 0.25s, color 0.25s;
                overflow: hidden;
                position: relative;

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-expert-name {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 600;
                color: $gray-300;
                text-transform: uppercase;
                display: block;
            }

            &-expert-category {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 400;
                color: rgba($gray-300, 0.5);
                display: block;
            }
        }
    }
}
