.ss {
    &-dropdown {
        display: inline-block;
        vertical-align: middle;

        &-toggle {
            font-size: 1.4rem;
            font-weight: 400;
            color: $body-color !important;
            display: block;
            position: relative;
            background-color: $white;
            border-radius: 0.6rem;
            border: 1px solid $gray-50;
            padding: 0.9rem 4rem 0.9rem 1.4rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
            outline: none;
            text-decoration: none !important;
            text-align: left;

            .d-block & {
                width: 100%;
            }

            &:hover {
                border-color: $gray-100;
                background-color: rgba($black, 0.03);
                box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100;
            }

            &:focus {
                border-color: $primary;
                background-color: $white;
                box-shadow: none;
            }
            

            &.show {
                border-color: $primary;
                background-color: $white;
                box-shadow: none;

                .ss-dropdown-arrow {
                    transform: rotate(180deg);
                }
            }

            &-txt {
                display: block;
                flex: 1 1 auto;
            }
        }

        &-arrow {
            width: 3.8rem;
            height: 3.8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;
            position: absolute;
            top: 0;
            right: 0.1rem;
        }

        .dropdown-menu {
            min-width: 100%;
            padding: 0.7rem;
            border: none;
            box-shadow: 0.1rem 0.9rem 2.1rem rgba($black, 0.12);
            border-radius: 0.6rem;
            font-size: 1.4rem;
            
            &.with-overflow-scroll {
                max-height: 30rem;
                overflow: hidden;
                overflow-y: auto;

                @include stylish-scroll($width: 0.5rem, $height: false, $track-bg: $white, $thumb-bg: rgba($black, 0.2));
            }
        }

        .dropdown-item {
            font-size: 1.4rem;
            padding: 1rem 1.4rem;
            border-radius: 0.6rem;
            background-color: transparent;
            margin: 0.1rem 0;
            color: $body-color !important;
            text-decoration: none !important;
            min-width: 15rem;

            &.disabled {
                cursor: default;
                opacity: 0.4;
            }

            &:not(.disabled):hover,
            &:not(.disabled):focus {
                background-color: $gray;
            }

            &:not(.disabled):active,
            &:not(.disabled).active {
                background-color: $gray;
            }

            &.ss-dpitem-icon {
                display: flex;
                align-items: center;

                &:active,
                &.active {
                    > [class*="ss-icon-"]:not(.ss-icon-chevron-right) {
                        color: $primary;
                    }
                }

                > [class*="ss-icon-"] {
                    font-size: 1.7rem;
                    flex-shrink: 0;

                    &:first-child {
                        margin-right: 1.2rem;
                    }

                    &:last-child {
                        font-size: 1rem;
                        margin-left: 1.2rem;
                    }
                }

                .dropdown-item-text {
                    flex: 1 1 auto;
                }
            }
        }

        .dropdown-item-text {
            padding: 0;
            color: inherit;
        }
    }

    &-popover {
        border: none;
        border-radius: 1rem;
        font-size: 1.4rem;
        background-color: $white;
        box-shadow: 0 0.8rem 3rem rgba($black, 0.18);
        max-width: 44rem;

        .popover-body {
            padding: 0;
        }

        .popover-header {
            padding: 1.4rem 1.4rem 0 1.4rem;
            border: none;
            border-radius: 0;
            font-size: 1.6rem;
            background-color: transparent;
        }

        .popover-arrow {
            width: 3.2rem;
            height: 1.6rem;

            &::before {
                display: none;
            }
        }

        &.bs-popover-auto[data-popper-placement^=top] .popover-arrow,
        &.bs-popover-top .popover-arrow {
            bottom: calc(-1.6rem - 1px);
        }

        &.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow,
        &.bs-popover-bottom .popover-arrow {
            top: calc(-1.6rem - 1px);
        }

        &.bs-popover-auto[data-popper-placement^=right] .popover-arrow,
        &.bs-popover-end .popover-arrow {
            left: calc(-1.6rem - 1px);
            height: 3.2rem;
            width: 1.6rem;
        }

        &.bs-popover-auto[data-popper-placement^=left] .popover-arrow,
        &.bs-popover-start .popover-arrow {
            right: calc(-1.5rem - 1px);
            height: 3.2rem;
            width: 1.6rem;
        }

        &.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after,
        &.bs-popover-top .popover-arrow::after {
            border-width: 1.6rem 1.6rem 0;
            border-top-color: $white;
        }

        &.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after,
        &.bs-popover-bottom .popover-arrow::after {
            border-width: 0 1.6rem 1.6rem 1.6rem;
            border-top-color: $white;
        }

        &.bs-popover-auto[data-popper-placement^=right] .popover-arrow::after,
        &.bs-popover-end .popover-arrow::after {
            border-width: 1.6rem 1.6rem 1.6rem 0;
        }

        &.bs-popover-auto[data-popper-placement^=left] .popover-arrow::after,
        &.bs-popover-start .popover-arrow::after {
            border-width: 1.6rem 0 1.6rem 1.6rem;
        }

        &-body {
            padding: 1.4rem;

            p {
                margin: 0 0 2.2rem 0;
            }

            .img-fluid {
                border-radius: 0.6rem;
                border: 1px solid $gray-250;
                margin-top: 2.2rem;
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        &-footer {
            padding: 1.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: -1rem 0;

            .btn {
                margin: 1rem;
            }
        }
    }

    &-tooltip {
        font-size: 1.4rem;

        &.show {
            opacity: 1;
        }

        &.lg {
            .tooltip-inner {
                font-size: 1.4rem;
                max-width: 20rem;
            }
        }

        .tooltip-inner {
            background-color: $white;
            box-shadow: 0 0.3rem 0.8rem rgba($black, 0.18);
            color: $body-color;
            border-radius: 0.6rem;
            padding: 0.6rem 1rem 0.6rem;
            font-size: 1.2rem;
            max-width: 15rem;
        }

        &.bs-tooltip-auto[data-popper-placement^=left],
        &.bs-tooltip-start {
            padding: 0 0.8rem;

            .tooltip-arrow {
                width: 0.8rem;
                height: 1.6rem;

                &::before {
                    border-width: 0.8rem 0 0.8rem 0.8rem;
                    border-left-color: $white;
                }
            }
        }

        &.bs-tooltip-auto[data-popper-placement^=right],
        &.bs-tooltip-end {
            padding: 0 0.8rem;

            .tooltip-arrow {
                width: 0.8rem;
                height: 1.6rem;

                &::before {
                    border-width: 0.8rem 0.8rem 0.8rem 0;
                    border-right-color: $white;
                }
            }
        }

        &.bs-tooltip-auto[data-popper-placement^=top],
        &.bs-tooltip-top {
            padding: 0.8rem 0;

            .tooltip-arrow {
                width: 1.6rem;
                height: 0.8rem;

                &::before {
                    border-width: 0.8rem 0.8rem 0;
                    border-top-color: $white;
                }
            }
        }

        &.bs-tooltip-auto[data-popper-placement^=bottom],
        &.bs-tooltip-bottom {
            padding: 0.8rem 0;

            .tooltip-arrow {
                width: 1.6rem;
                height: 0.8rem;

                &::before {
                    border-width: 0 0.8rem 0.8rem;
                    border-bottom-color: $white;
                }
            }
        }
    }
}
