.ss {
    &-iii {
        background-color: rgba($primary, 0.1);

        &-row {
            display: flex;
            align-items: center;
            padding-top: 3rem;

            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
        }

        &-l {
            width: 1%;
            flex: 1 1 auto;
            margin-right: 3rem;

            @media (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 3rem;
            }

            .form-group {
                max-width: 61rem;
                margin-bottom: 0;
            }
        }

        &-r {
            width: 33.5rem;
            max-width: 100%;
            flex-shrink: 0;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &-img {
            display: block;
            width: 100%;
            max-width: 33.5rem;
            position: relative;
            margin: auto;

            &::before {
                width: 100%;
                display: block;
                content: '';
                padding-top: 106.86%;
            }
        }
    }
}
