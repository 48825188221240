.btn {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 1.2rem 1rem 1.2rem;
    border-radius: 0.3rem;
    transition: all 0.15s linear;
    min-width: 11rem;
    box-shadow: none !important;
    border-width: 0.2rem;

    &.btn-sm {
        font-size: 1.2rem;
        padding: 0.6rem 1rem;
        min-width: 9rem;
        border-radius: 0.6rem;

        @media (min-width: 768px) {
            padding: 0.5rem 1.5rem;
        }
    }

    &.btn-sm.btn-icon {
        min-width: 3.2rem;
        min-height: 3.2rem;
        padding: 0.5rem;
    }

    &.btn-xsm {
        font-size: 1rem;
        padding: 0.6rem 0.8rem;
        min-width: 8rem;
        border-radius: 0.3rem;
    }

    &.btn-lg {
        font-size: 2.2rem;
        padding: 1.3rem 1.5rem 1.4rem 1.5rem;
    }

    &.btn-lg.btn-icon {
        min-width: 6.4rem;
        min-height: 6.4rem;
    }

    &.btn-xlg {
        font-size: 1.8rem;
        padding: 2.4rem;
    }

    &.btn-block {
        width: 100%;
    }

    &.with-icon {
        display: inline-flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;

        > span {
            display: block;
        }
    }

    &.with-icon-right {
        display: inline-flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;

        > span {
            display: block;
        }

        > [class*="ss-icon-"] {
            margin: 0 0 0 0.714em;
        }
    }

    &.with-icon-sep {
        display: inline-flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        position: relative;

        &::after {
            width: 1px;
            position: absolute;
            top: -1px;
            bottom: -1px;
            right: 2.75em;
            background-color: rgba($white, 0.47);
            content: '';
            display: block;
        }

        > span {
            display: block;
        }

        > [class*="ss-icon-"] {
            font-size: 0.75em;
            margin: 0 0 0 2.75em;
        }
    }

    [class*="ss-icon-"] {
        font-size: 1.25em;
        margin: 0 0.714em 0 0;
    }

    &-primary {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $primary-100;
            color: $white;
            border-color: $primary-100;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $primary-dark;
            color: $white;
            border-color: $primary-dark;
        }

        &:disabled,
        &.disabled {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }

    &-outline-primary {
        color: $body-color;
        background-color: transparent;
        border-color: $gray-20;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $primary;
            color: $white;
            border-color: $primary;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $primary-dark;
            color: $white;
            border-color: $primary-dark;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $primary;
            background-color: transparent;
        }
    }

    &-secondary {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $secondary-100;
            color: $white;
            border-color: $secondary-100;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $secondary-dark;
            color: $white;
            border-color: $secondary-dark;
        }

        &:disabled,
        &.disabled {
            background-color: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }

    &-outline-secondary {
        color: $body-color;
        border-color: $secondary;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $secondary;
            color: $white;
            border-color: $secondary;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $secondary-dark;
            color: $white;
            border-color: $secondary-dark;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $secondary;
            background-color: transparent;
        }
    }

    &-dark {
        background-color: $gray-dark;
        border-color: $gray-dark;
        color: $white;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $gray-dark-200;
            color: $white;
            border-color: $gray-dark-200;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $gray-darkblack;
            color: $white;
            border-color: $gray-darkblack;
        }

        &:disabled,
        &.disabled {
            background-color: $gray-dark-100;
            border-color: $gray-dark-100;
            color: $white;
        }
    }

    &-outline-dark {
        color: $body-color;
        border-color: $gray-dark;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $gray-dark;
            color: $white;
            border-color: $gray-dark;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $gray-darkblack;
            color: $white;
            border-color: $gray-darkblack;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $gray-dark;
            background-color: transparent;
        }
    }

    &-light {
        background-color: $gray;
        border-color: $gray;
        color: $body-color;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: lighten($gray, 1%);
            color: $body-color;
            border-color: lighten($gray, 1%);
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $gray-semi;
            color: $body-color;
            border-color: $gray-semi;
        }

        &:disabled,
        &.disabled {
            background-color: $gray;
            border-color: $gray;
            color: $body-color;
        }
    }

    &-outline-light {
        color: $body-color;
        border-color: $gray;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $gray;
            color: $body-color;
            border-color: $gray;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $gray-semi;
            color: $body-color;
            border-color: $gray-semi;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $gray;
            background-color: transparent;
        }
    }

    &-warning {
        background-color: $yellow;
        border-color: $yellow;
        color: $body-color;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: lighten($yellow, 5%);
            color: $body-color;
            border-color: lighten($yellow, 5%);
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $yellow-dark;
            color: $body-color;
            border-color: $yellow-dark;
        }

        &:disabled,
        &.disabled {
            background-color: $yellow;
            border-color: $yellow;
            color: $body-color;
        }
    }

    &-outline-warning {
        color: $body-color;
        border-color: $yellow;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $yellow;
            color: $body-color;
            border-color: $yellow;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $yellow-dark;
            color: $body-color;
            border-color: $yellow-dark;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $yellow;
            background-color: transparent;
        }
    }

    &-danger {
        background-color: $danger;
        border-color: $danger;
        color: $white;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: lighten($danger, 5%);
            color: $white;
            border-color: lighten($danger, 5%);
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $danger-dark;
            color: $white;
            border-color: $danger-dark;
        }

        &:disabled,
        &.disabled {
            background-color: $danger;
            border-color: $danger;
            color: $white;
        }
    }

    &-outline-danger {
        color: $body-color;
        border-color: $danger;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $danger;
            color: $white;
            border-color: $danger;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $danger-dark;
            color: $white;
            border-color: $danger-dark;
        }

        &:disabled,
        &.disabled {
            color: $body-color;
            border-color: $danger;
            background-color: transparent;
        }
    }

    &-white {
        background-color: $white;
        border-color: $white;
        color: $gray-dark-800;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            background-color: $gray-semi;
            color: $gray-dark-800;
            border-color: $gray-semi;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $gray-1100;
            color: $gray-dark-800;
            border-color: $gray-1100;
        }

        &:disabled,
        &.disabled {
            background-color: $white;
            border-color: $white;
            color: $gray-dark-800;
        }
    }

    &-icon {
        min-width: 4.8rem;
        min-height: 4.8rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        *:last-child {
            margin-right: 0;
        }
    }

    &-link {
        color: $primary !important;
        text-decoration: none;
        min-width: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    &-link-black {
        padding: 0;
        color: $gray-300 !important;
        font-weight: 400;

        i[class*=ss-icon-] {
            font-size: 1.3rem;
        }
    }
}
