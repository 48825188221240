.ss {
    &-aa {
        &-row {
            padding: 3rem 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;

            @media (min-width: 1200px) {
                flex-direction: row;
            }

            .h4 {
                margin-bottom: 0;

                @media (min-width: 1200px) {
                    margin-top: 1.7rem;
                }
            }
        }

        &-nav {
            margin: 0 -1.5rem;
            padding: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @media (min-width: 1200px) {
                margin: 0;
            }

            & > li {
                margin: 1rem;
                list-style: none;

                & > a {
                    padding: 1rem 2rem;
                    font-size: 1.6rem;
                    line-height: 1.75;
                    font-weight: 600;
                    color: $gray-300;
                    background-color: $gray;
                    border-radius: 0.6rem;
                    text-decoration: none !important;
                    display: block;

                    &:hover,
                    &.active {
                        color: $white;
                        background-color: $gray-300;
                    }
                }
            }
        }

        &-left {
            margin-bottom: 1rem;
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;

            @media (min-width: 1200px) {
                margin-bottom: 0;
                width: 16.9rem;
                max-width: 16.9rem;
                flex: 0 0 16.9rem;
            }
        }

        &-middle {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
        }

        &-right {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;

            @media (min-width: 1200px) {
                width: 23.4rem;
                max-width: 23.4rem;
                flex: 0 0 23.4rem;
            }

            .form-group {
                margin-top: 1rem;
                margin-bottom: 0;
            }

            .ss-icon-search {
                color: $gray-dark-1000;
            }
        }

        &-form {
            max-width: 35rem;
        }
    }
}
