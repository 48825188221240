.ss {
    &-y {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 992px) {
                margin-bottom: 6.6rem;
            }
        }

        &-box {
            padding-bottom: 3rem;

            @media (min-width: 768px) {
                padding-bottom: 0;
            }

            .h4 {
                margin-bottom: 0.5rem;

                a {
                    color: $black;
                    margin-bottom: 10px;
                    display: inline-block;
                    margin-top: 20px;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .btn {
                min-width: 14.5rem;
            }
        }

        &-imgbox {
            margin: 0 0 1.8rem 0;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 0.9rem;
            overflow: hidden;
            border: 0.1rem solid $gray-dark-700;
            background-color: $gray;
            box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.14);

            &::before {
                width: 100%;
                padding-top: 145.9459459459459%;
                content: '';
                display: block;
            }
        }

        &-right {
            &-content {
                padding: 2rem;

                .btn {
                    min-width: 20rem;
                }
            }
        }

        &-meta {
            margin-bottom: 0.7rem;
            font-size: 1.6rem;
            line-height: 1.75;
            font-weight: 400;
            color: $gray-300;
            display: block;
        }
    }
}
