.ss {
    &-zz {
        &-slider {
            padding-bottom: 5.9rem;

            &-row {
                align-items: center;
            }

            &-wrap {
                max-width: 108.8rem;
                margin: 0 auto;
            }

            .swiper-slide {
                height: auto;
            }

            &-controls {
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 992px) {
                    padding-top: 2.4rem;
                }
            }

            &-arrow {
                width: 3.5rem;
                height: 3.5rem;
                padding: 0.8rem 1.2rem;
                display: flex;
            }

            .swiper-pagination-bullets {
                position: static;
                display: flex;
            }

            .swiper-pagination-bullet {
                margin: 0 !important;
                padding: 1rem;
                width: auto;
                height: auto;
                background-color: transparent;
                opacity: 1;

                &::before {
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    content: '';
                    background-color: $gray-1100;
                    border-radius: 50%;
                }

                &-active {
                    &::before {
                        background-color: $primary;
                    }
                }
            }

            &-left {
                padding: 3rem 2rem;
                border-radius: 2rem;
                position: relative;
                background-color: $gray-1300;

                @media (min-width: 1200px) {
                    max-width: 56.8rem;
                    padding: 6.9rem 5rem 6.4rem 5rem;
                }

                &-inr {
                    max-width: 28.3rem;
                    position: relative;
                }
            }

            &-right {
                padding: 4rem 0;

                @media (min-width: 1200px) {
                    padding-left: 5.5rem;
                }
            }

            &-box-bg {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.35;
                pointer-events: none;
                z-index: 0;
            }
        }

        &-technology {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            list-style: none;

            > li {
                margin: 0 1.4rem 0.5rem 0;
                padding: 0;
                display: block;
                list-style: none;
            }

            &-icon {
                margin-bottom: 0.5rem;
                width: 4.8rem;
                height: 4.8rem;
                font-size: 4.8rem;
                border-radius: 1rem;
                background-color: $white;
                filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.0702579));
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-text {
                font-size: 1rem;
                line-height: 1.3rem;
                color: $gray-300;
                text-align: center;
                display: block;
            }
        }
    }
}
