.ss {
    &-aaa {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-cp {
            &-row {
                margin-bottom: 7rem;

                .col,
                [class*=col-] {
                    margin: 1.5rem 0;
                }
            }

            &-box {
                height: 100%;
                padding: 1.6rem;
                background: $gray-1700;
                border-radius: 1rem;
                display: flex;
                align-items: center;
                text-decoration: none !important;

                @media (min-width: 1400px) {
                    padding: 2.5rem 1.9rem 2.5rem 2.3rem;
                }

                &.active {
                    background: $white;
                    box-shadow: 0px 0.8rem 3rem rgba(0, 0, 0, 0.18);
                }
            }

            &-icon {
                flex-shrink: 0;
                margin-right: 1.6rem;
                width: 5.2rem;
                height: 5.2rem;
                font-size: 3.2rem;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $gray;
                border-radius: 0.6rem;

                @media (min-width: 1400px) {
                    margin-right: 2.3rem;
                }
            }

            &-desc {
                flex: 1;

                > p {
                    color: $gray-dark-400 !important;
                    font-weight: 600;
                }
            }
        }

        &-dicision {
            &-row {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                @media (min-width: 992px) {
                    flex-direction: row;
                }

                .btn-spacer {
                    padding-top: 3rem;

                    > .btn {
                        min-width: 13rem;
                        margin-right: 0.5rem;

                        @media (min-width: 992px) {
                            margin-right: 2.7rem;
                            min-width: 15.6rem;
                        }
                    }
                }
            }

            &-left {
                margin-bottom: 4rem;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                    width: 42.73504273504274%;
                    max-width: 42.73504273504274%;
                    flex: 0 0 42.73504273504274%;
                }
            }

            &-right {
                flex: 1;

                @media (min-width: 992px) {
                    padding-left: 3rem;
                }

                @media (min-width: 1200px) {
                    padding-left: 6.6rem;
                }

                a {
                    margin-right: 2rem;
                }
            }

            &-imgbox {
                margin: 0;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                background-color: $gray;
                border: 0.1rem solid $gray-1400;
                border-radius: 2rem;
                overflow: hidden;

                &::before {
                    width: 100%;
                    padding-top: 87.2%;
                    content: '';
                    display: block;
                }
            }
        }

        &-list {
            margin: 0 -1rem;
            padding: 0 0 3rem 0;
            position: relative;
            display: flex;
            flex-wrap: wrap;

            li::before {
                display: none;
            }

            &-box {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
                padding: 0.5rem 1rem;
                position: relative;
                display: flex;
                font-size: 1.8rem;
                color: $gray-300;

                @media (min-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                @media (min-width: 992px) {
                    width: 50%;
                    max-width: 50%;
                    flex: 0 0 50%;
                    padding: 1rem;
                }

                @media (min-width: 1400px) {
                    padding: 1.7rem 1rem;
                }
            }
        }

        &-check-icon {
            margin-top: 0.1rem;
            margin-right: 1.7rem;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2rem;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
