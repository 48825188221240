.ss {
    &-j {
        &-head {
            margin-bottom: 3.9rem;

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-answer {
            &-accordion {
                margin: 0 -1.5rem;
                display: flex;
                flex-wrap: wrap;
            }

            &-item {
                width: 100%;
                padding: 1.5rem;

                @media (min-width: 992px) {
                    width: 50%;
                }
            }

            &-box {
                border: 1px solid $gray-1200;
                border-radius: 1rem;
            }
        }

        &-accordion {
            &-body {
                padding: 1.6rem;
                border-top: 1px solid $gray-1200;
            }

            &-button {
                width: 100%;
                min-height: 5rem;
                padding: 1rem 4rem 1rem 1.6rem;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 1.33;
                color: $gray-300;
                box-shadow: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                outline: none;
                border: none;
                background: none;
                text-align: left;

                @media (min-width: 992px) {
                    font-size: 2rem;
                }

                @media (min-width: 1200px) {
                    min-height: 8rem;
                    font-size: 2.4rem;
                    padding: 1rem 4.2rem 1rem 3.2rem;
                }

                &:focus {
                    box-shadow: none;
                }

                &::after {
                    display: none;
                }

                .ss-icon-minus {
                    display: block;
                }

                .ss-icon-plus {
                    display: none;
                }

                &.collapsed {
                    .ss-icon-minus {
                        display: none;
                    }

                    .ss-icon-plus {
                        display: block;
                    }
                }

                &.sm {
                    font-size: 1.6rem;
                    min-height: 6.4rem;
                }
            }

            &-icon {
                width: 4rem;
                height: 4rem;
                font-size: 1.4rem;
                color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                right: 0;
                position: absolute;
                transform: translateY(-50%);

                @media (min-width: 1200px) {
                    width: 6.4rem;
                    height: 6.4rem;
                }
            }
        }
    }
}
