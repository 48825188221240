.ss {
    &-cc {
        &-article {
            padding: 0 2rem;
            margin-bottom: 4.5rem;
            border-radius: 1.5rem;

            @media (min-width: 768px) {
                padding: 0 3rem;
            }

            @media (min-width: 1200px) {
                padding: 0 6.5rem;
            }

            > .row {
                align-items: center;
                flex-direction: column-reverse;

                @media (min-width: 768px) {
                    flex-direction: row;
                }
            }

            &-left {
                padding: 3rem 0;
                color: $white;

                h2 {
                    padding-bottom: 3rem;
                    position: relative;

                    @media (min-width: 1200px) {
                        font-size: 4rem;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        max-width: 9.7rem;
                        background-color: rgba($white, 0.5);
                        height: 0.1rem;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                    }
                }
            }

            &-imgbox {
                margin: 0 auto;
                width: 100%;
                max-width: 52.2rem;
                position: relative;
                display: block;

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-footer-lbl {
                font-size: 1.7rem;
                line-height: 1.647;
                font-weight: 400;
                color: $white;
                text-transform: uppercase;
                display: block;
            }
        }
    }
}
