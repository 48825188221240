.ss {
    &-b {
        border-bottom: 0.2rem solid $gray-800;

        .ss-section-inner {
            padding: 0;
        }

        &-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            @media (min-width: 992px) {
                flex-direction: row;
                align-items: center;
            }
        }

        &-left {
            padding: 1rem 0;
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;

            @media (min-width: 992px) {
                padding: 0;
                width: 18.8rem;
                max-width: 18.8rem;
                flex: 0 0 18.8rem;
            }
        }

        &-right {
            padding: 0;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            @media (min-width: 992px) {
                justify-content: flex-end;
            }

            > a {
                @media (max-width: 767px) {
                    flex: 0 0 44%;
                    width: 44%;
                    max-width: 54%;
                }
            }
        }

        &-imgbox {
            margin: 0.5rem 1rem;
            width: auto;
            height: 4.7rem;
            position: relative;
            display: block;

            @media (min-width: 992px) {
                margin: 1.3rem 1.6rem 1.1rem 1.6rem;
            }

            @media (min-width: 1200px) {
                margin-left: 2.6rem;
                margin-right: 2.6rem;
            }

            @media (min-width: 1400px) {
                margin-left: 3.6rem;
                margin-right: 3.6rem;
            }

            &::before {
                display: none;
            }

            .lazy-loader {
                position: relative;

                .lazyload {
                    position: static;
                    height: 4.7rem;
                    width: auto;
                }
            }
        }
    }
}
