.alert {
    border-radius: 0.6rem;
    font-weight: 600;

    [class*="ss-icon-"] {
        font-size: 1.143em;
        margin-right: 1.2rem;
    }

    .btn-close {
        outline: none;
        box-shadow: none;
        color: $body-color;
        opacity: 1;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.7em auto no-repeat;

        &:focus {
            background-color: rgba($black, 0.05);
        }
    }

    &-primary {
        background-color: rgba($primary, 0.1);
        border-color: transparent;
        color: $primary;
    }

    &-success {
        background-color: rgba($secondary, 0.1);
        border-color: transparent;
        color: $secondary;
    }

    &-warning {
        background-color: rgba($yellow, 0.1);
        border-color: transparent;
        color: $yellow;
    }

    &-danger {
        background-color: rgba($danger, 0.1);
        border-color: transparent;
        color: $danger;
    }

    &-light {
        background-color: $white;
        border-color: transparent;
        color: $body-color;
        box-shadow: 0rem 0.2rem 1.4rem rgba($black, 0.12);
    }

    &-dark {
        background-color: rgba($gray-dark, 0.05);
        border-color: transparent;
        color: $gray-dark;
    }
}
