.ss {
    &-jj {
        &-imgbox {
            margin-top: 3rem;
            width: 100%;
            max-width: 100%;
            position: relative;
            display: block;
            border-radius: 1rem;
            overflow: hidden;
            background-color: $gray;

            @media (min-width: 992px) {
                margin-top: 4rem;
            }

            @media (min-width: 1200px) {
                margin-top: 6rem;
            }

            &::before {
                width: 100%;
                padding-top: 43.50877192982456%;
                content: '';
                display: block;
            }

            &.large {
                margin-top: 0;

                &::before {
                    padding-top: 42.64957264957265%;
                }
            }
        }
    }
}
