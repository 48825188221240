.ss {
    &-ww {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 992px) {
                margin-bottom: 6.6rem;
            }
        }

        &-clients {
            > .col,
            > [class*=col-] {
                margin: 1.5rem 0;
            }

            &-imgbox {
                margin: 0;
                width: 100%;
                max-width: 100%;
                position: relative;
                display: block;
                background-color: $gray-dark-500;

                > .lazy-loader {
                    padding: 1.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > .lazyload {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }
        }
    }
}
