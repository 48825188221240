.modal {
    min-width: 320px;

    &.modal-static {
        .modal-dialog {
            transform: none;
        }
    }

    &-dialog {
        max-width: 60rem;

        .small & {
            max-width: 72rem;
        }

        .medium & {
            max-width: 96.5rem;
        }

        .large & {
            max-width: 116rem;
        }
    }

    &-content {
        border-radius: 1rem;
        background-color: $white;
        border: none;
        box-shadow: 0rem 0.2rem 0.9rem rgba($black, 0.18);
    }

    &-header {
        padding: 2rem 5rem 2rem 2rem;
        position: relative;
    }

    &-body {
        padding: 2rem;
    }

    &-footer {
        padding: 2rem;

        > .btn {
            margin: 0 0.5rem;

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &-backdrop {
        &.show {
            opacity: 0.65;
        }
    }
}

.ss {
    &-modal {
        &-title {
            width: 1%;
            margin: 0;
            flex: 1 1 auto;
        }

        &-close {
            width: 5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            color: $body-color !important;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;

            &.outside {
                color: $white !important;
                width: 4rem;
                height: 4rem;
                bottom: 100%;
                top: auto;
                right: 0;
            }

            &:focus {
                background-color: rgba($black, 0.1);
            }
        }
    }
}
