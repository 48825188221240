.ss {
    &-dd {
        .ss-t-desc {
            margin-bottom: 3rem;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: 0;
            }
        }
    }
}
