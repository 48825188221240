.ss {
    &-kkk {
        .tab-pane {
            > :last-child {
                margin-bottom: 0;
            }
        }

        ul {
            &.checklist {
                &.two-column {
                    column-count: 1;
                    column-gap: 1rem;
        
                    @media (min-width: 768px) {
                        column-count: 2;
                    }
        
                    > li {
                        width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        min-height: 2.8rem;
                    }
                }
        
                &.three-column {
                    column-count: 1;
                    column-gap: 1rem;
        
                    @media (min-width: 768px) {
                        column-count: 2;
                    }

                    @media (min-width: 1200px) {
                        column-count: 3;
                    }
        
                    > li {
                        width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        min-height: 2.8rem;
                    }
                }
            }
        }

        &-maintab {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            margin: 4.2rem 0 5rem 0;

            @media (min-width: 1200px) {
                margin: 4.2rem 0 6.9rem 0;
            }

            > li {
                @media (max-width: 575px) {
                    width: 100%;
                    flex: 0 0 100%;
                }

                > a {
                    font-size: 1.6rem;
                    font-weight: 600;
                    display: block;
                    padding: 1.2rem 2rem;
                    min-width: 18.6rem;
                    border: 1px solid $gray-1200;
                    border-radius: 0.3rem;
                    color: $body-color !important;
                    text-decoration: none !important;
                    position: relative;
                    margin: 0 -0.3rem;
                    background-color: $white;
                    text-align: center;

                    @media (max-width: 575px) {
                        margin: 0.3rem;
                    }

                    &.active {
                        background-color: $body-color;
                        border-color: $body-color;
                        color: $white !important;
                        z-index: 10;
                    }
                }
            }
        }

        &-odcp {
            margin: 5rem 0;

            @media (min-width: 1200px) {
                margin: 6.9rem 0;
            }

            &-l {
                &-desc {
                    max-width: 16rem;
                }
            }
        }

        &-box {
            border: 1px solid $gray-1200;
            border-radius: 2rem;
            padding: 4rem;
        }

        &-lp {
            margin: 5rem 0;

            @media (min-width: 1200px) {
                margin: 6.9rem 0;
            }

            > .h4 {
                margin-bottom: 5rem;

                @media (min-width: 1200px) {
                    margin: 6.9rem 0;
                }
            }

            &-l {
                &-desc {
                    @media (min-width: 992px) {
                        max-width: 20rem;
                    }
                }
            }

            &-tabnav {
                display: flex;
                margin: 0 -1.5rem 0 -1.5rem;
                padding: 0;
                list-style: none;
                flex-wrap: wrap;

                @media (max-width: 991px) {
                    margin: 0 -1rem 0 -1rem;
                }

                > li {
                    width: 16.66%;
                    flex: 0 0 16.66%;
                    max-width: 16.66%;
                    list-style: none;
                    margin: 0 0 4.3rem 0;
                    padding: 0 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (max-width: 991px) {
                        padding: 0 1rem;
                    }

                    @media (max-width: 767px) {
                        margin: 0 0 3rem 0;
                    }

                    &:first-child {
                        @media (max-width: 991px) {
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                    }

                    &:not(:first-child) {
                        @media (max-width: 991px) {
                            width: 20%;
                            flex: 0 0 20%;
                            max-width: 20%;
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }

            &-tabitem {
                padding: 0.9rem 0.9rem 2rem 0.9rem;
                border: 1px solid $gray-1200;
                border-radius: 1rem;
                color: inherit !important;
                text-decoration: none !important;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;

                &.active {
                    border-color: $primary;
                    box-shadow: 0 0 0 0.1rem $primary;
                    background-color: rgba($primary, 0.05);

                    .ss-kkk-lp-dot {
                        .ss-icon-circle-fill {
                            display: block;
                        }
                    }

                    .ss-kkk-lp-arrow {
                        display: block;
                    }
                }

                .h4 {
                    margin: 0;
                }

                small {
                    font-size: 1.4rem;
                    font-weight: 400;
                    display: block;
                }
            }

            &-dot {
                width: 2.4rem;
                height: 2.4rem;
                border: 1px solid $gray-dark-300;
                color: $primary;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                margin-top: 1rem;

                .ss-icon-circle-fill {
                    display: none;
                }
            }

            &-arrow {
                width: 0;
                height: 0;
                border-left: 1.3rem solid transparent;
                border-right: 1.3rem solid transparent;
                border-top: 1.3rem solid $primary;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                display: none;
                margin-top: 0.1rem;
            }

            &-sep {
                background-color: $gray-800;
                opacity: 0.5;
                margin: 5rem 0;
            }

            &-ao {
                &-c {
                    margin-top: 3.6rem;

                    > :last-child {
                        margin-bottom: 0 !important;
                    }

                    &:nth-child(1) {
                        margin-top: 0;
                    }

                    &:nth-child(2) {
                        margin-top: 3.6rem;

                        @media (min-width: 768px) {
                            margin-top: 0;
                        }
                    }

                    &-head {
                        font-size: 1.6rem;
                        margin-bottom: 2.6rem;

                        @media (min-width: 768px) {
                            font-size: 1.8rem;
                        }
                    }

                    .ss-checkradio {
                        padding: 1rem 2rem;
                        border: 1px solid $gray-dark-300;
                        border-radius: 1rem;
                        margin-bottom: 2rem;
                        align-items: center;
                        min-height: 7.4rem;
                    }

                    .ss-checkradio-text {
                        margin-left: 2rem;
                    }
                }
            }
        }

        &-salesform {
            > .row {
                > .col {
                    @media (max-width: 767px) {
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        &-sol {
            &-tabnav {
                display: flex;
                margin: 0 -1.5rem 0 -1.5rem;
                padding: 0;
                list-style: none;
                flex-wrap: wrap;

                @media (max-width: 991px) {
                    margin: 0 -1rem 0 -1rem;
                }

                > li {
                    width: 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                    list-style: none;
                    margin: 0 0 6rem 0;
                    padding: 0 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (max-width: 991px) {
                        padding: 0 1rem;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin: 0 0 3rem 0;
                    }
                }
            }

            &-tabitem {
                border: 1px solid $gray-1200;
                border-radius: 1rem;
                padding: 4rem;
                color: inherit !important;
                text-decoration: none !important;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                font-weight: 400;

                &.active {
                    border-color: $primary;
                    box-shadow: 0 0 0 0.1rem $primary;

                    .ss-kkk-lp-arrow {
                        display: block;
                    }
                }
            }

            &-t {
                margin-bottom: 4rem;

                ul.checklist {
                    li {
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }

            &-b {
                margin-top: auto;

                .btn-outline-primary {
                    color: $primary !important;
                    background-color: transparent !important;
                    border-color: $gray-20 !important;

                    > [class*="ss-icon-"] {
                        font-size: 1.4rem;
                    }
                }
            }

            &-area {
                &-c {
                    margin-top: 2rem;

                    &:nth-child(1) {
                        margin-top: 0;
                    }

                    &:nth-child(2) {
                        margin-top: 2rem;

                        @media (min-width: 768px) {
                            margin-top: 0;
                        }
                    }
                }
            }

            &-clps {
                > .p2 {
                    padding: 0 2rem 2rem 2rem;
                }

                &-g {
                    border: 1px solid $gray-dark-300;
                    border-radius: 1rem;
                }

                &-t {
                    display: flex;
                    align-items: center;

                    .ss-checkradio {
                        width: 1%;
                        flex: 1 1 auto;
                        padding: 1rem 2rem;
                        align-items: center;
                        min-height: 7.4rem;
                    }

                    .ss-checkradio-text {
                        margin-left: 2rem;
                    }
                }

                &-arrow {
                    width: 5rem;
                    flex-shrink: 0;
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    color: $primary !important;
                    transform: rotate(180deg);
                    transition: transform 0.15s, color 0.15s;

                    &.collapsed {
                        transform: rotate(0deg);
                        color: $gray-dark-1000 !important;
                    }
                }
            }
        }
    }
}
