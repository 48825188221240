.ss {
    &-ss {
        .ss-cc-head {
            margin-bottom: 5rem;
            text-align: center;

            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }
        }

        &-coaching {
            &-row {
                padding: 0;
                display: flex;
                align-items: center;

                @media (min-width: 1200px) {
                    padding: 4rem 0 2.4rem 0;
                }
            }

            &-box {
                margin-bottom: 4rem;
                display: flex;
                align-items: center;

                @media (min-width: 1200px) {
                    margin-bottom: 9.6rem;
                }

                > h3 {
                    margin-bottom: 0;
                }
            }

            &-icon {
                margin-right: 2rem;
                flex-shrink: 0;
                width: 5rem;
                height: 5rem;
                font-size: 2.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 1;

                @media (min-width: 1200px) {
                    margin-right: 3rem;
                    width: 7.1rem;
                    height: 7.1rem;
                    font-size: 4.2rem;
                }

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    border-radius: 1rem;
                    background-color: $gray;
                    transform: rotate(45deg);
                }

                &.light-blue {
                    &::before {
                        background-color: rgba($light-blue, 0.1);
                    }
                }

                &.light-green {
                    &::before {
                        background-color: rgba($light-green, 0.1);
                    }
                }

                &.green {
                    &::before {
                        background-color: rgba($green, 0.1);
                    }
                }
            }

            &-text {
                margin-bottom: 0;
                font-size: 2.4rem;
                line-height: 1.333;
                font-weight: 600;
                color: $gray-300;
                text-decoration: none !important;
            }
        }

        &-process {
            margin-bottom: 4rem;
            padding: 3rem 3rem 2.2rem 3rem;
            background-color: rgba($gray-35, 0.15);
            border-radius: 2rem;

            @media (min-width: 1200px) {
                padding: 4.1rem 6.8rem 2.2rem 6.8rem;
            }
        }
    }
}
