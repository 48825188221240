.ss {
    &-footer {
        &-a {
            padding: 3rem 0 1.9rem 0;
            border-top: 0.1rem solid $gray-700;

            @media (min-width: 1200px) {
                padding-top: 5.8rem;
            }
        }

        &-b {
            padding: 2.2rem 0 2.3rem 0;
            border-top: 0.1rem solid $gray-700;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            @media (min-width: 576px) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &-logo {
            margin-bottom: 2rem;
            width: 13.8rem;
            position: relative;
            display: block;

            @media (min-width: 1200px) {
                margin-bottom: 4rem;
            }

            &::before {
                padding-top: 30.43478260869565%;
                width: 100%;
                content: '';
                display: block;
            }
        }

        &-social {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            > li {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;

                > a {
                    width: 3.2rem;
                    height: 3.2rem;
                    font-size: 1.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:last-child::before {
                    content: '';
                    height: 20px;
                    width: 2px;
                    display: inline-block;
                    background: $gray-700;
                    position: relative;
                    top: 6px;
                }
            }
        }

        &-copyright {
            font-size: 1.4rem;
            color: $gray-300;
            display: block;
        }

        &-info {
            margin: 0 0 3rem 0;
            padding: 0;
            list-style: none;
            display: block;

            > li {
                margin: 0 0 0.4rem 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;

                > a {
                    font-size: 1.6rem;
                    line-height: 1.375;
                    color: rgba($gray-300, 0.6);
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &-icon {
                margin-right: 1.2rem;
                width: 2rem;
                height: 2rem;
                font-size: 1.7rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-cell {
            padding: 0 0 2.4rem 0;

            .h6 {
                margin-bottom: 1.8rem;
                text-transform: uppercase;
            }
        }

        &-nav {
            margin: 0;
            padding: 0;
            list-style: none;
            display: block;

            > li {
                margin: 0;
                padding: 0;
                list-style: none;
                display: block;

                > a {
                    padding: 0.4rem 0;
                    font-size: 1.6rem;
                    line-height: 1.375;
                    color: rgba($gray-300, 0.6);
                    font-weight: 400;
                    display: block;
                }
            }
        }
    }

    &-sd-cell {
        padding: 0 0 3.8rem 0;

        address {
            font-size: 1.6rem;
            line-height: 1.375;
            color: rgba($gray-300, 0.6);
        }

        .btn {
            min-width: 17rem;
        }
    }
}
