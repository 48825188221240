.ss {
    &-yy {
        .ss-cc-head {
            margin-bottom: 5rem;

            @media (min-width: 992px) {
                margin-bottom: 6.6rem;
            }
        }

        &-slider {
            padding-bottom: 12.6rem;

            .swiper-slide {
                height: auto;
            }

            .swiper-pagination-bullets {
                bottom: 3.6rem;
            }

            .swiper-pagination-bullet {
                margin: 0 !important;
                padding: 0.8rem;
                width: auto;
                height: auto;
                background-color: transparent;
                opacity: 1;

                &::before {
                    width: 1.6rem;
                    height: 1.6rem;
                    display: block;
                    content: '';
                    background-color: $gray-900;
                    border-radius: 50%;
                }

                &-active {
                    &::before {
                        background-color: $primary;
                    }
                }
            }

            &-box {
                padding: 1.6rem 1.1rem 1.6rem 1.6rem;
                background-color: rgba($gray-35, 0.15);
                border-radius: 2rem;
                height: 100%;

                @media (min-width: 992px) {
                    min-height: 26.5rem;
                    padding: 2.2rem 1.1rem 2.9rem 2.2rem;
                }

                &-head {
                    display: flex;
                    align-items: center;

                    &-r {
                        flex: 1;
                    }
                }

                &-desc {
                    margin-bottom: 2.5rem;
                }
            }

            &-user-expert {
                margin-right: 1.4rem;
                width: 5rem;
                height: 5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-shrink: 0;
                background-color: $white;
                border-radius: 50%;
                transition: background-color 0.25s, color 0.25s;
                overflow: hidden;
                position: relative;

                @media (min-width: 992px) {
                    width: 7rem;
                    height: 7rem;
                }

                &::before {
                    width: 100%;
                    padding-top: 100%;
                    content: '';
                    display: block;
                }
            }

            &-expert-name {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 600;
                color: $gray-300;
                text-transform: uppercase;
                display: block;
            }

            &-expert-category {
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 400;
                color: $gray-300;
                display: block;
            }
        }
    }
}
