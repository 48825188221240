$white: #fff;
$black: #000;
$primary: #539cc1;
$primary-dark: #387899;
$primary-100: #6db8de;
$primary-200: #e1f5ff;
$secondary: #7bba52;
$secondary-dark: #649d3f;
$secondary-100: #98c978;
$gray: #f5f5f5;
$gray-semi: #e4e4e4;
$gray-inactive: #b2b2b3;
//$gray-dark: #4a4a4a;
$gray-dark: #222;
$gray-darkblack: #383838;
$gray-dark-100: #2a2a2a;
$gray-dark-200: #4f4f4f;
$gray-dark-300: #ccc;
$gray-dark-400: #404041;
$gray-dark-500: #f6f6f6;
$gray-dark-600: #9a9a9a;
$gray-dark-700: #e7e7e7;
$gray-dark-800: #222;
$gray-dark-900: #808080;
$gray-dark-1000: #b3b3b3;
$gray-dark-1100: #1d1d1d;
$gray-dark-1200: #dadada;
$gray-35: #eaeaea;
$gray-15: rgba($gray-35, 0.15);
$gray-50: #c4c4c4;
$gray-100: #b8b8b8;
$gray-150: #e5e8ed;
$gray-250: #e1e1e1;
//$gray-300: #333;
$gray-300: $gray-dark;
$gray-20: rgba(51, 51, 51, 0.2);
$gray-400: rgba(51, 51, 51, 0.5);
$gray-500: #eee;
$gray-600: rgba($gray-50, 0.5);
$gray-700: #ececec;
$gray-800: #979797;
$gray-900: #efefef;
$gray-1000: #f8f8f8;
$gray-1100: #d8d8d8;
$gray-1200: #ddd;
$gray-1300: #f9fafa;
$gray-1400: #ebebeb;
$gray-1500: #fcfcfc;
$gray-1600: #d7d7d7;
$gray-1700: #f9f9f9;
$yellow: #f8c124;
$yellow-dark: #edb749;
$yellow-dark-2: #fea114;
$danger: #ee6262;
$danger-dark: #e63935;
$body-color: $gray-dark;
$pink: #df4469;
$orange: #e5574f;
$soft-orange: #e3cfb3;
$dark-orange: #d9925b;
$dark-orange-100: #d9833f;
$purple: #8462ee;
$light-blue: #539eb9;
$light-blue-100: #daedf7;
$light-green: #58a5a9;
$green: #64ba79;
$lime-green: #b8d7cf;
$soft-blue: #b2d9f8;

@mixin stylish-scroll($width, $height, $track-bg, $thumb-bg) {
    scrollbar-width: thin;
    scrollbar-color: $thumb-bg $track-bg;

    @if $width {
        &::-webkit-scrollbar {
            width: $width;
        }
    }

    @if $height {
        &::-webkit-scrollbar {
            height: $height;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: $track-bg;
        border-radius: 2rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-bg;
        border-radius: 2rem;
    }
}
