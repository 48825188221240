textarea.form-control {
    height: 7rem;
}

.form {
    &-group {
        margin-bottom: 1.4rem;
    }

    &-control {
        font-size: 1.8rem;
        font-weight: 400;
        background-color: $white;
        border-radius: 0.6rem;
        border: 1px solid $gray-50;
        box-shadow: none;
        padding: 0.85rem 1.4rem;
        height: 4.8rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
        color: $body-color;

        .theme-b & {
            background: rgba($white, 0.2);
            border-color: $gray-dark-300;
            color: $white;
        }

        .theme-b &:not(:disabled):not(.disabled):not([readonly]):focus {
            color: $white;
            border-color: $gray-100;
            background-color: rgba($black, 0.03);
            box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100;
        }

        &::-webkit-input-placeholder {
            color: $gray-inactive;
            opacity: 1;
        }
        
        &:-moz-placeholder {
            color: $gray-inactive;
            opacity: 1;
        }
        
        &::-moz-placeholder {
            color: $gray-inactive;
            opacity: 1;
        }
        
        &:-ms-input-placeholder {
            color: $gray-inactive;
            opacity: 1;
        }

        &:not(:disabled):not(.disabled):not([readonly]):hover {
            border-color: $gray-100;
            background-color: rgba($black, 0.03);
            box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100;
        }

        &:not(:disabled):not(.disabled):not([readonly]):focus {
            background-color: $white;
            border-color: $primary;
            box-shadow: none;
        }

        &.is-invalid,
        .was-validated &:invalid {
            border-color: $danger !important;
            box-shadow: none !important;
            background-image: none;
            padding-right: 1.4rem;
        }

        &.is-valid,
        .was-validated &:valid {
            border-color: $secondary !important;
            box-shadow: none !important;
            background-image: none;
            padding-right: 1.4rem;
        }

        &:disabled,
        &.disabled {
            background-color: $gray !important;
            border-color: rgba($black, 0.12) !important;
            box-shadow: none !important;
        }

        &[readonly] {
            background-color: $gray !important;
            border-color: rgba($black, 0.12) !important;
            box-shadow: none !important;
        }

        &[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 1em;
            width: 1em;
            background: url(../images/search-close.svg) no-repeat center center;
            background-size: 1em 1em;
            position: relative;
            left: 0.75rem;
        }

        &-wrapper {
            display: block;
            position: relative;

            &.with-icon-left {
                .form-control {
                    padding-left: 4.2rem;
                }

                [class*="ss-icon-"] {
                    position: absolute;
                    top: 50%;
                    left: 1.2rem;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }

            &.with-icon-right {
                .form-control {
                    padding-right: 4.2rem;
                }

                [class*="ss-icon-"] {
                    position: absolute;
                    top: 50%;
                    right: 1.2rem;
                    transform: translateY(-50%);
                    pointer-events: none;
                }

                .ss-icon-check-outline {
                    font-size: 1.3rem;
                    color: #7bba52;
                }

                .ss-icon-close {
                    font-size: 1rem;
                    color: #ee6262;
                }
            }

            &.with-sep-right {
                &::after {
                    width: 1px;
                    top: 1px;
                    bottom: 1px;
                    right: 3.7rem;
                    display: block;
                    content: '';
                    background-color: $gray-50;
                    position: absolute;
                    pointer-events: none;
                }
            }

            &.with-url-prefix {
                .form-control {
                    padding-left: 8rem;
                }

                .url-prefix-text {
                    font-size: 1.8rem;
                    position: absolute;
                    top: 52%;
                    left: 1.4rem;
                    height: 2.4rem;
                    line-height: 2.4rem;
                    display: block;
                    border-right: 1px solid $gray-50;
                    padding-right: 1rem;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
        }
    }

    &-label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 600;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
}

.select2 {
    &-container {
        width: 100% !important;
        font-size: 1.8rem;

        &--focus:not(.select2-container--disabled) {
            .select2-selection--single {
                border-color: $primary !important;
                box-shadow: none !important;
            }

            .select2-selection--multiple {
                border-color: $primary !important;
                box-shadow: none !important;
            }

            .theme-b & .select2-selection--single {
                border-color: $gray-100 !important;
                background-color: rgba($black, 0.03);
                box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100;
            }
        }

        &:not(.select2-container--focus):not(.select2-container--disabled):hover {
            .select2-selection--single {
                border-color: $gray-100 !important;
                background-color: rgba($black, 0.03) !important;
                box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100 !important;
            }

            .select2-selection--multiple {
                border-color: $gray-100 !important;
                background-color: rgba($black, 0.03) !important;
                box-shadow: 0 0 0 2px rgba($black, 0.03), inset 0 0 0 1px $gray-100 !important;
            }
        }

        &--disabled {
            .select2-selection--single {
                background-color: $gray !important;
                border-color: rgba($black, 0.12) !important;
                box-shadow: none !important;
            }

            .select2-selection--multiple {
                background-color: $gray !important;
                border-color: rgba($black, 0.12) !important;
                box-shadow: none !important;
            }
        }
    }

    &-selection {
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;

        &--multiple {
            color: $black;
            border: 1px solid $gray-50 !important;
            outline: none !important;
            border-radius: 0.6rem !important;
            padding-right: 1.4rem !important;
            padding-bottom: 0.7rem !important;
            min-height: 4.8rem !important;
            
            .select2-selection__rendered {
                margin: 0 !important;
            }

            .select2-search--inline {
                margin-top: 0 !important;
                position: relative;
                top: 0;

                .select2-search__field {
                    height: 2.1rem;
                }
            }

            .select2-search__field {
                margin-top: 1.1rem !important;
                margin-left: 1.4rem !important;
                color: $body-color !important;
                appearance: none;
                -webkit-appearance: none;

                &::-webkit-input-placeholder {
                    color: $gray-inactive;
                    opacity: 1;
                }
                
                &:-moz-placeholder {
                    color: $gray-inactive;
                    opacity: 1;
                }
                
                &::-moz-placeholder {
                    color: $gray-inactive;
                    opacity: 1;
                }
                
                &:-ms-input-placeholder {
                    color: $gray-inactive;
                    opacity: 1;
                }
            }
        }

        &--single {
            height: 4.8rem !important;
            border: 1px solid $gray-50 !important;
            outline: none !important;
            border-radius: 0.6rem !important;

            .theme-b & {
                background: rgba($white, 0.2);
                border-color: $gray-dark-300;
            }

            .select2-selection__rendered {
                line-height: 4.6rem !important;
                padding-left: 1.4rem !important;
                padding-right: 4rem !important;

                .theme-b & {
                    color: $white;
                }
            }

            .select2-selection__placeholder {
                color: $gray-inactive !important;
            }
            
            .select2-selection__arrow {
                display: block !important;
                width: 4.6rem !important;
                height: 4.6rem !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3e%3cpath fill='%234a4a4a' d='M18.4,5.3l-0.8-0.8c-0.2-0.2-0.5-0.2-0.7,0l-7.4,7.4L2.1,4.5c-0.2-0.2-0.5-0.2-0.7,0L0.6,5.3C0.4,5.5,0.4,5.8,0.6,6l8.6,8.6c0.2,0.2,0.5,0.2,0.7,0L18.4,6C18.6,5.8,18.6,5.5,18.4,5.3z'/%3e%3c/svg%3e");
                background-size: 1.1rem 1.1rem;
                background-repeat: no-repeat;
                background-position: center center;

                .theme-b & {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23ffffff' d='M5,8l5-6H0L5,8z'/%3e%3c/svg%3e");
                }

                .theme-c & {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23333333' d='M5,8l5-6H0L5,8z'/%3e%3c/svg%3e");
                }

                b {
                    display: none !important;
                }
            }
        }

        &__arrow {
            display: none !important;

            .select2-container--open & {
                transform: rotate(180deg);
            }
        }

        &__choice {
            padding-left: 0.8rem !important;
            padding-right: 2.4rem !important;
            padding-top: 0.35rem !important;
            padding-bottom: 0.35rem !important;
            border: none !important;
            font-size: 1.2rem;
            margin-top: 1rem !important;
            margin-left: 0.8rem !important;
            background-color: $gray !important;

            &:hover {
                background-color: $primary !important;
                color: $white !important;

                .select2-selection__choice__remove {
                    color: $white !important;
                }
            }

            &__remove {
                left: auto !important;
                right: 0;
                border: none !important;
                font-size: 1.8rem !important;
                font-weight: 600 !important;
                color: $body-color !important;
                line-height: 1.35em !important;
                padding: 0 0.8rem 0 0.8rem !important;
                background-color: transparent !important;
            }
        }
    }

    &-dropdown {
        border: none;
        border-radius: 0.6rem !important;
        box-shadow: 0 1px 2px rgba($black, 0.18);
        z-index: 9999;

        &--below {
            margin-top: 0.3rem;
        }

        &--above {
            margin-top: -0.3rem;
        }

        .select2-search__field {
            outline: none !important;
            border-radius: 0.6rem;
            border: 1px solid rgba($gray-50, 0.5);
            padding: 0.65rem 1rem;
            appearance: none;
            -webkit-appearance: none;

            &::-webkit-input-placeholder {
                color: $gray-inactive;
                opacity: 1;
            }
            
            &:-moz-placeholder {
                color: $gray-inactive;
                opacity: 1;
            }
            
            &::-moz-placeholder {
                color: $gray-inactive;
                opacity: 1;
            }
            
            &:-ms-input-placeholder {
                color: $gray-inactive;
                opacity: 1;
            }
        }
    }

    &-results__options {
        max-height: 20rem !important;

        @include stylish-scroll($width: 0.5rem, $height: false, $track-bg: transparent, $thumb-bg: rgba($black, 0.2));
    }

    &-results__option {
        padding: 1rem 1.4rem;
        border-bottom: 1px solid $gray-150;

        &:last-child {
            border-bottom: none;
        }

        &--highlighted {
            background-color: rgba($primary, 0.05) !important;
            color: $body-color !important;
        }

        &--selected {
            background-color: rgba($primary, 0.05) !important;
            color: $body-color !important;
            position: relative;
            padding-right: 4rem;

            &::after {
                width: 3.8rem;
                height: 3.8rem;
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                content: '';
                color: $primary !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3e%3cpath fill='%23539CC1' d='M16.9,2.4L6.1,13.2l-4-4c-0.2-0.2-0.5-0.2-0.7,0l-1.2,1.2c-0.2,0.2-0.2,0.5,0,0.7l5.5,5.5c0.2,0.2,0.5,0.2,0.7,0L18.7,4.3c0.2-0.2,0.2-0.5,0-0.7l-1.2-1.2C17.4,2.2,17.1,2.2,16.9,2.4z'/%3e%3c/svg%3e");
                background-size: 1.5rem 1.5rem;
                background-repeat: no-repeat;
                background-position: center center;

                .sm & {
                    width: 3.3rem;
                    height: 3.3rem;
                    background-size: 1.3rem 1.3rem;
                }
            }
        }
    }

    &-search {
        &--dropdown {
            padding: 0.8rem;
            border-bottom: 1px solid $gray-150;
        }
    }
}

.theme-b .is-invalid ~ .select2-container,
.is-invalid ~ .select2-container,
.is-invalid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover {
    .select2-selection--single {
        border-color: $danger !important;
        box-shadow: none !important;
    }

    .select2-selection--multiple {
        border-color: $danger !important;
        box-shadow: none !important;
    }
}

.is-valid ~ .select2-container,
.is-valid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover {
    .select2-selection--single {
        border-color: $secondary !important;
        box-shadow: none !important;
    }

    .select2-selection--multiple {
        border-color: $secondary !important;
        box-shadow: none !important;
    }
}

.ss-checkradio {
    display: flex;
    position: relative;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;

    &.reversed,
    &.text-on-left {
        justify-content: flex-end;

        .ss-checkradio-ui {
            order: 99;
        }

        .ss-checkradio-text {
            text-align: right;
            margin-left: 0;
            margin-right: 1.2rem;
            justify-content: flex-end;
        }
    }

    input:not(.form-control) {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:focus ~ .ss-checkradio-ui {
            box-shadow: 0 0 0 0.2rem rgba($black, 0.1);
        }

        &:checked ~ .ss-checkradio-ui {
            background: $gray-inactive;

            [class*="ss-icon-"] {
                display: block;
            }
        }

        &:checked ~ .depend-on-checked {
            visibility: visible;
        }

        &:disabled ~ .ss-checkradio-text,
        &.disabled ~ .ss-checkradio-text {
            opacity: 0.5;
        }
    }

    &.checked {
        cursor: default;

        .ss-checkradio-ui {
            background: $gray-inactive;
    
            [class*="ss-icon-"] {
                display: block;
            }
        }
    }

    &.default-outline input:checked ~ .ss-checkradio-ui,
    &.default-outline.checked .ss-checkradio-ui {
        background-color: $white;
        color: $gray-inactive;
    }

    &.primary input:checked ~ .ss-checkradio-ui,
    &.primary.checked .ss-checkradio-ui {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }

    &.primary-outline input:checked ~ .ss-checkradio-ui,
    &.primary-outline.checked .ss-checkradio-ui {
        background-color: $white;
        border-color: $primary;
        color: $primary;
    }

    &.secondary input:checked ~ .ss-checkradio-ui,
    &.secondary.checked .ss-checkradio-ui {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
    }

    &.secondary-outline input:checked ~ .ss-checkradio-ui,
    &.secondary-outline.checked .ss-checkradio-ui {
        background-color: $white;
        border-color: $secondary;
        color: $secondary;
    }

    &.dark input:checked ~ .ss-checkradio-ui,
    &.dark.checked .ss-checkradio-ui {
        background-color: $gray-dark;
        border-color: $gray-dark;
        color: $white;
    }

    &.dark-outline input:checked ~ .ss-checkradio-ui,
    &.dark-outline.checked .ss-checkradio-ui {
        background-color: $white;
        border-color: $gray-dark;
        color: $gray-dark;
    }

    &.danger input:checked ~ .ss-checkradio-ui,
    &.danger.checked .ss-checkradio-ui {
        background-color: $danger;
        border-color: $danger;
        color: $white;
    }

    &.danger-outline input:checked ~ .ss-checkradio-ui,
    &.danger-outline.checked .ss-checkradio-ui {
        background-color: $danger;
        border-color: $danger;
        color: $white;
    }

    &-ui {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid $gray-inactive;
        font-size: 1.2rem;
        border-radius: 0.3rem;
        background-color: $white;
        color: $white;
        transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;

        [class*="ss-icon-"] {
            display: none;
        }

        .ss-icon-circle-fill {
            font-size: 1.2rem;
        }

        .type-radio & {
            border-radius: 50%;
        }
    }

    &-text {
        flex: 1 1 auto;
        align-self: center;
        font-size: 1.6rem;
        margin-left: 1.2rem;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            font-size: 1.8rem;
        }

        a {
            color: $primary;
            display: inline-block;
            margin: 0 0.5rem;
        }
    }

    .is-invalid:not(:checked) ~ &-ui {
        background-color: $danger;
        border-color: $danger;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3cpath fill='%23ffffff' d='M9,7l4.4-4.4c0.3-0.3,0.3-0.7,0-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9,0L7,5L2.6,0.6c-0.3-0.3-0.7-0.3-0.9,0l-1,1c-0.3,0.3-0.3,0.7,0,0.9L5,7l-4.4,4.4c-0.3,0.3-0.3,0.7,0,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0L7,9l4.4,4.4c0.3,0.3,0.7,0.3,0.9,0l1-1c0.3-0.3,0.3-0.7,0-0.9L9,7z'/%3e%3c/svg%3e");
        background-size: 1rem auto;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .default-outline .is-invalid:not(:checked) ~ &-ui,
    .primary-outline .is-invalid:not(:checked) ~ &-ui,
    .dark-outline .is-invalid:not(:checked) ~ &-ui {
        background-color: $white;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3cpath fill='%23ee6262' d='M9,7l4.4-4.4c0.3-0.3,0.3-0.7,0-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9,0L7,5L2.6,0.6c-0.3-0.3-0.7-0.3-0.9,0l-1,1c-0.3,0.3-0.3,0.7,0,0.9L5,7l-4.4,4.4c-0.3,0.3-0.3,0.7,0,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0L7,9l4.4,4.4c0.3,0.3,0.7,0.3,0.9,0l1-1c0.3-0.3,0.3-0.7,0-0.9L9,7z'/%3e%3c/svg%3e");
    }
}

.datepicker {
    min-width: 27rem;
    font-size: 1.8rem;
    padding: 0;
    border-color: $gray-dark-300;
    border-radius: 0.6rem;

    .table-condensed {
        width: 100%;

        thead {
            th.prev {
                font-size: 1.3rem;
                color: $primary;
            }

            th.next {
                font-size: 1.3rem;
                color: $primary;
            }

            th.dow {
                height: 4rem;
                font-size: 1rem;
                color: $gray-400;
                font-weight: 400;
                text-transform: uppercase;
                border-top: 0.1rem solid $gray-dark-300;
                padding-top: 1rem;
            }
        }

        .datepicker-switch {
            font-weight: 400;
            color: $gray-300;
            height: 4.8rem;

            &:hover {
                background-color: transparent;
            }
        }

        tbody {
            td,
            th {
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 50%;
                color: $gray-300;
                position: relative;

                &.day::before {
                    width: 3.2rem;
                    height: 3.2rem;
                    display: block;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    pointer-events: none;
                    border-radius: 50%;
                }

                &:hover {
                    background-color: transparent !important;
                }

                &.day:hover {
                    &::before {
                        background-color: $gray-250;
                    }
                }

                &.active {
                    background-color: transparent !important;

                    &::before {
                        background-color: $primary;
                    }
                }
            }

            td.new,
            td.old {
                color: $gray-400;
            }
        }
    }

    &::before,
    &::after {
        display: none;
    }
}
