.ss {
    &-vv {
        &-box {
            padding: 4rem 0 3rem 0;
            background: rgba($gray-35, 0.35);
            border-radius: 2rem;

            @media (min-width: 992px) {
                padding: 5rem 0 3rem 0;
            }

            @media (min-width: 1200px) {
                padding: 7.4rem 0 5.1rem 0;
            }

            > .h4 {
                margin-bottom: 3rem;
                text-align: center;

                @media (min-width: 992px) {
                    margin-bottom: 4rem;
                }

                @media (min-width: 1200px) {
                    margin-bottom: 5.4rem;
                }
            }
        }

        &-slider {
            border-radius: 2rem;

            .ss-tt-expert-box {
                padding-top: 0;
            }

            &-wrap {
                padding: 0 4rem;
                position: relative;

                @media (min-width: 1200px) {
                    padding: 0 10rem;
                }

                @media (min-width: 1400px) {
                    padding: 0 13rem;
                }
            }

            &-arrow {
                width: 4rem;
                font-size: 2.6rem;
                height: 27.2rem;
                top: 0;
                position: absolute;
                z-index: 11;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $gray-dark;

                @media (min-width: 1200px) {
                    width: 7.4rem;
                    background-color: rgba($gray-inactive, 0.15);
                }

                @media (min-width: 1400px) {
                    width: 8.2rem;
                }

                &.next {
                    border-radius: 1.9rem 0 0 1.9rem;
                    right: 0rem;
                }

                &.prev {
                    border-radius: 0 1.9rem 1.9rem 0;
                    left: 0rem;
                }
            }
        }
    }
}
