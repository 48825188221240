.ss {
    &-mm {
        &-content {
            min-height: 20rem;
            position: relative;
        }

        &-article {
            max-width: 95.8rem;
            padding: 0 0 0 6.6rem;
            margin: 0 auto;

            @media (min-width: 1200px) {
                padding: 0 6.6rem;
            }

            > p {
                @media (min-width: 1200px) {
                    margin-bottom: 3rem;
                }
                
                > a {
                    font-weight: 700;
                }
            }

            > ul {
                @media (min-width: 992px) {
                    padding-left: 5rem;
                }

                @media (min-width: 1200px) {
                    padding-left: 10rem;
                }

                > li {
                    list-style: none;
                    padding: 0;
                    margin-bottom: 2.5rem;
                    position: relative;

                    &::before {
                        top: 0.8rem;
                        left: -3rem;
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                        background-color: $gray-dark-100;
                        content: '';
                        display: block;
                        position: absolute;
                    }
                }
            }

            &-head {
                padding: 2rem 0 4rem 0;
                display: flex;
                align-items: center;

                @media (min-width: 992px) {
                    padding: 3rem 0 6rem 0;
                }

                @media (min-width: 1200px) {
                    padding: 5.3rem 0 7.9rem 0;
                }

                > h4 {
                    margin-bottom: 0;
                    padding-right: 5.4rem;
                }

                &::after {
                    width: 100%;
                    height: 0.1rem;
                    content: '';
                    display: block;
                    flex: 1;
                    background-color: rgba($gray-800, 0.5);
                }
            }

            &-info {
                max-width: 96.8rem;
                margin: 0 auto;
                padding: 2rem 1rem 2rem 6.6rem;
                background-color: rgba($gray-35, 0.1);
                border-radius: 1rem;

                @media (min-width: 992px) {
                    padding: 4rem 1rem 4rem 6.6rem;
                }

                @media (min-width: 1200px) {
                    margin-top: 8.7rem;
                    padding: 7rem 5rem 9.6rem 7.7rem;
                }

                & > :last-child {
                    margin-bottom: 0;
                }

                > h3 {
                    margin-bottom: 0.6rem;
                    color: $primary;
                }

                > h4 {
                    padding-bottom: 4rem;
                    margin-bottom: 3.9rem;
                    position: relative;

                    &::before {
                        width: 100%;
                        max-width: 24.8rem;
                        height: 0.1rem;
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        content: '';
                        display: block;
                        background-color: $gray-800;
                    }
                }
            }
        }

        &-social {
            top: 0rem;
            left: 0rem;
            position: absolute;
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            > li {
                margin: 1.1rem 0;
                padding: 0;
                list-style: none;
                display: block;

                > a {
                    width: 5.1rem;
                    height: 5.1rem;
                    font-size: 2.6rem;
                    color: $gray-dark;
                    background-color: $gray;
                    border-radius: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                    &:hover {
                        color: $white;
                        background-color: $gray-dark;
                    }
                }
            }
        }
    }
}
