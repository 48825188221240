.ss {
    &-gg {
        &-rwo-b {
            .ss-cc-block {
                @media (min-width: 1200px) {
                    padding-left: 7rem;
                }
            }
        }

        &-top-row {
            margin-bottom: 4.3rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @media (min-width: 576px) {
                flex-direction: row;
            }
        }

        &-back-link {
            margin-bottom: 2rem;
            font-size: 1.6rem;
            line-height: 1.75;
            font-weight: 600;
            color: $gray-300;
            display: flex;
            align-items: center;
            text-decoration: none !important;

            & > i {
                margin-right: 1.4rem;
            }
        }

        &-social {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            @media (min-width: 576px) {
                margin-left: auto;
            }

            > li {
                margin: 0 1rem;
                padding: 0;
                list-style: none;
                display: flex;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                > a {
                    width: 5.1rem;
                    height: 5.1rem;
                    font-size: 2.6rem;
                    color: $gray-dark;
                    background-color: $gray;
                    border-radius: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;

                    &:hover {
                        color: $white;
                        background-color: $gray-dark;
                    }
                }
            }
        }
    }
}
