.lazy-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0; }
  .lazy-loader::before {
    opacity: 1;
    background-image: url(../images/loader-black.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: block;
    content: "";
    transition: opacity 1s; }
  .lazy-loader .lazyload {
    opacity: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: block;
    transition: opacity 1s;
    object-fit: cover; }
  .lazy-loader.loaded .lazyload {
    opacity: 1; }
  .lazy-loader.loaded::before {
    opacity: 0; }
  .lazy-loader.white::before {
    background-image: url(../images/loader-white.svg); }
  .lazy-loader span.lazyload ~ img.lazyload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .lazy-loader.contain .lazyload {
    background-size: contain;
    object-fit: contain; }
  .lazy-loader.no-loader::before {
    background-image: none !important; }
  .lazy-loader.b-error::before {
    opacity: 0; }
  .lazy-loader.static {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .lazy-loader.static .lazyload {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; }

.ss-header {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 11;
  display: block;
  background-color: #fff; }
  .ss-header-inner {
    position: relative;
    border-bottom: 0.2rem solid #eee; }
  @media (max-width: 1199px) {
    .ss-header {
      max-height: 100%;
      overflow: auto; } }
  @media (max-width: 1199px) {
    .ss-header .container {
      max-width: 100%; } }
  .ss-header .nav-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
    -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
    @media (min-width: 1200px) {
      .ss-header .nav-overlay {
        display: none; } }
  .ss-header-content {
    padding: 1rem 0; }
    @media (min-width: 1200px) {
      .ss-header-content {
        padding: 0; } }
    .ss-header-content .navbar {
      padding: 0;
      position: static; }
      .ss-header-content .navbar .nav-link {
        color: #222;
        padding: 1.5rem;
        font-size: 1.6rem;
        font-weight: 400;
        text-decoration: none;
        display: flex;
        align-items: center; }
        @media (min-width: 1200px) {
          .ss-header-content .navbar .nav-link {
            padding: 2.7rem 2rem 2.7rem 1.9rem; } }
        .ss-header-content .navbar .nav-link-arrow {
          margin-left: auto;
          width: 1rem;
          font-size: 1rem;
          display: block;
          transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
          @media (max-width: 1199px) {
            .ss-header-content .navbar .nav-link-arrow {
              width: 5rem;
              height: 5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              margin: -1.5rem -1.5rem -1.5rem auto;
              flex-shrink: 0; } }
          @media (min-width: 1200px) {
            .ss-header-content .navbar .nav-link-arrow {
              margin: 0 0 0 1rem; } }
          .ss-header-content .navbar .nav-link-arrow .ss-icon-chevron-down {
            pointer-events: none; }
        .ss-header-content .navbar .nav-link:hover {
          text-decoration: none;
          color: #539cc1; }
        .ss-header-content .navbar .nav-link.active {
          color: #539cc1;
          font-weight: 600; }
        .ss-header-content .navbar .nav-link::after {
          display: none; }
        .ss-header-content .navbar .nav-link.show {
          font-weight: 600;
          color: #539cc1; }
          .ss-header-content .navbar .nav-link.show .nav-link-arrow {
            transform: rotate(180deg); }
        .ss-header-content .navbar .nav-link.show .ss-header-content .navbar .nav-link {
          transform: rotate(90deg); }
      .ss-header-content .navbar .navbar-collapse {
        padding-top: 0; }
        @media (max-width: 1199px) {
          .ss-header-content .navbar .navbar-collapse {
            display: block !important;
            position: fixed;
            height: calc(100% - 6.2rem);
            width: 100%;
            max-width: 34rem;
            top: 6.4rem;
            right: 0;
            border-left: 0.2rem solid #eee;
            background-color: #fff;
            overflow: hidden;
            overflow-y: auto;
            scrollbar-color: #0072cd #434243;
            scrollbar-width: thin;
            opacity: 0;
            -webkit-transform: translateX(30%);
            -ms-transform: translateX(30%);
            transform: translateX(30%);
            visibility: hidden;
            -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
            -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
            transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
            .ss-header-content .navbar .navbar-collapse::-webkit-scrollbar {
              width: 8px; }
            .ss-header-content .navbar .navbar-collapse::-webkit-scrollbar-track {
              background-color: #f5f5f5; }
            .ss-header-content .navbar .navbar-collapse::-webkit-scrollbar-thumb {
              background-color: #b8b8b8; }
            .ss-header-content .navbar .navbar-collapse.show {
              opacity: 1;
              -webkit-transform: translateX(0);
              -ms-transform: translateX(0);
              transform: translateX(0);
              visibility: visible; } }
        .ss-header-content .navbar .navbar-collapse-content {
          padding: 1.5rem 0;
          width: 100%;
          display: flex;
          flex-direction: column; }
          @media (min-width: 1200px) {
            .ss-header-content .navbar .navbar-collapse-content {
              padding: 0;
              flex-direction: row;
              align-items: center; } }
      .ss-header-content .navbar .dropdown {
        position: static; }

.ss-dd-section {
  width: 100%;
  max-height: none;
  border: none;
  border-radius: 0;
  box-shadow: 0px 1.6rem 2.8rem -0.4rem rgba(0, 0, 0, 0.18);
  padding: 0;
  overflow: hidden;
  margin-top: 0rem !important;
  border-top: 0.2rem solid #eee; }
  .ss-dd-section::-webkit-scrollbar {
    width: 8px; }
  .ss-dd-section::-webkit-scrollbar-track {
    background-color: #f5f5f5; }
  .ss-dd-section::-webkit-scrollbar-thumb {
    background-color: #b8b8b8; }
  @media (max-width: 1199px) {
    .ss-dd-section {
      box-shadow: none;
      border-top: none; } }

.eda-logo {
  margin-right: 2rem;
  width: 13.77rem;
  display: block;
  max-height: 42px;
  overflow: hidden;
  position: relative; }
  .eda-logo img {
    width: 100%;
    height: auto;
    display: block; }

.nav-right {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1200px) {
    .nav-right {
      padding: 0;
      margin-left: auto;
      align-items: center;
      flex-direction: row; } }
  .nav-right .btn-primary {
    min-width: 14.6rem;
    padding: 0.7rem 1.2rem; }

.login-link {
  color: #222;
  padding: 1.3rem 0;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none !important; }
  @media (min-width: 1200px) {
    .login-link {
      padding: 1.3rem 2.2rem 1.2rem 2.2rem; } }

.nav-toggle-btn {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  @media (min-width: 1200px) {
    .nav-toggle-btn {
      display: none; } }

.nav-toggle-bars {
  position: relative;
  display: block;
  width: 1.6rem;
  height: 1.2rem; }
  .nav-toggle-bars .bar {
    transition: all 0.25s ease;
    height: 0.2rem;
    background-color: #000;
    position: absolute; }
    .nav-toggle-bars .bar.top {
      top: 0;
      width: 50%; }
      .nav-toggle-bars .bar.top.left {
        transform-origin: 0 0; }
        .nav-expanded .nav-toggle-bars .bar.top.left {
          transform: translate(2px, -1px) rotate(45deg) scaleX(1.25); }
      .nav-toggle-bars .bar.top.right {
        transform-origin: 100% 0; }
        .nav-expanded .nav-toggle-bars .bar.top.right {
          transform: translate(-2px, -1px) rotate(-45deg) scaleX(1.25); }
    .nav-toggle-bars .bar.middle {
      width: 100%;
      top: 0.5rem; }
      .nav-expanded .nav-toggle-bars .bar.middle {
        transform: scaleX(0); }
    .nav-toggle-bars .bar.bottom {
      width: 50%;
      bottom: 0; }
      .nav-toggle-bars .bar.bottom.left {
        width: 60%;
        transform-origin: 0 100%; }
        .nav-expanded .nav-toggle-bars .bar.bottom.left {
          width: 50%;
          transform: translate(2px, 2px) rotate(-45deg) scaleX(1.25); }
      .nav-toggle-bars .bar.bottom.right {
        width: 40%;
        transform-origin: 100% 100%;
        background-color: transparent; }
        .nav-expanded .nav-toggle-bars .bar.bottom.right {
          width: 50%;
          background-color: #000;
          transform: translate(-2px, 2px) rotate(45deg) scaleX(1.25); }
    .nav-toggle-bars .bar.left {
      left: 0; }
    .nav-toggle-bars .bar.right {
      right: 0; }

.dd-content-cell {
  padding: 0;
  position: relative; }
  @media (min-width: 1200px) {
    .dd-content-cell {
      padding: 3.4rem 0 2.4rem 0; } }
  @media (min-width: 1200px) {
    .dd-content-cell-inr.two-col {
      column-count: 2; } }
  .dd-content-cell-title {
    min-height: 2.6rem;
    margin-bottom: 2.3rem;
    padding-bottom: 0.6rem;
    border-bottom: 0.1rem solid rgba(196, 196, 196, 0.5);
    position: relative; }
    .dd-content-cell-title .h6 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 1.4rem; }
  .dd-content-cell.width-bg {
    padding: 1.5rem 0; }
    @media (min-width: 1200px) {
      .dd-content-cell.width-bg {
        padding: 3.4rem 2rem 2.4rem 2rem; } }
    .dd-content-cell.width-bg::before {
      width: 1000rem;
      pointer-events: none;
      z-index: 0;
      display: block;
      content: '';
      top: 0;
      bottom: 0;
      left: -1.5rem;
      position: absolute;
      background-color: #f5f5f5; }

.dd-items {
  margin-bottom: 2rem;
  padding: 0;
  display: inline-flex;
  color: #222;
  text-decoration: none !important;
  font-weight: 400; }
  @media (min-width: 1200px) {
    .dd-items {
      margin-bottom: 1rem;
      padding: 1rem 0 0 0; } }
  .dd-items-title-row {
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .dd-items-title-row .h5 {
      padding-right: 1rem;
      margin-bottom: 0;
      line-height: normal;
      color: #222; }
  .dd-items.active .dd-items-title-row .h5 {
    color: #539cc1;
    font-weight: 600; }
  .dd-items-icon {
    margin-right: 0.7rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    background-color: #f5f5f5;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center; }
    .dd-items-icon img {
      width: 100%;
      height: auto;
      display: block; }
    .dd-items-icon.color-icon {
      font-size: 2rem; }
  .dd-items-desc {
    flex: 1; }
    .dd-items-desc p {
      font-size: 1.4rem;
      color: rgba(64, 64, 65, 0.85); }
  .dd-items-badge {
    padding: 0.1rem 1rem 0.1rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 0.3rem;
    background-color: #f5f5f5;
    color: #404041; }

.dd-product-box {
  padding-top: 1rem;
  position: relative; }

.dd-product-imgbox {
  margin-bottom: 1.2rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 0.6rem;
  overflow: hidden;
  background-color: #fff;
  border: 0.1rem solid rgba(178, 178, 179, 0.78); }
  .dd-product-imgbox::before {
    padding-top: 50.63829787234043%;
    content: '';
    display: block;
    max-width: 100%;
    width: 100%; }

.dd-product-desc p {
  font-size: 1.4rem;
  margin-bottom: 0.9rem; }

.dd-product-link {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: #222; }

.dd-more-icon {
  margin-left: 0.6rem;
  padding-top: 0.1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.4rem;
  color: #539cc1;
  display: flex;
  align-items: center;
  justify-content: center; }

.dd-highlight-box {
  margin-bottom: 1.5rem;
  padding: 1.7rem 1.2rem 0.8rem 1.6rem;
  background-color: #fff;
  position: relative;
  border-radius: 1rem; }

.dd-highlight-row-a {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start; }

.dd-highlight-row-b {
  display: flex;
  align-items: center; }

.dd-highlight-imgbox {
  width: 6rem;
  position: relative;
  display: block;
  border-radius: 0.6rem;
  overflow: hidden;
  border: 0.1rem solid rgba(178, 178, 179, 0.78); }
  .dd-highlight-imgbox::before {
    padding-top: 100%;
    content: '';
    display: block;
    max-width: 100%;
    width: 100%; }

.dd-highlight-desc {
  padding-left: 1.5rem;
  flex: 1; }
  .dd-highlight-desc p {
    font-size: 1.4rem;
    margin-bottom: 0; }

.dd-news-lbl {
  font-size: 1.4rem;
  line-height: 1.286;
  font-weight: 600;
  color: #b2b2b3;
  text-transform: uppercase; }

.dd-news-right {
  margin-left: auto; }

html.nav-expanded body {
  overflow: hidden; }

html.nav-expanded .nav-overlay {
  opacity: 1;
  visibility: visible; }

:root {
  scroll-behavior: auto; }

html {
  font-size: 10px; }

body,
html {
  position: relative;
  background-color: #fff; }

body {
  color: #222;
  font-size: 1.6rem;
  line-height: 1.45;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  min-width: 320px; }
  @media (min-width: 768px) {
    body {
      font-size: 1.8rem; } }

::-webkit-input-placeholder {
  color: #333333;
  opacity: 1; }

:-moz-placeholder {
  color: #333333;
  opacity: 1; }

::-moz-placeholder {
  color: #333333;
  opacity: 1; }

:-ms-input-placeholder {
  color: #333333;
  opacity: 1; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: none;
  -moz-appearance: textfield;
  margin: 0; }

input:-webkit-autofill {
  transition: background-color 50000s ease-in-out, color 50000s ease-in-out; }

a {
  color: #539cc1;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-weight: 600;
  transition: color 0.15s; }

a:hover:not(.btn) {
  color: #539cc1;
  text-decoration: underline; }

a.nostyle:hover {
  text-decoration: none; }

.container {
  padding: 0 1.5rem; }
  @media (min-width: 1400px) {
    .container {
      max-width: 1200px; } }

.row {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.col,
[class*="col-"] {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

[class*="ss-icon-"] {
  font-size: 1em;
  width: 1em;
  display: block;
  position: relative; }
  [class*="ss-icon-"]::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
    width: 100%;
    z-index: 1; }
  [class*="ss-icon-"].ss-icon-logo::before, [class*="ss-icon-"].ss-icon-logo-colored::before {
    padding-top: 19.02%; }
  [class*="ss-icon-"] svg,
  [class*="ss-icon-"] img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }

.layout {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 6.4rem; }
  @media (min-width: 1200px) {
    .layout {
      padding-top: 8rem; } }
  .layout > :first-child:not([class*="ss-gc-"]) {
    margin-top: 0; }

.ss-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url(../images/loader-black.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70px 70px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.15s, opacity 0.15s; }
  .loading .ss-loader {
    visibility: visible;
    opacity: 1; }

.ss-section {
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .ss-section.fs::before {
    width: 0;
    height: calc(100vh - 8rem);
    display: block;
    content: ''; }
  .ss-section-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 0; }
  .ss-section-inner {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    z-index: 1; }

.ss-caption {
  color: #539cc1; }

.ss-spacer-wrapper {
  display: block;
  clear: both;
  width: 100%; }

.pageid_108 .layout {
  padding-top: 14rem; }
  @media (min-width: 768px) {
    .pageid_108 .layout {
      padding-top: 20rem; } }

.ss-footer-a {
  padding: 3rem 0 1.9rem 0;
  border-top: 0.1rem solid #ececec; }
  @media (min-width: 1200px) {
    .ss-footer-a {
      padding-top: 5.8rem; } }

.ss-footer-b {
  padding: 2.2rem 0 2.3rem 0;
  border-top: 0.1rem solid #ececec;
  display: flex;
  flex-direction: column-reverse;
  align-items: center; }
  @media (min-width: 576px) {
    .ss-footer-b {
      flex-direction: row;
      justify-content: space-between; } }

.ss-footer-logo {
  margin-bottom: 2rem;
  width: 13.8rem;
  position: relative;
  display: block; }
  @media (min-width: 1200px) {
    .ss-footer-logo {
      margin-bottom: 4rem; } }
  .ss-footer-logo::before {
    padding-top: 30.43478260869565%;
    width: 100%;
    content: '';
    display: block; }

.ss-footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex; }
  .ss-footer-social > li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex; }
    .ss-footer-social > li > a {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center; }
    .ss-footer-social > li:last-child::before {
      content: '';
      height: 20px;
      width: 2px;
      display: inline-block;
      background: #ececec;
      position: relative;
      top: 6px; }

.ss-footer-copyright {
  font-size: 1.4rem;
  color: #222;
  display: block; }

.ss-footer-info {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: block; }
  .ss-footer-info > li {
    margin: 0 0 0.4rem 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center; }
    .ss-footer-info > li > a {
      font-size: 1.6rem;
      line-height: 1.375;
      color: rgba(34, 34, 34, 0.6);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center; }
  .ss-footer-info-icon {
    margin-right: 1.2rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center; }

.ss-footer-cell {
  padding: 0 0 2.4rem 0; }
  .ss-footer-cell .h6 {
    margin-bottom: 1.8rem;
    text-transform: uppercase; }

.ss-footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block; }
  .ss-footer-nav > li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-footer-nav > li > a {
      padding: 0.4rem 0;
      font-size: 1.6rem;
      line-height: 1.375;
      color: rgba(34, 34, 34, 0.6);
      font-weight: 400;
      display: block; }

.ss-sd-cell {
  padding: 0 0 3.8rem 0; }
  .ss-sd-cell address {
    font-size: 1.6rem;
    line-height: 1.375;
    color: rgba(34, 34, 34, 0.6); }
  .ss-sd-cell .btn {
    min-width: 17rem; }

.btn {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 1.2rem 1rem 1.2rem;
  border-radius: 0.3rem;
  transition: all 0.15s linear;
  min-width: 11rem;
  box-shadow: none !important;
  border-width: 0.2rem; }
  .btn.btn-sm {
    font-size: 1.2rem;
    padding: 0.6rem 1rem;
    min-width: 9rem;
    border-radius: 0.6rem; }
    @media (min-width: 768px) {
      .btn.btn-sm {
        padding: 0.5rem 1.5rem; } }
  .btn.btn-sm.btn-icon {
    min-width: 3.2rem;
    min-height: 3.2rem;
    padding: 0.5rem; }
  .btn.btn-xsm {
    font-size: 1rem;
    padding: 0.6rem 0.8rem;
    min-width: 8rem;
    border-radius: 0.3rem; }
  .btn.btn-lg {
    font-size: 2.2rem;
    padding: 1.3rem 1.5rem 1.4rem 1.5rem; }
  .btn.btn-lg.btn-icon {
    min-width: 6.4rem;
    min-height: 6.4rem; }
  .btn.btn-xlg {
    font-size: 1.8rem;
    padding: 2.4rem; }
  .btn.btn-block {
    width: 100%; }
  .btn.with-icon {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center; }
    .btn.with-icon > span {
      display: block; }
  .btn.with-icon-right {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center; }
    .btn.with-icon-right > span {
      display: block; }
    .btn.with-icon-right > [class*="ss-icon-"] {
      margin: 0 0 0 0.714em; }
  .btn.with-icon-sep {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    position: relative; }
    .btn.with-icon-sep::after {
      width: 1px;
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: 2.75em;
      background-color: rgba(255, 255, 255, 0.47);
      content: '';
      display: block; }
    .btn.with-icon-sep > span {
      display: block; }
    .btn.with-icon-sep > [class*="ss-icon-"] {
      font-size: 0.75em;
      margin: 0 0 0 2.75em; }
  .btn [class*="ss-icon-"] {
    font-size: 1.25em;
    margin: 0 0.714em 0 0; }
  .btn-primary {
    background-color: #539cc1;
    border-color: #539cc1;
    color: #fff; }
    .btn-primary:not(:disabled):not(.disabled):hover, .btn-primary:not(:disabled):not(.disabled):focus {
      background-color: #6db8de;
      color: #fff;
      border-color: #6db8de; }
    .btn-primary:not(:disabled):not(.disabled):active {
      background-color: #387899;
      color: #fff;
      border-color: #387899; }
    .btn-primary:disabled, .btn-primary.disabled {
      background-color: #539cc1;
      border-color: #539cc1;
      color: #fff; }
  .btn-outline-primary {
    color: #222;
    background-color: transparent;
    border-color: rgba(51, 51, 51, 0.2); }
    .btn-outline-primary:not(:disabled):not(.disabled):hover, .btn-outline-primary:not(:disabled):not(.disabled):focus {
      background-color: #539cc1;
      color: #fff;
      border-color: #539cc1; }
    .btn-outline-primary:not(:disabled):not(.disabled):active {
      background-color: #387899;
      color: #fff;
      border-color: #387899; }
    .btn-outline-primary:disabled, .btn-outline-primary.disabled {
      color: #222;
      border-color: #539cc1;
      background-color: transparent; }
  .btn-secondary {
    background-color: #7bba52;
    border-color: #7bba52;
    color: #fff; }
    .btn-secondary:not(:disabled):not(.disabled):hover, .btn-secondary:not(:disabled):not(.disabled):focus {
      background-color: #98c978;
      color: #fff;
      border-color: #98c978; }
    .btn-secondary:not(:disabled):not(.disabled):active {
      background-color: #649d3f;
      color: #fff;
      border-color: #649d3f; }
    .btn-secondary:disabled, .btn-secondary.disabled {
      background-color: #7bba52;
      border-color: #7bba52;
      color: #fff; }
  .btn-outline-secondary {
    color: #222;
    border-color: #7bba52;
    background-color: transparent; }
    .btn-outline-secondary:not(:disabled):not(.disabled):hover, .btn-outline-secondary:not(:disabled):not(.disabled):focus {
      background-color: #7bba52;
      color: #fff;
      border-color: #7bba52; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active {
      background-color: #649d3f;
      color: #fff;
      border-color: #649d3f; }
    .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
      color: #222;
      border-color: #7bba52;
      background-color: transparent; }
  .btn-dark {
    background-color: #222;
    border-color: #222;
    color: #fff; }
    .btn-dark:not(:disabled):not(.disabled):hover, .btn-dark:not(:disabled):not(.disabled):focus {
      background-color: #4f4f4f;
      color: #fff;
      border-color: #4f4f4f; }
    .btn-dark:not(:disabled):not(.disabled):active {
      background-color: #383838;
      color: #fff;
      border-color: #383838; }
    .btn-dark:disabled, .btn-dark.disabled {
      background-color: #2a2a2a;
      border-color: #2a2a2a;
      color: #fff; }
  .btn-outline-dark {
    color: #222;
    border-color: #222;
    background-color: transparent; }
    .btn-outline-dark:not(:disabled):not(.disabled):hover, .btn-outline-dark:not(:disabled):not(.disabled):focus {
      background-color: #222;
      color: #fff;
      border-color: #222; }
    .btn-outline-dark:not(:disabled):not(.disabled):active {
      background-color: #383838;
      color: #fff;
      border-color: #383838; }
    .btn-outline-dark:disabled, .btn-outline-dark.disabled {
      color: #222;
      border-color: #222;
      background-color: transparent; }
  .btn-light {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: #222; }
    .btn-light:not(:disabled):not(.disabled):hover, .btn-light:not(:disabled):not(.disabled):focus {
      background-color: #f8f8f8;
      color: #222;
      border-color: #f8f8f8; }
    .btn-light:not(:disabled):not(.disabled):active {
      background-color: #e4e4e4;
      color: #222;
      border-color: #e4e4e4; }
    .btn-light:disabled, .btn-light.disabled {
      background-color: #f5f5f5;
      border-color: #f5f5f5;
      color: #222; }
  .btn-outline-light {
    color: #222;
    border-color: #f5f5f5;
    background-color: transparent; }
    .btn-outline-light:not(:disabled):not(.disabled):hover, .btn-outline-light:not(:disabled):not(.disabled):focus {
      background-color: #f5f5f5;
      color: #222;
      border-color: #f5f5f5; }
    .btn-outline-light:not(:disabled):not(.disabled):active {
      background-color: #e4e4e4;
      color: #222;
      border-color: #e4e4e4; }
    .btn-outline-light:disabled, .btn-outline-light.disabled {
      color: #222;
      border-color: #f5f5f5;
      background-color: transparent; }
  .btn-warning {
    background-color: #f8c124;
    border-color: #f8c124;
    color: #222; }
    .btn-warning:not(:disabled):not(.disabled):hover, .btn-warning:not(:disabled):not(.disabled):focus {
      background-color: #f9c83d;
      color: #222;
      border-color: #f9c83d; }
    .btn-warning:not(:disabled):not(.disabled):active {
      background-color: #edb749;
      color: #222;
      border-color: #edb749; }
    .btn-warning:disabled, .btn-warning.disabled {
      background-color: #f8c124;
      border-color: #f8c124;
      color: #222; }
  .btn-outline-warning {
    color: #222;
    border-color: #f8c124;
    background-color: transparent; }
    .btn-outline-warning:not(:disabled):not(.disabled):hover, .btn-outline-warning:not(:disabled):not(.disabled):focus {
      background-color: #f8c124;
      color: #222;
      border-color: #f8c124; }
    .btn-outline-warning:not(:disabled):not(.disabled):active {
      background-color: #edb749;
      color: #222;
      border-color: #edb749; }
    .btn-outline-warning:disabled, .btn-outline-warning.disabled {
      color: #222;
      border-color: #f8c124;
      background-color: transparent; }
  .btn-danger {
    background-color: #ee6262;
    border-color: #ee6262;
    color: #fff; }
    .btn-danger:not(:disabled):not(.disabled):hover, .btn-danger:not(:disabled):not(.disabled):focus {
      background-color: #f07979;
      color: #fff;
      border-color: #f07979; }
    .btn-danger:not(:disabled):not(.disabled):active {
      background-color: #e63935;
      color: #fff;
      border-color: #e63935; }
    .btn-danger:disabled, .btn-danger.disabled {
      background-color: #ee6262;
      border-color: #ee6262;
      color: #fff; }
  .btn-outline-danger {
    color: #222;
    border-color: #ee6262;
    background-color: transparent; }
    .btn-outline-danger:not(:disabled):not(.disabled):hover, .btn-outline-danger:not(:disabled):not(.disabled):focus {
      background-color: #ee6262;
      color: #fff;
      border-color: #ee6262; }
    .btn-outline-danger:not(:disabled):not(.disabled):active {
      background-color: #e63935;
      color: #fff;
      border-color: #e63935; }
    .btn-outline-danger:disabled, .btn-outline-danger.disabled {
      color: #222;
      border-color: #ee6262;
      background-color: transparent; }
  .btn-white {
    background-color: #fff;
    border-color: #fff;
    color: #222; }
    .btn-white:not(:disabled):not(.disabled):hover, .btn-white:not(:disabled):not(.disabled):focus {
      background-color: #e4e4e4;
      color: #222;
      border-color: #e4e4e4; }
    .btn-white:not(:disabled):not(.disabled):active {
      background-color: #d8d8d8;
      color: #222;
      border-color: #d8d8d8; }
    .btn-white:disabled, .btn-white.disabled {
      background-color: #fff;
      border-color: #fff;
      color: #222; }
  .btn-icon {
    min-width: 4.8rem;
    min-height: 4.8rem;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .btn-icon *:last-child {
      margin-right: 0; }
  .btn-link {
    color: #539cc1 !important;
    text-decoration: none;
    min-width: 0; }
    .btn-link:hover {
      text-decoration: underline; }
  .btn-link-black {
    padding: 0;
    color: #222 !important;
    font-weight: 400; }
    .btn-link-black i[class*=ss-icon-] {
      font-size: 1.3rem; }

.text-primary {
  color: #539cc1 !important; }

.text-secondary, .text-success {
  color: #7bba52 !important; }

.text-danger {
  color: #ee6262 !important; }

.text-yellow, .text-warning {
  color: #f8c124 !important; }

.text-gray, .text-light {
  color: #f5f5f5 !important; }

.text-dark {
  color: #222 !important; }

.text-black {
  color: #000 !important; }

.text-body {
  color: #222 !important; }

.text-pink {
  color: #df4469 !important; }

.text-dark-orange {
  color: #d9925b !important; }

.text-red, .text-orange {
  color: #e5574f !important; }

.text-purple {
  color: #8462ee !important; }

.text-green {
  color: #64ba79 !important; }

.text-light-green {
  color: #58a5a9 !important; }

.text-light-blue {
  color: #539eb9 !important; }

.text-yellow-dark-2 {
  color: #fea114 !important; }

.bg-primary, .bg-info {
  background-color: #539cc1 !important; }

.bg-success, .bg-secondary {
  background-color: #7bba52 !important; }

.bg-yellow, .bg-warning {
  background-color: #f8c124 !important; }

.bg-danger {
  background-color: #ee6262 !important; }

.bg-light, .bg-gray {
  background-color: #f5f5f5 !important; }

.bg-gray-2 {
  background-color: #eaeaea !important; }

.bg-dark {
  background-color: #222 !important; }

.bg-black {
  background-color: #000 !important; }

.bg-body {
  background-color: #222 !important; }

.bg-primary60, .bg-info60 {
  background-color: rgba(83, 156, 193, 0.06) !important; }

.bg-pink {
  background-color: #df4469 !important; }

.bg-pink60 {
  background-color: rgba(223, 68, 105, 0.06) !important; }

.bg-red, .bg-orange {
  background-color: #e5574f !important; }

.bg-orange60 {
  background-color: rgba(229, 87, 79, 0.06) !important; }

.bg-dark-orange {
  background-color: #d9925b !important; }

.bg-dark-orange-2 {
  background-color: #d9833f !important; }

.bg-dark-orange60 {
  background-color: rgba(217, 146, 91, 0.06) !important; }

.bg-purple {
  background-color: #8462ee !important; }

.bg-purple60 {
  background-color: rgba(132, 98, 238, 0.06) !important; }

.bg-green {
  background-color: #64ba79 !important; }

.bg-lime-green {
  background-color: #b8d7cf !important; }

.bg-green60 {
  background-color: rgba(100, 186, 121, 0.06) !important; }

.bg-light-green {
  background-color: #58a5a9 !important; }

.bg-light-blue {
  background-color: #539eb9 !important; }

.bg-yellow-dark-2 {
  background-color: #fea114 !important; }

.bg-yellow-dark60-2 {
  background-color: rgba(254, 161, 20, 0.06) !important; }

.bg-soft-blue {
  background-color: #b2d9f8 !important; }

.bg-gray-dark {
  background-color: #222 !important; }

.bg-primary-200 {
  background-color: #e1f5ff !important; }

.w-1 {
  width: 1%; }

.fw-600 {
  font-weight: 600 !important; }

.mt-25 {
  margin-top: 2.5rem !important; }

.mb-20 {
  margin-bottom: 2rem !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

h1,
.h1 {
  font-size: 4rem;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 4.8rem;
      margin-bottom: 3rem; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      font-size: 5.6rem; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      font-size: 6.4rem; } }

h2,
.h2 {
  font-size: 3.2rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3.6rem;
      margin-bottom: 3rem; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      font-size: 4rem; } }

h3,
.h3 {
  font-size: 2.6rem;
  line-height: 1.25;
  font-weight: 600;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3.2rem;
      margin-bottom: 3rem; } }

h4,
.h4 {
  font-size: 2rem;
  line-height: 1.33;
  font-weight: 600;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.4rem; } }

h5,
.h5,
h6,
.h6 {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 2rem; }

p {
  margin-bottom: 1.8rem; }
  @media (min-width: 768px) {
    p {
      margin-bottom: 2.6rem; } }

strong,
b {
  font-weight: 600; }

.p1 {
  font-size: 1.6rem;
  line-height: 1.45; }
  @media (min-width: 768px) {
    .p1 {
      font-size: 1.8rem; } }
  .p1 > :last-child {
    margin-bottom: 0; }

.p2 {
  font-size: 1.4rem;
  line-height: 1.5; }
  @media (min-width: 768px) {
    .p2 {
      font-size: 1.6rem; } }
  .p2 > :last-child {
    margin-bottom: 0; }

ol,
ul {
  padding-left: 3rem; }

dl,
ol,
ul {
  margin-bottom: 1.8rem; }
  @media (min-width: 768px) {
    dl,
    ol,
    ul {
      margin-bottom: 2.6rem; } }

.small {
  font-size: inherit; }

.ss-cc-block > :last-child {
  margin-bottom: 0; }

.ss-cc-desc {
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .ss-cc-desc {
      margin-bottom: 3rem; } }
  .ss-cc-desc > :last-child {
    margin-bottom: 0; }

.ss-cc-btns {
  display: flex;
  margin: 2rem 0 -2rem 0;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .ss-cc-btns {
      margin: 3rem 0 -2rem 0; } }
  .ss-cc-btns .btn {
    margin: 0 2rem 2rem 0; }
    .ss-cc-btns .btn:last-child {
      margin-right: 0; }

.ss-cc-head {
  text-align: center; }
  .ss-cc-head h2 {
    margin-bottom: 2rem; }
  .ss-cc-head .ss-cc-desc {
    max-width: 54rem;
    margin: 0 auto; }

.ss-section {
  margin: 5rem 0; }
  @media (min-width: 992px) {
    .ss-section {
      margin: 10rem 0; } }
  @media (min-width: 1200px) {
    .ss-section {
      margin: 15rem 0; } }
  .ss-section.tb-space-b {
    margin: 5rem 0; }
    @media (min-width: 1200px) {
      .ss-section.tb-space-b {
        margin: 6.9rem 0; } }

.ss-hr {
  margin: 0; }
  .ss-hr hr {
    margin: 0;
    border-color: #979797; }

.ss-post {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column; }
  .ss-post.theme-b .ss-entry-thumb::before {
    padding-top: 59.18918918918919%; }

.ss-entry-thumb {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-entry-thumb::before {
    width: 100%;
    padding-top: 74.07407407407407%;
    content: '';
    display: block; }
  .ss-entry-thumb:hover .ss-entry-overlay {
    opacity: 1;
    visibility: visible; }

.ss-entry-overlay {
  padding: 2.4rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
  opacity: 0;
  visibility: hidden; }
  .ss-entry-overlay .btn {
    min-width: 17rem; }
  .ss-entry-overlay.bottom-left {
    justify-content: flex-start;
    align-items: flex-end; }

.ss-entry-author {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.286;
  font-weight: 600;
  color: #539cc1;
  text-transform: uppercase;
  display: block; }

.ss-entry-header .h4 {
  margin-bottom: 1rem; }
  .ss-entry-header .h4 a {
    color: #222;
    text-decoration: none; }

.ss-entry-body {
  padding: 2rem 0; }

.ss-entry-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .ss-entry-date-text {
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 1.429;
    font-weight: 400;
    color: rgba(64, 64, 65, 0.6);
    text-decoration: none !important;
    display: block; }

.ss-entry-date-column {
  margin: 0 1rem;
  font-size: 1.4rem;
  line-height: 1.429;
  font-weight: 400;
  color: rgba(64, 64, 65, 0.6);
  display: block; }

ul.checklist {
  padding-left: 0; }
  ul.checklist.primary .checklist-icon {
    color: #539cc1; }
  ul.checklist.light-blue .checklist-icon {
    color: #539eb9; }
  ul.checklist.purple .checklist-icon {
    color: #8462ee; }
  ul.checklist.green .checklist-icon {
    color: #64ba79; }
  ul.checklist.dark-orange .checklist-icon {
    color: #d9925b; }
  ul.checklist.orange .checklist-icon {
    color: #e5574f; }
  ul.checklist.pink .checklist-icon {
    color: #df4469; }
  ul.checklist.yellow-dark-2 .checklist-icon {
    color: #fea114; }
  ul.checklist li {
    margin-bottom: 2.6rem;
    padding-left: 4.1rem;
    list-style: none;
    position: relative; }
    ul.checklist li:last-child {
      margin-bottom: 0; }
  ul.checklist .checklist-icon {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2rem;
    top: 0.3rem;
    left: 0;
    position: absolute; }

.badge-row {
  margin-bottom: 3rem; }

.badge-box {
  padding: 0.6rem 1.3rem 0.6rem 1.3rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0.3rem;
  color: #404041;
  text-transform: uppercase;
  display: inline-block;
  background-color: rgba(234, 234, 234, 0.35); }

.resources-title-row {
  margin-bottom: 2.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .resources-title-row {
      flex-direction: row; } }
  .resources-title-row h2 {
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .resources-title-row h2 {
        margin-bottom: 0;
        margin-right: 2rem; } }

@media (min-width: 768px) {
  .resources-see-all-link {
    margin-left: auto; } }

.ss-breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 400; }
  .ss-breadcrumb a {
    color: inherit;
    text-decoration: none !important;
    font-weight: 400; }
  .ss-breadcrumb .ss-icon-chevron-right {
    font-size: 0.75em;
    margin: 0 1.6rem; }

img.alignleft {
  max-width: 100%;
  height: auto;
  float: right;
  margin: 0 1.8rem 1.8rem 0; }
  @media (min-width: 768px) {
    img.alignleft {
      margin: 0 2.6rem 2.6rem 0; } }

img.alignright {
  max-width: 100%;
  height: auto;
  float: right;
  margin: 0 0 1.8rem 1.8rem; }
  @media (min-width: 768px) {
    img.alignright {
      margin: 0 0 2.6rem 2.6rem; } }

img.aligncenter {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 1.8rem auto; }
  @media (min-width: 768px) {
    img.aligncenter {
      margin: 0 auto 2.6rem auto; } }

.alert {
  border-radius: 0.6rem;
  font-weight: 600; }
  .alert [class*="ss-icon-"] {
    font-size: 1.143em;
    margin-right: 1.2rem; }
  .alert .btn-close {
    outline: none;
    box-shadow: none;
    color: #222;
    opacity: 1;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.7em auto no-repeat; }
    .alert .btn-close:focus {
      background-color: rgba(0, 0, 0, 0.05); }
  .alert-primary {
    background-color: rgba(83, 156, 193, 0.1);
    border-color: transparent;
    color: #539cc1; }
  .alert-success {
    background-color: rgba(123, 186, 82, 0.1);
    border-color: transparent;
    color: #7bba52; }
  .alert-warning {
    background-color: rgba(248, 193, 36, 0.1);
    border-color: transparent;
    color: #f8c124; }
  .alert-danger {
    background-color: rgba(238, 98, 98, 0.1);
    border-color: transparent;
    color: #ee6262; }
  .alert-light {
    background-color: #fff;
    border-color: transparent;
    color: #222;
    box-shadow: 0rem 0.2rem 1.4rem rgba(0, 0, 0, 0.12); }
  .alert-dark {
    background-color: rgba(34, 34, 34, 0.05);
    border-color: transparent;
    color: #222; }

textarea.form-control {
  height: 7rem; }

.form-group {
  margin-bottom: 1.4rem; }

.form-control {
  font-size: 1.8rem;
  font-weight: 400;
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #c4c4c4;
  box-shadow: none;
  padding: 0.85rem 1.4rem;
  height: 4.8rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  color: #222; }
  .theme-b .form-control {
    background: rgba(255, 255, 255, 0.2);
    border-color: #ccc;
    color: #fff; }
  .theme-b .form-control:not(:disabled):not(.disabled):not([readonly]):focus {
    color: #fff;
    border-color: #b8b8b8;
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8; }
  .form-control::-webkit-input-placeholder {
    color: #b2b2b3;
    opacity: 1; }
  .form-control:-moz-placeholder {
    color: #b2b2b3;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #b2b2b3;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #b2b2b3;
    opacity: 1; }
  .form-control:not(:disabled):not(.disabled):not([readonly]):hover {
    border-color: #b8b8b8;
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8; }
  .form-control:not(:disabled):not(.disabled):not([readonly]):focus {
    background-color: #fff;
    border-color: #539cc1;
    box-shadow: none; }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #ee6262 !important;
    box-shadow: none !important;
    background-image: none;
    padding-right: 1.4rem; }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #7bba52 !important;
    box-shadow: none !important;
    background-image: none;
    padding-right: 1.4rem; }
  .form-control:disabled, .form-control.disabled {
    background-color: #f5f5f5 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important; }
  .form-control[readonly] {
    background-color: #f5f5f5 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important; }
  .form-control[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    background: url(../images/search-close.svg) no-repeat center center;
    background-size: 1em 1em;
    position: relative;
    left: 0.75rem; }
  .form-control-wrapper {
    display: block;
    position: relative; }
    .form-control-wrapper.with-icon-left .form-control {
      padding-left: 4.2rem; }
    .form-control-wrapper.with-icon-left [class*="ss-icon-"] {
      position: absolute;
      top: 50%;
      left: 1.2rem;
      transform: translateY(-50%);
      pointer-events: none; }
    .form-control-wrapper.with-icon-right .form-control {
      padding-right: 4.2rem; }
    .form-control-wrapper.with-icon-right [class*="ss-icon-"] {
      position: absolute;
      top: 50%;
      right: 1.2rem;
      transform: translateY(-50%);
      pointer-events: none; }
    .form-control-wrapper.with-icon-right .ss-icon-check-outline {
      font-size: 1.3rem;
      color: #7bba52; }
    .form-control-wrapper.with-icon-right .ss-icon-close {
      font-size: 1rem;
      color: #ee6262; }
    .form-control-wrapper.with-sep-right::after {
      width: 1px;
      top: 1px;
      bottom: 1px;
      right: 3.7rem;
      display: block;
      content: '';
      background-color: #c4c4c4;
      position: absolute;
      pointer-events: none; }
    .form-control-wrapper.with-url-prefix .form-control {
      padding-left: 8rem; }
    .form-control-wrapper.with-url-prefix .url-prefix-text {
      font-size: 1.8rem;
      position: absolute;
      top: 52%;
      left: 1.4rem;
      height: 2.4rem;
      line-height: 2.4rem;
      display: block;
      border-right: 1px solid #c4c4c4;
      padding-right: 1rem;
      transform: translateY(-50%);
      pointer-events: none; }

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase; }

.select2-container {
  width: 100% !important;
  font-size: 1.8rem; }
  .select2-container--focus:not(.select2-container--disabled) .select2-selection--single {
    border-color: #539cc1 !important;
    box-shadow: none !important; }
  .select2-container--focus:not(.select2-container--disabled) .select2-selection--multiple {
    border-color: #539cc1 !important;
    box-shadow: none !important; }
  .theme-b .select2-container--focus:not(.select2-container--disabled) .select2-selection--single {
    border-color: #b8b8b8 !important;
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8; }
  .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--single {
    border-color: #b8b8b8 !important;
    background-color: rgba(0, 0, 0, 0.03) !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8 !important; }
  .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--multiple {
    border-color: #b8b8b8 !important;
    background-color: rgba(0, 0, 0, 0.03) !important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8 !important; }
  .select2-container--disabled .select2-selection--single {
    background-color: #f5f5f5 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important; }
  .select2-container--disabled .select2-selection--multiple {
    background-color: #f5f5f5 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important; }

.select2-selection {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out; }
  .select2-selection--multiple {
    color: #000;
    border: 1px solid #c4c4c4 !important;
    outline: none !important;
    border-radius: 0.6rem !important;
    padding-right: 1.4rem !important;
    padding-bottom: 0.7rem !important;
    min-height: 4.8rem !important; }
    .select2-selection--multiple .select2-selection__rendered {
      margin: 0 !important; }
    .select2-selection--multiple .select2-search--inline {
      margin-top: 0 !important;
      position: relative;
      top: 0; }
      .select2-selection--multiple .select2-search--inline .select2-search__field {
        height: 2.1rem; }
    .select2-selection--multiple .select2-search__field {
      margin-top: 1.1rem !important;
      margin-left: 1.4rem !important;
      color: #222 !important;
      appearance: none;
      -webkit-appearance: none; }
      .select2-selection--multiple .select2-search__field::-webkit-input-placeholder {
        color: #b2b2b3;
        opacity: 1; }
      .select2-selection--multiple .select2-search__field:-moz-placeholder {
        color: #b2b2b3;
        opacity: 1; }
      .select2-selection--multiple .select2-search__field::-moz-placeholder {
        color: #b2b2b3;
        opacity: 1; }
      .select2-selection--multiple .select2-search__field:-ms-input-placeholder {
        color: #b2b2b3;
        opacity: 1; }
  .select2-selection--single {
    height: 4.8rem !important;
    border: 1px solid #c4c4c4 !important;
    outline: none !important;
    border-radius: 0.6rem !important; }
    .theme-b .select2-selection--single {
      background: rgba(255, 255, 255, 0.2);
      border-color: #ccc; }
    .select2-selection--single .select2-selection__rendered {
      line-height: 4.6rem !important;
      padding-left: 1.4rem !important;
      padding-right: 4rem !important; }
      .theme-b .select2-selection--single .select2-selection__rendered {
        color: #fff; }
    .select2-selection--single .select2-selection__placeholder {
      color: #b2b2b3 !important; }
    .select2-selection--single .select2-selection__arrow {
      display: block !important;
      width: 4.6rem !important;
      height: 4.6rem !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3e%3cpath fill='%234a4a4a' d='M18.4,5.3l-0.8-0.8c-0.2-0.2-0.5-0.2-0.7,0l-7.4,7.4L2.1,4.5c-0.2-0.2-0.5-0.2-0.7,0L0.6,5.3C0.4,5.5,0.4,5.8,0.6,6l8.6,8.6c0.2,0.2,0.5,0.2,0.7,0L18.4,6C18.6,5.8,18.6,5.5,18.4,5.3z'/%3e%3c/svg%3e");
      background-size: 1.1rem 1.1rem;
      background-repeat: no-repeat;
      background-position: center center; }
      .theme-b .select2-selection--single .select2-selection__arrow {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23ffffff' d='M5,8l5-6H0L5,8z'/%3e%3c/svg%3e"); }
      .theme-c .select2-selection--single .select2-selection__arrow {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23333333' d='M5,8l5-6H0L5,8z'/%3e%3c/svg%3e"); }
      .select2-selection--single .select2-selection__arrow b {
        display: none !important; }
  .select2-selection__arrow {
    display: none !important; }
    .select2-container--open .select2-selection__arrow {
      transform: rotate(180deg); }
  .select2-selection__choice {
    padding-left: 0.8rem !important;
    padding-right: 2.4rem !important;
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
    border: none !important;
    font-size: 1.2rem;
    margin-top: 1rem !important;
    margin-left: 0.8rem !important;
    background-color: #f5f5f5 !important; }
    .select2-selection__choice:hover {
      background-color: #539cc1 !important;
      color: #fff !important; }
      .select2-selection__choice:hover .select2-selection__choice__remove {
        color: #fff !important; }
    .select2-selection__choice__remove {
      left: auto !important;
      right: 0;
      border: none !important;
      font-size: 1.8rem !important;
      font-weight: 600 !important;
      color: #222 !important;
      line-height: 1.35em !important;
      padding: 0 0.8rem 0 0.8rem !important;
      background-color: transparent !important; }

.select2-dropdown {
  border: none;
  border-radius: 0.6rem !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
  z-index: 9999; }
  .select2-dropdown--below {
    margin-top: 0.3rem; }
  .select2-dropdown--above {
    margin-top: -0.3rem; }
  .select2-dropdown .select2-search__field {
    outline: none !important;
    border-radius: 0.6rem;
    border: 1px solid rgba(196, 196, 196, 0.5);
    padding: 0.65rem 1rem;
    appearance: none;
    -webkit-appearance: none; }
    .select2-dropdown .select2-search__field::-webkit-input-placeholder {
      color: #b2b2b3;
      opacity: 1; }
    .select2-dropdown .select2-search__field:-moz-placeholder {
      color: #b2b2b3;
      opacity: 1; }
    .select2-dropdown .select2-search__field::-moz-placeholder {
      color: #b2b2b3;
      opacity: 1; }
    .select2-dropdown .select2-search__field:-ms-input-placeholder {
      color: #b2b2b3;
      opacity: 1; }

.select2-results__options {
  max-height: 20rem !important;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; }
  .select2-results__options::-webkit-scrollbar {
    width: 0.5rem; }
  .select2-results__options::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 2rem; }
  .select2-results__options::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2rem; }

.select2-results__option {
  padding: 1rem 1.4rem;
  border-bottom: 1px solid #e5e8ed; }
  .select2-results__option:last-child {
    border-bottom: none; }
  .select2-results__option--highlighted {
    background-color: rgba(83, 156, 193, 0.05) !important;
    color: #222 !important; }
  .select2-results__option--selected {
    background-color: rgba(83, 156, 193, 0.05) !important;
    color: #222 !important;
    position: relative;
    padding-right: 4rem; }
    .select2-results__option--selected::after {
      width: 3.8rem;
      height: 3.8rem;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      content: '';
      color: #539cc1 !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'%3e%3cpath fill='%23539CC1' d='M16.9,2.4L6.1,13.2l-4-4c-0.2-0.2-0.5-0.2-0.7,0l-1.2,1.2c-0.2,0.2-0.2,0.5,0,0.7l5.5,5.5c0.2,0.2,0.5,0.2,0.7,0L18.7,4.3c0.2-0.2,0.2-0.5,0-0.7l-1.2-1.2C17.4,2.2,17.1,2.2,16.9,2.4z'/%3e%3c/svg%3e");
      background-size: 1.5rem 1.5rem;
      background-repeat: no-repeat;
      background-position: center center; }
      .sm .select2-results__option--selected::after {
        width: 3.3rem;
        height: 3.3rem;
        background-size: 1.3rem 1.3rem; }

.select2-search--dropdown {
  padding: 0.8rem;
  border-bottom: 1px solid #e5e8ed; }

.theme-b .is-invalid ~ .select2-container .select2-selection--single,
.is-invalid ~ .select2-container .select2-selection--single,
.is-invalid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--single {
  border-color: #ee6262 !important;
  box-shadow: none !important; }

.theme-b .is-invalid ~ .select2-container .select2-selection--multiple,
.is-invalid ~ .select2-container .select2-selection--multiple,
.is-invalid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--multiple {
  border-color: #ee6262 !important;
  box-shadow: none !important; }

.is-valid ~ .select2-container .select2-selection--single,
.is-valid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--single {
  border-color: #7bba52 !important;
  box-shadow: none !important; }

.is-valid ~ .select2-container .select2-selection--multiple,
.is-valid ~ .select2-container:not(.select2-container--focus):not(.select2-container--disabled):hover .select2-selection--multiple {
  border-color: #7bba52 !important;
  box-shadow: none !important; }

.ss-checkradio {
  display: flex;
  position: relative;
  align-items: flex-start;
  user-select: none;
  cursor: pointer; }
  .ss-checkradio.reversed, .ss-checkradio.text-on-left {
    justify-content: flex-end; }
    .ss-checkradio.reversed .ss-checkradio-ui, .ss-checkradio.text-on-left .ss-checkradio-ui {
      order: 99; }
    .ss-checkradio.reversed .ss-checkradio-text, .ss-checkradio.text-on-left .ss-checkradio-text {
      text-align: right;
      margin-left: 0;
      margin-right: 1.2rem;
      justify-content: flex-end; }
  .ss-checkradio input:not(.form-control) {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .ss-checkradio input:not(.form-control):focus ~ .ss-checkradio-ui {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1); }
    .ss-checkradio input:not(.form-control):checked ~ .ss-checkradio-ui {
      background: #b2b2b3; }
      .ss-checkradio input:not(.form-control):checked ~ .ss-checkradio-ui [class*="ss-icon-"] {
        display: block; }
    .ss-checkradio input:not(.form-control):checked ~ .depend-on-checked {
      visibility: visible; }
    .ss-checkradio input:not(.form-control):disabled ~ .ss-checkradio-text,
    .ss-checkradio input:not(.form-control).disabled ~ .ss-checkradio-text {
      opacity: 0.5; }
  .ss-checkradio.checked {
    cursor: default; }
    .ss-checkradio.checked .ss-checkradio-ui {
      background: #b2b2b3; }
      .ss-checkradio.checked .ss-checkradio-ui [class*="ss-icon-"] {
        display: block; }
  .ss-checkradio.default-outline input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.default-outline.checked .ss-checkradio-ui {
    background-color: #fff;
    color: #b2b2b3; }
  .ss-checkradio.primary input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.primary.checked .ss-checkradio-ui {
    background-color: #539cc1;
    border-color: #539cc1;
    color: #fff; }
  .ss-checkradio.primary-outline input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.primary-outline.checked .ss-checkradio-ui {
    background-color: #fff;
    border-color: #539cc1;
    color: #539cc1; }
  .ss-checkradio.secondary input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.secondary.checked .ss-checkradio-ui {
    background-color: #7bba52;
    border-color: #7bba52;
    color: #fff; }
  .ss-checkradio.secondary-outline input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.secondary-outline.checked .ss-checkradio-ui {
    background-color: #fff;
    border-color: #7bba52;
    color: #7bba52; }
  .ss-checkradio.dark input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.dark.checked .ss-checkradio-ui {
    background-color: #222;
    border-color: #222;
    color: #fff; }
  .ss-checkradio.dark-outline input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.dark-outline.checked .ss-checkradio-ui {
    background-color: #fff;
    border-color: #222;
    color: #222; }
  .ss-checkradio.danger input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.danger.checked .ss-checkradio-ui {
    background-color: #ee6262;
    border-color: #ee6262;
    color: #fff; }
  .ss-checkradio.danger-outline input:checked ~ .ss-checkradio-ui,
  .ss-checkradio.danger-outline.checked .ss-checkradio-ui {
    background-color: #ee6262;
    border-color: #ee6262;
    color: #fff; }
  .ss-checkradio-ui {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid #b2b2b3;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    background-color: #fff;
    color: #fff;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out; }
    .ss-checkradio-ui [class*="ss-icon-"] {
      display: none; }
    .ss-checkradio-ui .ss-icon-circle-fill {
      font-size: 1.2rem; }
    .type-radio .ss-checkradio-ui {
      border-radius: 50%; }
  .ss-checkradio-text {
    flex: 1 1 auto;
    align-self: center;
    font-size: 1.6rem;
    margin-left: 1.2rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .ss-checkradio-text {
        font-size: 1.8rem; } }
    .ss-checkradio-text a {
      color: #539cc1;
      display: inline-block;
      margin: 0 0.5rem; }
  .is-invalid:not(:checked) ~ .ss-checkradio-ui {
    background-color: #ee6262;
    border-color: #ee6262;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3cpath fill='%23ffffff' d='M9,7l4.4-4.4c0.3-0.3,0.3-0.7,0-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9,0L7,5L2.6,0.6c-0.3-0.3-0.7-0.3-0.9,0l-1,1c-0.3,0.3-0.3,0.7,0,0.9L5,7l-4.4,4.4c-0.3,0.3-0.3,0.7,0,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0L7,9l4.4,4.4c0.3,0.3,0.7,0.3,0.9,0l1-1c0.3-0.3,0.3-0.7,0-0.9L9,7z'/%3e%3c/svg%3e");
    background-size: 1rem auto;
    background-position: center center;
    background-repeat: no-repeat; }
  .default-outline .is-invalid:not(:checked) ~ .ss-checkradio-ui,
  .primary-outline .is-invalid:not(:checked) ~ .ss-checkradio-ui,
  .dark-outline .is-invalid:not(:checked) ~ .ss-checkradio-ui {
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3cpath fill='%23ee6262' d='M9,7l4.4-4.4c0.3-0.3,0.3-0.7,0-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9,0L7,5L2.6,0.6c-0.3-0.3-0.7-0.3-0.9,0l-1,1c-0.3,0.3-0.3,0.7,0,0.9L5,7l-4.4,4.4c-0.3,0.3-0.3,0.7,0,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0L7,9l4.4,4.4c0.3,0.3,0.7,0.3,0.9,0l1-1c0.3-0.3,0.3-0.7,0-0.9L9,7z'/%3e%3c/svg%3e"); }

.datepicker {
  min-width: 27rem;
  font-size: 1.8rem;
  padding: 0;
  border-color: #ccc;
  border-radius: 0.6rem; }
  .datepicker .table-condensed {
    width: 100%; }
    .datepicker .table-condensed thead th.prev {
      font-size: 1.3rem;
      color: #539cc1; }
    .datepicker .table-condensed thead th.next {
      font-size: 1.3rem;
      color: #539cc1; }
    .datepicker .table-condensed thead th.dow {
      height: 4rem;
      font-size: 1rem;
      color: rgba(51, 51, 51, 0.5);
      font-weight: 400;
      text-transform: uppercase;
      border-top: 0.1rem solid #ccc;
      padding-top: 1rem; }
    .datepicker .table-condensed .datepicker-switch {
      font-weight: 400;
      color: #222;
      height: 4.8rem; }
      .datepicker .table-condensed .datepicker-switch:hover {
        background-color: transparent; }
    .datepicker .table-condensed tbody td,
    .datepicker .table-condensed tbody th {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      color: #222;
      position: relative; }
      .datepicker .table-condensed tbody td.day::before,
      .datepicker .table-condensed tbody th.day::before {
        width: 3.2rem;
        height: 3.2rem;
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        pointer-events: none;
        border-radius: 50%; }
      .datepicker .table-condensed tbody td:hover,
      .datepicker .table-condensed tbody th:hover {
        background-color: transparent !important; }
      .datepicker .table-condensed tbody td.day:hover::before,
      .datepicker .table-condensed tbody th.day:hover::before {
        background-color: #e1e1e1; }
      .datepicker .table-condensed tbody td.active,
      .datepicker .table-condensed tbody th.active {
        background-color: transparent !important; }
        .datepicker .table-condensed tbody td.active::before,
        .datepicker .table-condensed tbody th.active::before {
          background-color: #539cc1; }
    .datepicker .table-condensed tbody td.new,
    .datepicker .table-condensed tbody td.old {
      color: rgba(51, 51, 51, 0.5); }
  .datepicker::before, .datepicker::after {
    display: none; }

@media (max-width: 767px) {
  .wpcf7-form .row > .col {
    width: 100%;
    flex: 0 0 auto; } }

.wpcf7-form .wpcf7-spinner {
  background-color: #539cc1;
  margin: 5px 24px 0 0; }

.wpcf7-form .wpcf7-response-output {
  color: #fff; }

.wpcf7-form .wpcf7-checkbox {
  display: flex;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 1rem;
  margin-bottom: 2rem;
  align-items: center;
  min-height: 7.4rem; }
  .wpcf7-form .wpcf7-checkbox input:not(.form-control) {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .wpcf7-form .wpcf7-checkbox input + span.wpcf7-list-item-label {
    padding-left: 46px;
    display: inline-block;
    position: relative; }
    .wpcf7-form .wpcf7-checkbox input + span.wpcf7-list-item-label::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: 1px solid #b2b2b3;
      font-size: 0;
      border-radius: 0.3rem;
      background-color: #fff;
      color: #fff;
      transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
      cursor: pointer; }
    .wpcf7-form .wpcf7-checkbox input + span.wpcf7-list-item-label::after {
      display: none; }
  .wpcf7-form .wpcf7-checkbox input:checked ~ span.wpcf7-list-item-label {
    position: relative; }
    .wpcf7-form .wpcf7-checkbox input:checked ~ span.wpcf7-list-item-label::before {
      content: '';
      display: block;
      background-color: #7bba52;
      border-color: #7bba52;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='ss-icon-check-outline' viewBox='0 0 19 19'%3E%3Cpath fill='%23ffffff' d='M16.9,2.4L6.1,13.2l-4-4c-0.2-0.2-0.5-0.2-0.7,0l-1.2,1.2c-0.2,0.2-0.2,0.5,0,0.7l5.5,5.5 c0.2,0.2,0.5,0.2,0.7,0L18.7,4.3c0.2-0.2,0.2-0.5,0-0.7l-1.2-1.2C17.4,2.2,17.1,2.2,16.9,2.4z'%3E%3C/path%3E%3C/svg%3E");
      background-position: 60% center;
      background-size: 60%;
      background-repeat: no-repeat; }

.wpcf7-form .tab-pane .tab-content {
  border: 1px solid #ddd;
  border-radius: 2rem;
  padding: 4rem;
  padding-bottom: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.wpcf7-form .ss-kkk-odcp .ss-kkk-box {
  border: 1px solid #ddd;
  border-radius: 2rem;
  padding: 4rem; }

.wpcf7-form .ss-kkk-box {
  border: 0;
  padding: 0; }

.wpcf7-form .ss-wrapper-infos {
  border: 1px solid #ddd;
  border-radius: 2rem;
  padding: 4rem;
  padding-top: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.ss-l .wpcf7-form .wpcf7-response-output,
.ss-kk .wpcf7-form .wpcf7-response-output,
.ss-kkk .wpcf7-form .wpcf7-response-output,
.ss-n .wpcf7-form .wpcf7-response-output {
  color: #222; }

.ss-kkk-sol-clps-t .wpcf7-checkbox {
  border: 0;
  margin: 0; }

.ss-dropdown {
  display: inline-block;
  vertical-align: middle; }
  .ss-dropdown-toggle {
    font-size: 1.4rem;
    font-weight: 400;
    color: #222 !important;
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 0.6rem;
    border: 1px solid #c4c4c4;
    padding: 0.9rem 4rem 0.9rem 1.4rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    outline: none;
    text-decoration: none !important;
    text-align: left; }
    .d-block .ss-dropdown-toggle {
      width: 100%; }
    .ss-dropdown-toggle:hover {
      border-color: #b8b8b8;
      background-color: rgba(0, 0, 0, 0.03);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03), inset 0 0 0 1px #b8b8b8; }
    .ss-dropdown-toggle:focus {
      border-color: #539cc1;
      background-color: #fff;
      box-shadow: none; }
    .ss-dropdown-toggle.show {
      border-color: #539cc1;
      background-color: #fff;
      box-shadow: none; }
      .ss-dropdown-toggle.show .ss-dropdown-arrow {
        transform: rotate(180deg); }
    .ss-dropdown-toggle-txt {
      display: block;
      flex: 1 1 auto; }
  .ss-dropdown-arrow {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    position: absolute;
    top: 0;
    right: 0.1rem; }
  .ss-dropdown .dropdown-menu {
    min-width: 100%;
    padding: 0.7rem;
    border: none;
    box-shadow: 0.1rem 0.9rem 2.1rem rgba(0, 0, 0, 0.12);
    border-radius: 0.6rem;
    font-size: 1.4rem; }
    .ss-dropdown .dropdown-menu.with-overflow-scroll {
      max-height: 30rem;
      overflow: hidden;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.2) #fff; }
      .ss-dropdown .dropdown-menu.with-overflow-scroll::-webkit-scrollbar {
        width: 0.5rem; }
      .ss-dropdown .dropdown-menu.with-overflow-scroll::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 2rem; }
      .ss-dropdown .dropdown-menu.with-overflow-scroll::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 2rem; }
  .ss-dropdown .dropdown-item {
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
    border-radius: 0.6rem;
    background-color: transparent;
    margin: 0.1rem 0;
    color: #222 !important;
    text-decoration: none !important;
    min-width: 15rem; }
    .ss-dropdown .dropdown-item.disabled {
      cursor: default;
      opacity: 0.4; }
    .ss-dropdown .dropdown-item:not(.disabled):hover, .ss-dropdown .dropdown-item:not(.disabled):focus {
      background-color: #f5f5f5; }
    .ss-dropdown .dropdown-item:not(.disabled):active, .ss-dropdown .dropdown-item:not(.disabled).active {
      background-color: #f5f5f5; }
    .ss-dropdown .dropdown-item.ss-dpitem-icon {
      display: flex;
      align-items: center; }
      .ss-dropdown .dropdown-item.ss-dpitem-icon:active > [class*="ss-icon-"]:not(.ss-icon-chevron-right), .ss-dropdown .dropdown-item.ss-dpitem-icon.active > [class*="ss-icon-"]:not(.ss-icon-chevron-right) {
        color: #539cc1; }
      .ss-dropdown .dropdown-item.ss-dpitem-icon > [class*="ss-icon-"] {
        font-size: 1.7rem;
        flex-shrink: 0; }
        .ss-dropdown .dropdown-item.ss-dpitem-icon > [class*="ss-icon-"]:first-child {
          margin-right: 1.2rem; }
        .ss-dropdown .dropdown-item.ss-dpitem-icon > [class*="ss-icon-"]:last-child {
          font-size: 1rem;
          margin-left: 1.2rem; }
      .ss-dropdown .dropdown-item.ss-dpitem-icon .dropdown-item-text {
        flex: 1 1 auto; }
  .ss-dropdown .dropdown-item-text {
    padding: 0;
    color: inherit; }

.ss-popover {
  border: none;
  border-radius: 1rem;
  font-size: 1.4rem;
  background-color: #fff;
  box-shadow: 0 0.8rem 3rem rgba(0, 0, 0, 0.18);
  max-width: 44rem; }
  .ss-popover .popover-body {
    padding: 0; }
  .ss-popover .popover-header {
    padding: 1.4rem 1.4rem 0 1.4rem;
    border: none;
    border-radius: 0;
    font-size: 1.6rem;
    background-color: transparent; }
  .ss-popover .popover-arrow {
    width: 3.2rem;
    height: 1.6rem; }
    .ss-popover .popover-arrow::before {
      display: none; }
  .ss-popover.bs-popover-auto[data-popper-placement^=top] .popover-arrow,
  .ss-popover.bs-popover-top .popover-arrow {
    bottom: calc(-1.6rem - 1px); }
  .ss-popover.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow,
  .ss-popover.bs-popover-bottom .popover-arrow {
    top: calc(-1.6rem - 1px); }
  .ss-popover.bs-popover-auto[data-popper-placement^=right] .popover-arrow,
  .ss-popover.bs-popover-end .popover-arrow {
    left: calc(-1.6rem - 1px);
    height: 3.2rem;
    width: 1.6rem; }
  .ss-popover.bs-popover-auto[data-popper-placement^=left] .popover-arrow,
  .ss-popover.bs-popover-start .popover-arrow {
    right: calc(-1.5rem - 1px);
    height: 3.2rem;
    width: 1.6rem; }
  .ss-popover.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after,
  .ss-popover.bs-popover-top .popover-arrow::after {
    border-width: 1.6rem 1.6rem 0;
    border-top-color: #fff; }
  .ss-popover.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after,
  .ss-popover.bs-popover-bottom .popover-arrow::after {
    border-width: 0 1.6rem 1.6rem 1.6rem;
    border-top-color: #fff; }
  .ss-popover.bs-popover-auto[data-popper-placement^=right] .popover-arrow::after,
  .ss-popover.bs-popover-end .popover-arrow::after {
    border-width: 1.6rem 1.6rem 1.6rem 0; }
  .ss-popover.bs-popover-auto[data-popper-placement^=left] .popover-arrow::after,
  .ss-popover.bs-popover-start .popover-arrow::after {
    border-width: 1.6rem 0 1.6rem 1.6rem; }
  .ss-popover-body {
    padding: 1.4rem; }
    .ss-popover-body p {
      margin: 0 0 2.2rem 0; }
    .ss-popover-body .img-fluid {
      border-radius: 0.6rem;
      border: 1px solid #e1e1e1;
      margin-top: 2.2rem; }
    .ss-popover-body :last-child {
      margin-bottom: 0; }
  .ss-popover-footer {
    padding: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -1rem 0; }
    .ss-popover-footer .btn {
      margin: 1rem; }

.ss-tooltip {
  font-size: 1.4rem; }
  .ss-tooltip.show {
    opacity: 1; }
  .ss-tooltip.lg .tooltip-inner {
    font-size: 1.4rem;
    max-width: 20rem; }
  .ss-tooltip .tooltip-inner {
    background-color: #fff;
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.18);
    color: #222;
    border-radius: 0.6rem;
    padding: 0.6rem 1rem 0.6rem;
    font-size: 1.2rem;
    max-width: 15rem; }
  .ss-tooltip.bs-tooltip-auto[data-popper-placement^=left], .ss-tooltip.bs-tooltip-start {
    padding: 0 0.8rem; }
    .ss-tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .ss-tooltip.bs-tooltip-start .tooltip-arrow {
      width: 0.8rem;
      height: 1.6rem; }
      .ss-tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ss-tooltip.bs-tooltip-start .tooltip-arrow::before {
        border-width: 0.8rem 0 0.8rem 0.8rem;
        border-left-color: #fff; }
  .ss-tooltip.bs-tooltip-auto[data-popper-placement^=right], .ss-tooltip.bs-tooltip-end {
    padding: 0 0.8rem; }
    .ss-tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .ss-tooltip.bs-tooltip-end .tooltip-arrow {
      width: 0.8rem;
      height: 1.6rem; }
      .ss-tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ss-tooltip.bs-tooltip-end .tooltip-arrow::before {
        border-width: 0.8rem 0.8rem 0.8rem 0;
        border-right-color: #fff; }
  .ss-tooltip.bs-tooltip-auto[data-popper-placement^=top], .ss-tooltip.bs-tooltip-top {
    padding: 0.8rem 0; }
    .ss-tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .ss-tooltip.bs-tooltip-top .tooltip-arrow {
      width: 1.6rem;
      height: 0.8rem; }
      .ss-tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ss-tooltip.bs-tooltip-top .tooltip-arrow::before {
        border-width: 0.8rem 0.8rem 0;
        border-top-color: #fff; }
  .ss-tooltip.bs-tooltip-auto[data-popper-placement^=bottom], .ss-tooltip.bs-tooltip-bottom {
    padding: 0.8rem 0; }
    .ss-tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .ss-tooltip.bs-tooltip-bottom .tooltip-arrow {
      width: 1.6rem;
      height: 0.8rem; }
      .ss-tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ss-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
        border-width: 0 0.8rem 0.8rem;
        border-bottom-color: #fff; }

.progress {
  height: 0.8rem;
  border-radius: 3rem; }
  .progress-row {
    margin-bottom: 3rem; }
    .progress-row:last-child {
      margin-bottom: 0; }
  .progress-lbls {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600; }

.modal {
  min-width: 320px; }
  .modal.modal-static .modal-dialog {
    transform: none; }
  .modal-dialog {
    max-width: 60rem; }
    .small .modal-dialog {
      max-width: 72rem; }
    .medium .modal-dialog {
      max-width: 96.5rem; }
    .large .modal-dialog {
      max-width: 116rem; }
  .modal-content {
    border-radius: 1rem;
    background-color: #fff;
    border: none;
    box-shadow: 0rem 0.2rem 0.9rem rgba(0, 0, 0, 0.18); }
  .modal-header {
    padding: 2rem 5rem 2rem 2rem;
    position: relative; }
  .modal-body {
    padding: 2rem; }
  .modal-footer {
    padding: 2rem; }
    .modal-footer > .btn {
      margin: 0 0.5rem; }
      .modal-footer > .btn:last-child {
        margin-right: 0; }
      .modal-footer > .btn:first-child {
        margin-left: 0; }
  .modal-backdrop.show {
    opacity: 0.65; }

.ss-modal-title {
  width: 1%;
  margin: 0;
  flex: 1 1 auto; }

.ss-modal-close {
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #222 !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; }
  .ss-modal-close.outside {
    color: #fff !important;
    width: 4rem;
    height: 4rem;
    bottom: 100%;
    top: auto;
    right: 0; }
  .ss-modal-close:focus {
    background-color: rgba(0, 0, 0, 0.1); }

.ss-a {
  padding-bottom: 0.5rem;
  position: relative; }
  @media (min-width: 992px) {
    .ss-a.reverse .container > .row {
      flex-direction: row-reverse; } }
  .ss-a.reverse .ss-a-imgwrap {
    margin-right: 0; }
    @media (min-width: 992px) {
      .ss-a.reverse .ss-a-imgwrap {
        margin-left: -10.5rem; } }
    @media (min-width: 1400px) {
      .ss-a.reverse .ss-a-imgwrap {
        margin-left: -13.5rem; } }
  .ss-a-bottom-b {
    content: '';
    display: block;
    height: 0.5rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: matrix(1, 0, 0, -1, 0, 0); }
    .ss-a-bottom-b.blue-green {
      background: linear-gradient(102.96deg, #539cc1 28.13%, #6ecc52 112.33%); }
    .ss-a-bottom-b.purple {
      background: linear-gradient(102.96deg, #8462ee 28.13%, rgba(132, 98, 238, 0.156632) 112.33%); }
    .ss-a-bottom-b.green {
      background: linear-gradient(102.96deg, #64ba79 28.13%, rgba(100, 186, 121, 0.0698754) 112.33%); }
    .ss-a-bottom-b.dark-orange {
      background: linear-gradient(247.07deg, #d9925b 35.59%, rgba(217, 146, 91, 0.100169) 109.83%);
      transform: matrix(-1, 0, 0, 1, 0, 0); }
    .ss-a-bottom-b.red {
      background: linear-gradient(102.96deg, #e5574f 28.13%, rgba(229, 87, 79, 0.0752021) 110.69%); }
    .ss-a-bottom-b.light-green {
      background: linear-gradient(102.96deg, #58a5a9 28.13%, rgba(91, 161, 173, 0.0829873) 110.69%); }
    .ss-a-bottom-b.light-blue {
      background: linear-gradient(56.97deg, #539eb9 33.7%, #daf3ff 100%); }
    .ss-a-bottom-b.pink {
      background: linear-gradient(56.97deg, #df4469 33.7%, rgba(215, 70, 116, 0.286986) 100%); }
    .ss-a-bottom-b.yellow-dark-2 {
      background: linear-gradient(56.97deg, #fea114 33.7%, rgba(254, 162, 12, 0.286986) 100%); }
  .ss-a-gredient {
    opacity: 0.16;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
    .ss-a-gredient.purple {
      background: linear-gradient(180.56deg, rgba(132, 98, 238, 0.0001) 25.95%, rgba(132, 98, 238, 0.15) 71.9%, #8462ee 121.61%); }
    .ss-a-gredient.green {
      background: linear-gradient(180.56deg, rgba(100, 186, 121, 0.0001) 25.95%, rgba(100, 186, 121, 0.15) 71.9%, #64ba79 121.61%); }
    .ss-a-gredient.dark-orange {
      background: linear-gradient(180.56deg, rgba(217, 146, 91, 0.0001) 25.95%, rgba(220, 156, 107, 0.152262) 71.9%, #d9925b 121.61%); }
    .ss-a-gredient.red {
      background: linear-gradient(180.56deg, rgba(229, 87, 79, 0.0001) 25.95%, rgba(229, 87, 79, 0.15) 71.12%, #e5574f 121.61%); }
    .ss-a-gredient.light-green {
      background: linear-gradient(180.56deg, rgba(88, 165, 169, 0.0001) 25.95%, rgba(88, 165, 169, 0.15) 70.69%, #58a5a9 120.87%); }
    .ss-a-gredient.light-blue {
      background: linear-gradient(180.56deg, rgba(83, 158, 185, 0.0001) 25.95%, rgba(83, 158, 185, 0.15) 71.9%, #539eb9 121.61%); }
    .ss-a-gredient.pink {
      background: linear-gradient(180.56deg, rgba(223, 68, 105, 0.0001) 25.95%, rgba(223, 68, 105, 0.15) 71.6%, #df4469 121%); }
    .ss-a-gredient.yellow-dark-2 {
      background: linear-gradient(180.56deg, rgba(254, 161, 20, 0.0001) 25.95%, rgba(254, 161, 20, 0.15) 71.9%, #fea114 121.61%); }
  .ss-a-left {
    padding: 5rem 0; }
    .ss-a-left .badge-row {
      margin-bottom: 1.8rem; }
    @media (min-width: 768px) {
      .ss-a-left .h1 {
        margin-bottom: 2.4rem; } }
    .ss-a-left .h1 strong {
      font-weight: 700; }
    .ss-a-left p {
      max-width: 50.3rem; }
      @media (min-width: 1200px) {
        .ss-a-left p {
          font-size: 2rem; } }
    @media (min-width: 992px) {
      .ss-a-left .btn-spacer {
        padding-top: 1.2rem; } }
    .ss-a-left .btn-spacer .btn {
      margin-right: 1rem; }
      @media (min-width: 768px) {
        .ss-a-left .btn-spacer .btn {
          min-width: 13.9rem;
          margin-right: 2.1rem; } }
      .ss-a-left .btn-spacer .btn:last-child {
        margin-right: 0; }
  .ss-a-right {
    width: 100%;
    padding: 0; }
  .ss-a-imgwrap {
    display: block; }
    @media (min-width: 992px) {
      .ss-a-imgwrap {
        margin-right: -10.5rem; } }
    @media (min-width: 1400px) {
      .ss-a-imgwrap {
        margin-right: -13.5rem; } }
  .ss-a-imgbox {
    width: 100%;
    position: relative;
    display: block; }
    .ss-a-imgbox::before {
      width: 100%;
      padding-top: 74.28571428571429%;
      content: '';
      display: block; }
  @media (min-width: 1200px) {
    .ss-a.product-intro .ss-a-imgwrap {
      margin-right: -13.5rem;
      display: block; } }
  @media (min-width: 1400px) {
    .ss-a.product-intro .ss-a-imgwrap {
      margin-right: -21.5rem; } }
  .ss-a.product-intro .ss-a-imgbox::before {
    padding-top: 62.45468669083376%; }

.ss-b {
  border-bottom: 0.2rem solid #979797; }
  .ss-b .ss-section-inner {
    padding: 0; }
  .ss-b-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    @media (min-width: 992px) {
      .ss-b-row {
        flex-direction: row;
        align-items: center; } }
  .ss-b-left {
    padding: 1rem 0;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 992px) {
      .ss-b-left {
        padding: 0;
        width: 18.8rem;
        max-width: 18.8rem;
        flex: 0 0 18.8rem; } }
  .ss-b-right {
    padding: 0;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    @media (min-width: 992px) {
      .ss-b-right {
        justify-content: flex-end; } }
    @media (max-width: 767px) {
      .ss-b-right > a {
        flex: 0 0 44%;
        width: 44%;
        max-width: 54%; } }
  .ss-b-imgbox {
    margin: 0.5rem 1rem;
    width: auto;
    height: 4.7rem;
    position: relative;
    display: block; }
    @media (min-width: 992px) {
      .ss-b-imgbox {
        margin: 1.3rem 1.6rem 1.1rem 1.6rem; } }
    @media (min-width: 1200px) {
      .ss-b-imgbox {
        margin-left: 2.6rem;
        margin-right: 2.6rem; } }
    @media (min-width: 1400px) {
      .ss-b-imgbox {
        margin-left: 3.6rem;
        margin-right: 3.6rem; } }
    .ss-b-imgbox::before {
      display: none; }
    .ss-b-imgbox .lazy-loader {
      position: relative; }
      .ss-b-imgbox .lazy-loader .lazyload {
        position: static;
        height: 4.7rem;
        width: auto; }

@media (min-width: 1200px) {
  .ss-c-head {
    margin-bottom: 6.6rem; } }

.ss-c-head .ss-cc-block {
  text-align: center; }
  .ss-c-head .ss-cc-block h2 {
    margin-bottom: 2rem; }
  .ss-c-head .ss-cc-block .ss-cc-desc {
    max-width: 54rem;
    margin: 0 auto; }

.ss-c-feature {
  padding: 5rem 0;
  margin: 0 -1.6rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .ss-c-feature.reverse {
      flex-direction: row-reverse; } }
  @media (min-width: 992px) {
    .ss-c-feature {
      align-items: flex-end;
      flex-direction: row; } }
  @media (min-width: 1200px) {
    .ss-c-feature {
      margin: 0 -3.4rem;
      padding: 5.4rem 0 10.2rem 0; } }
  .ss-c-feature-cell {
    padding: 0 1.6rem;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 992px) {
      .ss-c-feature-cell {
        padding: 0 3.4rem;
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%; } }
    @media (min-width: 992px) {
      .ss-c-feature-cell.left {
        width: 56%;
        max-width: 56%;
        flex: 0 0 56%; } }
    @media (min-width: 1200px) {
      .ss-c-feature-cell.left {
        width: 64.62035541195477%;
        max-width: 64.62035541195477%;
        flex: 0 0 64.62035541195477%; } }
    .ss-c-feature-cell.right {
      padding-top: 3rem; }
      @media (min-width: 992px) {
        .ss-c-feature-cell.right {
          padding-top: 0;
          width: 44%;
          max-width: 44%;
          flex: 0 0 44%; } }
      @media (min-width: 1200px) {
        .ss-c-feature-cell.right {
          padding-top: 0;
          width: 35.37964458804523%;
          max-width: 35.37964458804523%;
          flex: 0 0 35.37964458804523%; } }
    .ss-c-feature-cell h2 strong {
      font-weight: 700; }
    .ss-c-feature-cell .btn.btn-sm {
      font-size: 1.6rem; }
    .ss-c-feature-cell .ss-cc-btns {
      margin: 0 -1.5rem; }
      .ss-c-feature-cell .ss-cc-btns .btn {
        margin-bottom: 0.5rem; }
      .ss-c-feature-cell .ss-cc-btns .btn-link {
        margin: 0 1.5rem; }
  .ss-c-feature-imgbox {
    width: 100%;
    max-width: 100%;
    position: relative;
    display: block; }
    .ss-c-feature-imgbox::before {
      width: 100%;
      padding-top: 60.65573770491803%;
      content: '';
      display: block; }
  .ss-c-feature-badge {
    margin-bottom: 3rem;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 0.3rem;
    text-transform: uppercase;
    display: inline-block; }

.ss-c-feature:last-child {
  padding-bottom: 0; }

.ss-d-content {
  padding: 2rem;
  background-color: #222;
  border-radius: 2rem; }
  @media (min-width: 992px) {
    .ss-d-content {
      padding: 6.6rem 2rem 7.2rem 2rem; } }
  .ss-d-content .ss-cc-block {
    max-width: 57rem;
    margin: 0 auto;
    color: #fff;
    text-align: center; }
    @media (min-width: 992px) {
      .ss-d-content .ss-cc-block {
        margin-bottom: 3rem; } }
    .ss-d-content .ss-cc-block h2 {
      margin-bottom: 2rem; }

.ss-d-form {
  max-width: 77rem;
  margin: 0 auto;
  padding-top: 2.7rem; }
  @media (max-width: 767px) {
    .ss-d-form > .row > .col {
      width: 100%;
      flex: 0 0 auto; } }
  .ss-d-form .btn {
    border-radius: 0.6rem; }
    @media (min-width: 992px) {
      .ss-d-form .btn {
        min-width: 17rem; } }

@media (min-width: 1200px) {
  .ss-e-head {
    margin-bottom: 6.6rem; } }

.ss-e-head .ss-cc-block {
  text-align: center; }
  .ss-e-head .ss-cc-block h2 {
    margin-bottom: 2rem; }
  .ss-e-head .ss-cc-block .ss-cc-desc {
    max-width: 54rem;
    margin: 0 auto; }

.ss-e-benefits-box {
  margin: 1.5rem 0;
  padding: 3rem 2.2rem 2.6rem 3rem;
  background: rgba(234, 234, 234, 0.15);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }

.ss-e-benefits-header {
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center; }
  .ss-e-benefits-header .h4 {
    margin: 0; }

.ss-e-benefits-desc {
  margin-bottom: 1.8rem; }

.ss-e-benefits-footer {
  margin-top: auto;
  display: flex;
  padding-right: 0.8rem; }
  .ss-e-benefits-footer > .btn {
    margin-left: auto; }

.ss-e-benefits-icon {
  margin-right: 1.3rem;
  width: 4.6rem;
  max-width: 4.6rem;
  flex: 0 0 4.6rem;
  height: 4.6rem;
  font-size: 2.7rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; }
  @media (min-width: 1200px) {
    .ss-e-benefits-icon {
      margin-right: 2.3rem; } }
  .ss-e-benefits-icon.light-blue {
    background-color: #539eb9; }
  .ss-e-benefits-icon.light-green {
    background-color: #58a5a9; }
  .ss-e-benefits-icon.green {
    background-color: #64ba79; }

.ss-f-imgbox {
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-f-imgbox::before {
    width: 100%;
    padding-top: 60.08547008547009%;
    content: '';
    display: block; }

.ss-g-head {
  margin-bottom: 3.3rem; }
  .ss-g-head .ss-cc-block {
    text-align: center; }
    .ss-g-head .ss-cc-block h2 {
      margin-bottom: 2rem; }
    .ss-g-head .ss-cc-block .ss-cc-desc {
      max-width: 54rem;
      margin: 0 auto; }

.ss-g-expert {
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  @media (min-width: 768px) {
    .ss-g-expert {
      padding: 8rem 5rem; } }
  @media (min-width: 992px) {
    .ss-g-expert {
      padding: 8rem 10rem; } }
  .ss-g-expert::before {
    width: 100%;
    height: 100%;
    content: '';
    display: block; }
  .ss-g-expert-box {
    padding: 3rem 1.6rem 2rem 1.6rem;
    width: 100%;
    max-width: 25rem;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0px 0.8rem 3rem rgba(0, 0, 0, 0.0802557);
    position: relative; }
    @media (min-width: 768px) {
      .ss-g-expert-box {
        max-width: 34rem; } }
    @media (min-width: 992px) {
      .ss-g-expert-box {
        max-width: 37rem;
        min-height: 38.4rem;
        padding: 3.9rem 3.2rem 3.2rem 3.2rem; } }
    .ss-g-expert-box > p {
      margin: 0;
      font-size: 1.6rem;
      line-height: 1.333;
      font-weight: 600;
      color: #222; }
      @media (min-width: 768px) {
        .ss-g-expert-box > p {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .ss-g-expert-box > p {
          font-size: 2.4rem; } }
    .ss-g-expert-box-footer {
      padding-top: 3.3rem; }
  .ss-g-expert-qoute {
    width: 2.8rem;
    height: 2.8rem;
    font-size: 2.4rem;
    color: #539cc1;
    top: 1rem;
    right: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .ss-g-expert-qoute {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 2.8rem; } }
    @media (min-width: 992px) {
      .ss-g-expert-qoute {
        width: 4.3rem;
        height: 4.3rem;
        font-size: 3.6rem;
        top: 1.5rem;
        right: 2.5rem; } }
  .ss-g-expert-name {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    color: #222;
    text-transform: uppercase;
    display: block; }
  .ss-g-expert-post {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: rgba(34, 34, 34, 0.5);
    display: block; }

.ss-g-slider {
  padding-bottom: 4.6rem; }
  .ss-g-slider-wrap {
    padding-top: 5.9rem; }
  .ss-g-slider .swiper-slide {
    height: auto; }
  .ss-g-slider .swiper-pagination-bullets {
    bottom: -0.8rem; }
  .ss-g-slider .swiper-pagination-bullet {
    margin: 0 !important;
    padding: 0.8rem;
    width: auto;
    height: auto;
    background-color: transparent;
    opacity: 1; }
    .ss-g-slider .swiper-pagination-bullet::before {
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      content: '';
      background-color: #efefef;
      border-radius: 50%; }
    .ss-g-slider .swiper-pagination-bullet-active::before {
      background-color: #7bba52; }
  .ss-g-slider-box {
    padding: 1.6rem 1.1rem 1.6rem 1.6rem;
    background-color: #f8f8f8;
    border-radius: 2rem;
    height: 100%; }
    @media (min-width: 992px) {
      .ss-g-slider-box {
        min-height: 26.5rem;
        padding: 2.2rem 1.1rem 2.9rem 2.2rem; } }
    .ss-g-slider-box-head {
      margin-bottom: 1.4rem;
      display: flex;
      align-items: center; }
      .ss-g-slider-box-head-r {
        flex: 1; }
  .ss-g-slider-user-expert {
    margin-right: 1.8rem;
    width: 5.1rem;
    height: 5.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: background-color 0.25s, color 0.25s;
    overflow: hidden;
    position: relative; }
    .ss-g-slider-user-expert::before {
      width: 100%;
      padding-top: 100%;
      content: '';
      display: block; }
  .ss-g-slider-expert-name {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    color: #222;
    text-transform: uppercase;
    display: block; }
  .ss-g-slider-expert-category {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: rgba(34, 34, 34, 0.5);
    display: block; }

.ss-h-head {
  margin-bottom: 5.4rem; }
  .ss-h-head .ss-cc-block {
    text-align: center; }
    .ss-h-head .ss-cc-block h2 {
      margin-bottom: 2rem; }
    .ss-h-head .ss-cc-block .ss-cc-desc {
      max-width: 54rem;
      margin: 0 auto; }

.ss-h-imgbox {
  margin-bottom: 3rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border: 0.1rem solid #eaeaea;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-h-imgbox::before {
    width: 100%;
    padding-top: 54.91228070175439%;
    content: '';
    display: block; }

.ss-h-desc {
  padding-bottom: 2.6rem; }
  .ss-h-desc h3 a {
    color: #222 !important;
    text-decoration: none !important; }

.ss-h-date {
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.556;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.5);
  display: block; }

.ss-h-btn-space {
  display: flex;
  justify-content: center; }
  @media (min-width: 992px) {
    .ss-h-btn-space {
      padding-top: 4rem; } }

.ss-i {
  background-color: #222; }
  .ss-i .ss-section-bg::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: block;
    content: ''; }
  .ss-i.light {
    background-color: #fcfcfc; }
    .ss-i.light .ss-i-features-row {
      color: #222; }
    .ss-i.light .ss-i-features-m p {
      font-weight: 600; }
  .ss-i .ss-section-inner {
    padding: 6.5rem 0; }
  .ss-i-features-row {
    display: flex;
    color: #fff;
    align-items: center;
    flex-wrap: wrap; }
  .ss-i-features-l {
    font-size: 4.2rem;
    font-weight: 700;
    flex-shrink: 0;
    line-height: 0.9;
    align-self: flex-start; }
    @media (min-width: 768px) {
      .ss-i-features-l {
        font-size: 5.2rem; } }
    @media (min-width: 992px) {
      .ss-i-features-l {
        font-size: 6.4rem; } }
    .ss-i-features-l strong {
      display: block; }
  .ss-i-features-m {
    width: 1%;
    max-width: 75.7rem;
    flex: 1 1 auto;
    padding: 0 2.4rem; }
    @media (min-width: 1200px) {
      .ss-i-features-m p {
        font-size: 2.4rem; } }
  .ss-i-features-r {
    width: 100%;
    flex-shrink: 0;
    margin: 3rem 0 0 7rem; }
    @media (min-width: 576px) {
      .ss-i-features-r {
        width: auto;
        margin: 0 0 0 auto; } }
    @media (min-width: 992px) {
      .ss-i-features-r .btn {
        min-width: 17rem; } }

.ss-j-head {
  margin-bottom: 3.9rem; }
  .ss-j-head .ss-cc-block {
    text-align: center; }
    .ss-j-head .ss-cc-block h2 {
      margin-bottom: 2rem; }
    .ss-j-head .ss-cc-block .ss-cc-desc {
      max-width: 54rem;
      margin: 0 auto; }

.ss-j-answer-accordion {
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap; }

.ss-j-answer-item {
  width: 100%;
  padding: 1.5rem; }
  @media (min-width: 992px) {
    .ss-j-answer-item {
      width: 50%; } }

.ss-j-answer-box {
  border: 1px solid #ddd;
  border-radius: 1rem; }

.ss-j-accordion-body {
  padding: 1.6rem;
  border-top: 1px solid #ddd; }

.ss-j-accordion-button {
  width: 100%;
  min-height: 5rem;
  padding: 1rem 4rem 1rem 1.6rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.33;
  color: #222;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  outline: none;
  border: none;
  background: none;
  text-align: left; }
  @media (min-width: 992px) {
    .ss-j-accordion-button {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .ss-j-accordion-button {
      min-height: 8rem;
      font-size: 2.4rem;
      padding: 1rem 4.2rem 1rem 3.2rem; } }
  .ss-j-accordion-button:focus {
    box-shadow: none; }
  .ss-j-accordion-button::after {
    display: none; }
  .ss-j-accordion-button .ss-icon-minus {
    display: block; }
  .ss-j-accordion-button .ss-icon-plus {
    display: none; }
  .ss-j-accordion-button.collapsed .ss-icon-minus {
    display: none; }
  .ss-j-accordion-button.collapsed .ss-icon-plus {
    display: block; }
  .ss-j-accordion-button.sm {
    font-size: 1.6rem;
    min-height: 6.4rem; }

.ss-j-accordion-icon {
  width: 4rem;
  height: 4rem;
  font-size: 1.4rem;
  color: #539cc1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%); }
  @media (min-width: 1200px) {
    .ss-j-accordion-icon {
      width: 6.4rem;
      height: 6.4rem; } }

.ss-k {
  background-color: rgba(216, 216, 216, 0.11); }
  .ss-k .ss-section-inner {
    padding: 5rem 0; }
    @media (min-width: 992px) {
      .ss-k .ss-section-inner {
        padding: 7rem 0; } }
    @media (min-width: 1200px) {
      .ss-k .ss-section-inner {
        padding: 12.5rem 0 12.8rem 0; } }
  .ss-k-head {
    text-align: center;
    margin-bottom: 4.4rem; }
    .ss-k-head h2 {
      margin-bottom: 2rem; }
    .ss-k-head .ss-cc-desc {
      max-width: 54rem;
      margin: 0 auto; }
  .ss-k-btn-spacer {
    padding-top: 3.9rem;
    display: flex;
    justify-content: center; }
    .ss-k-btn-spacer .btn {
      min-width: 15.2rem; }

@media (min-width: 1200px) {
  .ss-l .ss-cc-desc {
    max-width: 50rem; } }

@media (min-width: 1200px) {
  .ss-l .ss-cc-block h2 {
    max-width: 50rem; } }

.ss-l .badge-row {
  margin-bottom: 2rem; }

.ss-l-imgbox {
  margin: 0 auto 5rem auto;
  width: 100%;
  max-width: 57rem;
  position: relative;
  display: block; }
  @media (min-width: 1200px) {
    .ss-l-imgbox {
      margin: 0 0 0 -4.4rem; } }
  .ss-l-imgbox::before {
    width: 100%;
    padding-top: 100%;
    content: '';
    display: block; }
  .ss-l-imgbox img {
    border-radius: 2rem; }

.ss-l .flex-row-reverse .ss-l-imgbox {
  margin-left: 0; }

.ss-l-partner-form {
  padding-top: 4.5rem; }
  .ss-l-partner-form .btn {
    min-width: 17rem; }

.ss-m-head {
  margin-bottom: 5rem; }
  @media (min-width: 1200px) {
    .ss-m-head {
      margin-bottom: 9.4rem; } }
  .ss-m-head .ss-cc-block {
    text-align: center; }
    .ss-m-head .ss-cc-block h2 {
      margin-bottom: 2rem; }
    .ss-m-head .ss-cc-block .ss-cc-desc {
      max-width: 54rem;
      margin: 0 auto; }

.ss-m-lmbox {
  margin: 1.5rem 0;
  padding: 2rem 1.6rem 0 1.6rem;
  border-radius: 1rem;
  background-color: rgba(234, 234, 234, 0.35);
  display: flex;
  flex-direction: column;
  position: relative; }
  @media (min-width: 576px) {
    .ss-m-lmbox {
      min-height: 25.6rem;
      padding: 2.2rem 19rem 2.8rem 3rem; } }
  @media (min-width: 992px) {
    .ss-m-lmbox {
      margin: 2.5rem 0; } }
  .ss-m-lmbox-head {
    margin-bottom: 1.8rem;
    display: flex;
    align-items: center; }
    .ss-m-lmbox-head .h4 {
      margin: 0; }
  .ss-m-lmbox-desc {
    margin-bottom: 1rem; }
    @media (min-width: 576px) {
      .ss-m-lmbox-desc {
        max-width: 28rem; } }
  .ss-m-lmbox-footer {
    margin-top: auto; }
    .ss-m-lmbox-footer .btn {
      font-weight: 600; }
  .ss-m-lmbox-icon {
    margin-right: 1.3rem;
    width: 4.6rem;
    max-width: 4.6rem;
    flex: 0 0 4.6rem;
    height: 4.6rem;
    font-size: 2.7rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #b8b8b8; }
    @media (min-width: 1200px) {
      .ss-m-lmbox-icon {
        margin-right: 2.3rem; } }
    .ss-m-lmbox-icon.light-blue {
      background-color: #539eb9; }
    .ss-m-lmbox-icon.light-green {
      background-color: #58a5a9; }
    .ss-m-lmbox-icon.green {
      background-color: #64ba79; }
  .ss-m-lmbox-imgbox {
    margin: 2rem auto 0 auto;
    width: 100%;
    max-width: 16.6rem;
    position: relative;
    display: block; }
    @media (min-width: 576px) {
      .ss-m-lmbox-imgbox {
        margin: 0;
        position: absolute;
        bottom: 0;
        right: 2.1rem; } }
    .ss-m-lmbox-imgbox::before {
      width: 100%;
      padding-top: 144.578313253012%;
      content: '';
      display: block; }

.ss-m-pattern {
  width: 100%;
  max-width: 15rem;
  display: block;
  top: 0;
  left: 0;
  position: absolute; }
  @media (min-width: 768px) {
    .ss-m-pattern {
      max-width: 20rem; } }
  .ss-m-pattern::before {
    width: 100%;
    padding-top: 94%;
    content: '';
    display: block; }

.ss-n-pattern {
  width: 100%;
  max-width: 10rem;
  display: block;
  top: 100%;
  margin-top: -3rem;
  right: 0;
  position: absolute; }
  @media (min-width: 992px) {
    .ss-n-pattern {
      margin-top: 1rem; } }
  @media (min-width: 1200px) {
    .ss-n-pattern {
      margin-top: 4rem;
      max-width: 13.9rem; } }
  .ss-n-pattern::before {
    width: 100%;
    padding-top: 69.7841726618705%;
    content: '';
    display: block; }

.ss-n .ss-cc-block {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-n .ss-cc-block {
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .ss-n .ss-cc-block h2 {
      max-width: 40rem;
      margin-bottom: 5.2rem; } }
  @media (min-width: 992px) {
    .ss-n .ss-cc-block .ss-cc-desc {
      max-width: 47rem; } }

.ss-n-service-form .btn {
  width: 100%; }

.ss-o h2 {
  text-align: center; }

.ss-o-list-group {
  max-width: 92rem;
  margin: 0 auto;
  padding-top: 1rem; }
  @media (min-width: 1200px) {
    .ss-o-list-group {
      padding-top: 2.9rem; } }

.ss-o-list-row {
  position: relative;
  padding-left: 4rem;
  padding-bottom: 4.2rem; }
  @media (min-width: 1200px) {
    .ss-o-list-row {
      padding-left: 7.8rem; } }
  .ss-o-list-row::before {
    content: '';
    display: block;
    width: 0.1rem;
    height: 100%;
    position: absolute;
    top: 0.2rem;
    left: 1.3rem;
    background-color: #c4c4c4; }
    @media (min-width: 1200px) {
      .ss-o-list-row::before {
        left: 2.3rem; } }
  .ss-o-list-row:last-child::before {
    display: none; }
  .ss-o-list-row .h4 {
    margin-bottom: 1.4rem; }

.ss-o-ractangle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  transform: rotate(45deg);
  position: absolute;
  top: 0.5rem;
  left: 0;
  display: block; }
  @media (min-width: 1200px) {
    .ss-o-ractangle {
      width: 3.5rem;
      height: 3.5rem;
      left: 0.6rem;
      top: 0rem; } }

.ss-p {
  background-color: #222; }
  .ss-p .container {
    max-width: 1440px; }
  .ss-p .ss-section-inner {
    padding: 5rem 0; }
    @media (min-width: 992px) {
      .ss-p .ss-section-inner {
        padding: 6.1rem 0 7rem 0; } }
  .ss-p h2 {
    margin-bottom: 2rem;
    color: #fff;
    text-align: center; }
    @media (min-width: 1200px) {
      .ss-p h2 {
        margin-bottom: 7rem; } }
  .ss-p-slider {
    border-radius: 2rem; }
    .ss-p-slider .swiper-slide {
      height: auto; }
    .ss-p-slider-wrap {
      padding: 0 2rem;
      position: relative; }
      @media (min-width: 1200px) {
        .ss-p-slider-wrap {
          padding: 0 10rem; } }
      @media (min-width: 1400px) {
        .ss-p-slider-wrap {
          padding: 0 13.5rem; } }
    .ss-p-slider-arrow {
      width: 2rem;
      font-size: 2.6rem;
      height: 100%;
      top: 0;
      border-radius: 1.9rem;
      position: absolute;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 992px) {
        .ss-p-slider-arrow {
          width: 3rem; } }
      @media (min-width: 1200px) {
        .ss-p-slider-arrow {
          width: 7.4rem;
          background-color: rgba(255, 255, 255, 0.1); } }
      @media (min-width: 1400px) {
        .ss-p-slider-arrow {
          width: 9.4rem; } }
      .ss-p-slider-arrow.next {
        right: -1rem; }
        @media (min-width: 1400px) {
          .ss-p-slider-arrow.next {
            right: 0; } }
      .ss-p-slider-arrow.prev {
        left: -1rem; }
        @media (min-width: 1400px) {
          .ss-p-slider-arrow.prev {
            left: 0; } }
    .ss-p-slider-box {
      height: 100%;
      padding: 2rem 4rem 0 4rem;
      background: #fff;
      box-shadow: -0.5rem 0px 0.9rem -0.3rem rgba(0, 0, 0, 0.180288);
      border-radius: 1.9rem; }
      @media (min-width: 576px) {
        .ss-p-slider-box {
          display: flex; } }
      @media (min-width: 992px) {
        .ss-p-slider-box {
          padding: 2rem 2rem 0 2rem; } }
      @media (min-width: 1400px) {
        .ss-p-slider-box {
          padding: 2rem 3.1rem 0rem 4.6rem; } }
      .ss-p-slider-box .ss-cc-block {
        padding-top: 1.3rem;
        display: flex;
        flex-direction: column; }
        .ss-p-slider-box .ss-cc-block .btn {
          margin-top: auto;
          margin-bottom: 3.7rem;
          align-self: flex-start; }
    .ss-p-slider-imgbox {
      margin: 2rem auto 0 auto;
      width: 100%;
      max-width: 13rem;
      position: relative;
      align-self: flex-end;
      display: none; }
      @media (min-width: 576px) {
        .ss-p-slider-imgbox {
          margin: 0 0 0 auto; } }
      @media (min-width: 992px) {
        .ss-p-slider-imgbox {
          display: block; } }
      @media (min-width: 1200px) {
        .ss-p-slider-imgbox {
          max-width: 17.7rem; } }
      .ss-p-slider-imgbox::before {
        width: 100%;
        padding-top: 144.578313253012%;
        content: '';
        display: block; }
    .ss-p-slider .checklist li {
      font-size: 1.4rem;
      margin-bottom: 0.4rem;
      color: rgba(64, 64, 65, 0.6); }
  .ss-p-solutions-btn-wrap {
    padding-top: 5rem;
    display: flex;
    justify-content: center; }
    @media (min-width: 992px) {
      .ss-p-solutions-btn-wrap {
        padding-top: 6.5rem; } }
    .ss-p-solutions-btn-wrap .btn {
      color: #fff;
      border-color: #fff; }
      .ss-p-solutions-btn-wrap .btn i {
        font-size: 1.3rem; }

.ss-q {
  background-color: #222;
  color: #fff; }
  .ss-q .ss-section-inner {
    padding: 5rem 0; }
    @media (min-width: 992px) {
      .ss-q .ss-section-inner {
        padding: 6rem 0; } }
  @media (min-width: 992px) {
    .ss-q h2 {
      margin-bottom: 0; } }

.ss-r-head {
  margin-bottom: 9rem;
  text-align: center; }

.ss-r-ws-box {
  padding: 1.6rem 2rem;
  background-color: #f6f6f6;
  border-radius: 2rem; }
  @media (min-width: 992px) {
    .ss-r-ws-box {
      padding: 2.6rem 2.5rem; } }
  @media (min-width: 1200px) {
    .ss-r-ws-box {
      padding: 4.6rem 3.5rem; } }

.ss-r-list {
  margin: 0 -1.5rem;
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1400px) {
    .ss-r-list {
      margin: 0 -3.5rem;
      padding: 0 3.5rem; } }
  .ss-r-list li::before {
    display: none; }
  .ss-r-list.pink .ss-r-check-icon {
    color: #df4469 !important; }
  .ss-r-list-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
    padding: 0.5rem 1.5rem;
    position: relative;
    display: flex;
    font-size: 1.8rem;
    color: #222; }
    @media (min-width: 768px) {
      .ss-r-list-box {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%; } }
    @media (min-width: 1200px) {
      .ss-r-list-box {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
        padding: 1.3rem 1.5rem; } }
    @media (min-width: 1400px) {
      .ss-r-list-box {
        padding: 1.3rem 3.5rem; } }

.ss-r-check-icon {
  margin-top: 0.1rem;
  margin-right: 1.7rem;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.ss-s-head {
  margin-bottom: 2.5rem;
  text-align: center; }

.ss-s-org-wrap {
  margin: 0;
  padding: 2rem 2rem;
  background-color: #f6f6f6;
  border-radius: 2rem; }
  @media (min-width: 1200px) {
    .ss-s-org-wrap {
      padding: 4rem 5rem; } }
  @media (min-width: 1400px) {
    .ss-s-org-wrap {
      padding: 6.3rem 6.9rem; } }

.ss-s-org-box {
  min-height: 28rem;
  margin: 1.5rem 0;
  background: #fff;
  border-radius: 1rem;
  position: relative; }
  @media (min-width: 1200px) {
    .ss-s-org-box {
      margin: 2.5rem 0; } }

.ss-s-org-head {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #eaeaea; }
  @media (min-width: 576px) {
    .ss-s-org-head {
      padding: 1.5rem 15rem 1.5rem 2rem; } }
  @media (min-width: 1200px) {
    .ss-s-org-head {
      padding: 1.5rem 15rem 1.5rem 2rem; } }
  @media (min-width: 1400px) {
    .ss-s-org-head {
      padding: 2rem 15rem 2rem 2.7rem; } }
  .ss-s-org-head .h4 {
    margin-bottom: 0; }

.ss-s-org-body {
  padding: 1.5rem; }
  @media (min-width: 576px) {
    .ss-s-org-body {
      padding: 1.5rem 15rem 1.5rem 1.5rem; } }
  @media (min-width: 1200px) {
    .ss-s-org-body {
      padding: 1.5rem 15rem 1.5rem 2rem; } }
  @media (min-width: 1400px) {
    .ss-s-org-body {
      padding: 1.5rem 15rem 1.5rem 2.9rem; } }

.ss-s-org-points {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block; }
  .ss-s-org-points li::before {
    display: none; }
  .ss-s-org-points li::after {
    width: 1.3rem;
    height: 1.3rem;
    top: 1rem;
    left: 1rem;
    background: #9a9a9a;
    opacity: 0.2;
    border-radius: 0.2rem;
    transform: rotate(45deg);
    content: '';
    display: block;
    position: absolute; }
  .ss-s-org-points li {
    margin: 0.2rem 0;
    padding: 0.3rem 0 0.3rem 4.1rem;
    list-style: none;
    display: block;
    position: relative; }

.ss-s-org-icon {
  margin-right: 1.4rem;
  width: 4.5rem;
  height: 4.5rem;
  font-size: 3.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #58a5a9; }

.ss-s-org-imgbox {
  margin: 2rem auto 0 auto;
  width: 100%;
  max-width: 15.6rem;
  position: relative;
  display: block; }
  @media (min-width: 576px) {
    .ss-s-org-imgbox {
      margin: 0;
      position: absolute;
      bottom: 0;
      right: 0; } }
  .ss-s-org-imgbox::before {
    width: 100%;
    padding-top: 169.2307692307692%;
    content: '';
    display: block; }

.ss-t .ss-cc-head {
  padding: 5rem 0; }
  @media (min-width: 768px) {
    .ss-t .ss-cc-head {
      padding: 6rem 0; } }
  @media (min-width: 1200px) {
    .ss-t .ss-cc-head {
      padding: 9rem 0 7.8rem 0; } }

@media (min-width: 992px) {
  .ss-t-row {
    margin: 0 -3.8rem; } }

@media (min-width: 1200px) {
  .ss-t-row {
    margin: 0 -4.8rem; } }

@media (min-width: 992px) {
  .ss-t-row .col,
  .ss-t-row [class*=col-] {
    padding: 0 3.8rem; } }

@media (min-width: 1200px) {
  .ss-t-row .col,
  .ss-t-row [class*=col-] {
    padding: 0 4.8rem; } }

@media (min-width: 992px) {
  .ss-t-row > :first-child {
    border-right: 0.1rem solid #e7e7e7; } }

.ss-t-imgbox {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border: 0.1rem solid #eaeaea;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-t-imgbox::before {
    width: 100%;
    padding-top: 54.91228070175439%;
    content: '';
    display: block; }

.ss-t-desc h3 a {
  color: #222 !important;
  text-decoration: none !important; }

.ss-t-hr-lbl {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.429;
  font-weight: 600;
  color: #539cc1;
  display: block; }

@media (min-width: 1200px) {
  .ss-t-right h4 {
    margin-bottom: 3.6rem; } }

.ss-t-right .ss-t-hr-lbl {
  color: rgba(255, 255, 255, 0.8); }

.ss-t-right :last-child {
  margin-bottom: 0; }

.ss-t-rf-box {
  margin-bottom: 3rem;
  padding: 2.6rem 3rem 3rem 3rem;
  border-radius: 1rem;
  background-color: #f5f5f5;
  position: relative;
  color: #fff;
  overflow: hidden; }
  .ss-t-rf-box .ss-cc-block {
    max-width: 36rem; }
    @media (min-width: 1200px) {
      .ss-t-rf-box .ss-cc-block .btn {
        min-width: 17rem; } }

.ss-t-rf-bg-icon {
  font-size: 21rem;
  bottom: -1.5rem;
  right: 0;
  position: absolute;
  transform: rotate(-30deg);
  opacity: 0.15; }

.ss-u {
  border-bottom: 0;
  position: fixed;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 3rem rgba(68, 68, 68, 0.2);
  margin-top: 0 !important;
  width: 100%;
  top: 5.8rem; }
  @media (min-width: 1200px) {
    .ss-u {
      top: 7.8rem; } }
  .ss-u-nav-wrapper {
    overflow-x: auto;
    overflow-y: hidden; }
  .ss-u-nav {
    margin: 0;
    padding: 1.6rem 0;
    list-style: none;
    display: flex; }
    .ss-u-nav.tab-nav > li > a {
      font-weight: 400;
      color: #404041; }
      .ss-u-nav.tab-nav > li > a.active {
        font-weight: 700; }
    @media (min-width: 992px) {
      .ss-u-nav {
        justify-content: space-between;
        flex-wrap: wrap; } }
    .ss-u-nav > li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block;
      white-space: nowrap; }
      .ss-u-nav > li ::before {
        display: none; }
      .ss-u-nav > li > a {
        padding: 1rem;
        font-size: 1.4rem;
        line-height: 1.556;
        font-weight: 600;
        color: #404041 !important;
        display: block;
        text-decoration: none !important; }
        .ss-u-nav > li > a:not(.active):hover {
          color: #539cc1 !important; }
        @media (min-width: 992px) {
          .ss-u-nav > li > a {
            font-size: 1.6rem; } }
        @media (min-width: 1400px) {
          .ss-u-nav > li > a {
            font-size: 1.8rem; } }

.ss-v-gc-link {
  margin-top: 1rem;
  font-size: 1.8rem;
  line-height: 1.556;
  font-weight: 600;
  color: #539cc1;
  display: block;
  text-decoration: none; }
  .ss-v-gc-link:hover {
    text-decoration: underline; }
  @media (min-width: 992px) {
    .ss-v-gc-link {
      margin: 0 0 0 auto; } }

.ss-v-topics-row > .col,
.ss-v-topics-row > [class*=col-] {
  margin: 1.5rem 0; }

.ss-v-topics-box {
  margin: 0;
  min-height: 8rem;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important; }
  .ss-v-topics-box.active {
    background-color: #539cc1;
    color: #fff !important; }
    .ss-v-topics-box.active .ss-v-topics-name {
      color: inherit; }
  @media (min-width: 576px) {
    .ss-v-topics-box {
      min-height: 13.7rem; } }

.ss-v-topics-name {
  font-size: 1.8rem;
  line-height: 1.333;
  font-weight: 600;
  color: #404041;
  text-align: center;
  display: block; }
  @media (min-width: 576px) {
    .ss-v-topics-name {
      max-width: 14rem; } }

.ss-v-subscribe-box {
  margin: 1.5rem 0;
  padding: 3.2rem 3rem 3.3rem 3rem;
  border-radius: 1.2rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column; }
  .ss-v-subscribe-box.light-blue {
    background-color: rgba(83, 158, 185, 0.1); }
  @media (min-width: 768px) {
    .ss-v-subscribe-box {
      margin-top: 0; } }

.ss-v-subscribe-imgbox {
  margin-bottom: 1.7rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 0.9rem;
  overflow: hidden;
  background-color: #fff; }
  .ss-v-subscribe-imgbox::before {
    width: 100%;
    padding-top: 61.42857142857143%;
    content: '';
    display: block; }

.ss-v-subscribe-footer {
  padding-top: 1rem; }
  @media (min-width: 992px) {
    .ss-v-subscribe-footer {
      padding-top: 2.9rem; } }
  .ss-v-subscribe-footer .btn {
    width: 100%;
    max-width: 24rem;
    margin: 0 auto;
    display: block; }

.ss-w-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px; }
  @media (min-width: 992px) {
    .ss-w-article {
      margin-bottom: 0; } }
  @media (min-width: 576px) {
    .ss-w-article.theme-b {
      flex-direction: row; } }
  @media (min-width: 576px) {
    .ss-w-article.theme-b .ss-w-imgbox {
      max-width: 17rem;
      margin: 0 3rem 0 0; } }
  .ss-w-article.theme-b .h4 {
    font-size: 1.8rem; }
  @media (min-width: 1200px) {
    .ss-w-article-group {
      padding-left: 3rem; } }
  .ss-w-article-group .ss-w-article {
    padding: 2rem 0;
    border-bottom: 0.1rem solid rgba(151, 151, 151, 0.5); }
    @media (max-width: 575px) {
      .ss-w-article-group .ss-w-article {
        border-top: 0.1rem solid rgba(151, 151, 151, 0.5); } }
    @media (min-width: 1200px) {
      .ss-w-article-group .ss-w-article {
        padding: 3rem 0; } }
    @media (min-width: 992px) {
      .ss-w-article-group .ss-w-article:first-child {
        padding-top: 0; } }
    @media (max-width: 991px) {
      .ss-w-article-group .ss-w-article:last-child {
        padding-bottom: 0;
        border-bottom: none; } }

.ss-w-imgbox {
  margin-bottom: 1.7rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 0.9rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-w-imgbox::before {
    width: 100%;
    padding-top: 64.03508771929825%;
    content: '';
    display: block; }

.ss-w-desc h3 {
  margin-bottom: 1rem; }
  .ss-w-desc h3 a {
    color: #222 !important;
    text-decoration: none !important; }

.ss-w-desc-meta {
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 1.429;
  font-weight: 400;
  color: rgba(64, 64, 65, 0.6);
  display: block; }

.ss-w-hr-lbl {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.429;
  font-weight: 600;
  color: #539cc1;
  display: block; }

.ss-x-box {
  background-color: #daedf7;
  border-radius: 1rem; }

.ss-x-imgbox {
  margin: 0 auto;
  width: 100%;
  max-width: 28rem;
  position: relative;
  display: block;
  border-radius: 0.9rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-x-imgbox::before {
    width: 100%;
    padding-top: 90.71428571428571%;
    content: '';
    display: block; }

.ss-x-right-content {
  padding: 2rem; }
  .ss-x-right-content .btn {
    min-width: 20rem; }

.ss-x-meta {
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  color: #222;
  display: block; }

.ss-y .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .ss-y .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-y-box {
  padding-bottom: 3rem; }
  @media (min-width: 768px) {
    .ss-y-box {
      padding-bottom: 0; } }
  .ss-y-box .h4 {
    margin-bottom: 0.5rem; }
    .ss-y-box .h4 a {
      color: #000;
      margin-bottom: 10px;
      display: inline-block;
      margin-top: 20px; }
      .ss-y-box .h4 a:hover {
        color: #539cc1; }
  .ss-y-box .btn {
    min-width: 14.5rem; }

.ss-y-imgbox {
  margin: 0 0 1.8rem 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 0.9rem;
  overflow: hidden;
  border: 0.1rem solid #e7e7e7;
  background-color: #f5f5f5;
  box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.14); }
  .ss-y-imgbox::before {
    width: 100%;
    padding-top: 145.9459459459459%;
    content: '';
    display: block; }

.ss-y-right-content {
  padding: 2rem; }
  .ss-y-right-content .btn {
    min-width: 20rem; }

.ss-y-meta {
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  color: #222;
  display: block; }

.ss-z .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .ss-z .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-z-box {
  color: #fff;
  padding: 3rem 4rem 3rem 3rem;
  height: 100%;
  background-color: #000;
  border-radius: 1rem;
  position: relative; }
  @media (min-width: 768px) {
    .ss-z-box {
      padding-right: 6rem; } }
  @media (min-width: 992px) {
    .ss-z-box {
      padding-right: 7rem; } }
  .ss-z-box-row > .col,
  .ss-z-box-row > [class*=col-] {
    margin: 1.5rem 0; }
  .ss-z-box-meta {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.429;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    display: block; }
  .ss-z-box-icon {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1.2rem;
    right: 1.1rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3); }
    @media (min-width: 768px) {
      .ss-z-box-icon {
        width: 5.8rem;
        height: 5.8rem;
        font-size: 2.8rem; } }
    @media (min-width: 992px) {
      .ss-z-box-icon {
        top: 2.2rem;
        right: 2.1rem; } }
  .ss-z-box .btn {
    margin: 0 1rem 0.5rem 0;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .ss-z-box .btn {
        margin-right: 2; } }
    .ss-z-box .btn:last-child {
      margin-right: 0; }

.ss-aa-row {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (min-width: 1200px) {
    .ss-aa-row {
      flex-direction: row; } }
  .ss-aa-row .h4 {
    margin-bottom: 0; }
    @media (min-width: 1200px) {
      .ss-aa-row .h4 {
        margin-top: 1.7rem; } }

.ss-aa-nav {
  margin: 0 -1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media (min-width: 1200px) {
    .ss-aa-nav {
      margin: 0; } }
  .ss-aa-nav > li {
    margin: 1rem;
    list-style: none; }
    .ss-aa-nav > li > a {
      padding: 1rem 2rem;
      font-size: 1.6rem;
      line-height: 1.75;
      font-weight: 600;
      color: #222;
      background-color: #f5f5f5;
      border-radius: 0.6rem;
      text-decoration: none !important;
      display: block; }
      .ss-aa-nav > li > a:hover, .ss-aa-nav > li > a.active {
        color: #fff;
        background-color: #222; }

.ss-aa-left {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 1200px) {
    .ss-aa-left {
      margin-bottom: 0;
      width: 16.9rem;
      max-width: 16.9rem;
      flex: 0 0 16.9rem; } }

.ss-aa-middle {
  flex: 1;
  display: flex;
  flex-wrap: wrap; }

.ss-aa-right {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 1200px) {
    .ss-aa-right {
      width: 23.4rem;
      max-width: 23.4rem;
      flex: 0 0 23.4rem; } }
  .ss-aa-right .form-group {
    margin-top: 1rem;
    margin-bottom: 0; }
  .ss-aa-right .ss-icon-search {
    color: #b3b3b3; }

.ss-aa-form {
  max-width: 35rem; }

.ss-bb-feature-row {
  margin-bottom: 3rem; }
  .ss-bb-feature-row > .col,
  .ss-bb-feature-row > [class*=col-] {
    margin: 1.5rem 0; }

.ss-bb-row-a {
  align-items: center;
  flex-direction: column-reverse; }
  @media (min-width: 768px) {
    .ss-bb-row-a {
      flex-direction: row; } }
  @media (min-width: 992px) {
    .ss-bb-row-a {
      flex-direction: column-reverse; } }
  @media (min-width: 1200px) {
    .ss-bb-row-a {
      flex-direction: row; } }

.ss-bb-box {
  padding: 3.2rem 3.4rem 3.8rem 3rem;
  background-color: #000;
  color: #fff;
  border-radius: 1rem;
  overflow: hidden;
  text-align: center; }
  @media (min-width: 768px) {
    .ss-bb-box {
      text-align: left; } }
  @media (min-width: 992px) {
    .ss-bb-box {
      text-align: center; } }
  @media (min-width: 1200px) {
    .ss-bb-box {
      text-align: left; } }
  .ss-bb-box h2 {
    margin-bottom: 0; }
  .ss-bb-box-left {
    padding: 2rem 0; }
  .ss-bb-box-imgbox {
    margin: 0 auto;
    width: 100%;
    max-width: 20.5rem;
    position: relative;
    display: block;
    border-radius: 1rem;
    background-color: #f5f5f5; }
    @media (min-width: 1200px) {
      .ss-bb-box-imgbox {
        margin: 0 0 0 auto; } }
    .ss-bb-box-imgbox::before {
      width: 100%;
      padding-top: 125.8536585365854%;
      content: '';
      display: block; }
    .ss-bb-box-imgbox::after {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      top: -3rem;
      left: -3rem;
      border-radius: 1rem;
      background-color: #fff; }
    .ss-bb-box-imgbox .lazy-loader {
      z-index: 1; }
      .ss-bb-box-imgbox .lazy-loader .lazyload {
        border-radius: 1rem;
        overflow: hidden; }

.ss-bb-meta {
  margin-bottom: 5rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 700;
  color: #fff;
  display: block; }
  @media (min-width: 768px) {
    .ss-bb-meta {
      margin-bottom: 3rem; } }

.ss-bb-bedge {
  padding: 0.9rem 1.5rem 0.8rem 1.5rem;
  margin-bottom: 2.6rem;
  font-size: 1.4rem;
  line-height: 1.286;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 0.3rem;
  background-color: rgba(234, 234, 234, 0.15); }

@media (min-width: 768px) {
  .ss-bb .ss-post {
    padding-bottom: 0; } }

.ss-bb-link-topost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4; }

.ss-bb-link-totag {
  position: relative;
  z-index: 6; }

.ss-cc-article {
  padding: 0 2rem;
  margin-bottom: 4.5rem;
  border-radius: 1.5rem; }
  @media (min-width: 768px) {
    .ss-cc-article {
      padding: 0 3rem; } }
  @media (min-width: 1200px) {
    .ss-cc-article {
      padding: 0 6.5rem; } }
  .ss-cc-article > .row {
    align-items: center;
    flex-direction: column-reverse; }
    @media (min-width: 768px) {
      .ss-cc-article > .row {
        flex-direction: row; } }
  .ss-cc-article-left {
    padding: 3rem 0;
    color: #fff; }
    .ss-cc-article-left h2 {
      padding-bottom: 3rem;
      position: relative; }
      @media (min-width: 1200px) {
        .ss-cc-article-left h2 {
          font-size: 4rem; } }
      .ss-cc-article-left h2::after {
        content: '';
        display: block;
        width: 100%;
        max-width: 9.7rem;
        background-color: rgba(255, 255, 255, 0.5);
        height: 0.1rem;
        left: 0;
        bottom: 0;
        position: absolute; }
  .ss-cc-article-imgbox {
    margin: 0 auto;
    width: 100%;
    max-width: 52.2rem;
    position: relative;
    display: block; }
    .ss-cc-article-imgbox::before {
      width: 100%;
      padding-top: 100%;
      content: '';
      display: block; }
  .ss-cc-article-footer-lbl {
    font-size: 1.7rem;
    line-height: 1.647;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    display: block; }

.ss-dd .ss-t-desc {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-dd .ss-t-desc {
      margin-bottom: 0; } }
  .ss-dd .ss-t-desc h3 {
    margin-bottom: 0; }

.ss-ee {
  display: block; }

.ss-ff .btn {
  margin: 3rem auto;
  display: block;
  max-width: 17rem; }
  @media (min-width: 576px) {
    .ss-ff .btn {
      margin: 0;
      max-width: 35.4rem; } }

@media (min-width: 1400px) {
  .ss-ff {
    min-width: 35.4rem; } }

.ss-ff-row {
  flex-direction: column-reverse;
  align-items: center; }
  @media (min-width: 576px) {
    .ss-ff-row {
      flex-direction: row; } }

.ss-ff-imgwrap {
  margin-right: -11.5rem; }

.ss-ff-imgbox {
  width: 100%;
  position: relative;
  display: block; }
  .ss-ff-imgbox::before {
    width: 100%;
    padding-top: 49.42857142857143%;
    content: '';
    display: block; }

@media (min-width: 1200px) {
  .ss-gg-rwo-b .ss-cc-block {
    padding-left: 7rem; } }

.ss-gg-top-row {
  margin-bottom: 4.3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column; }
  @media (min-width: 576px) {
    .ss-gg-top-row {
      flex-direction: row; } }

.ss-gg-back-link {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 600;
  color: #222;
  display: flex;
  align-items: center;
  text-decoration: none !important; }
  .ss-gg-back-link > i {
    margin-right: 1.4rem; }

.ss-gg-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex; }
  @media (min-width: 576px) {
    .ss-gg-social {
      margin-left: auto; } }
  .ss-gg-social > li {
    margin: 0 1rem;
    padding: 0;
    list-style: none;
    display: flex; }
    .ss-gg-social > li:first-child {
      margin-left: 0; }
    .ss-gg-social > li:last-child {
      margin-right: 0; }
    .ss-gg-social > li > a {
      width: 5.1rem;
      height: 5.1rem;
      font-size: 2.6rem;
      color: #222;
      background-color: #f5f5f5;
      border-radius: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
      .ss-gg-social > li > a:hover {
        color: #fff;
        background-color: #222; }

.ss-hh-post {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1200px) {
    .ss-hh-post {
      padding-bottom: 4.5rem; } }
  .ss-hh-post.theme-row {
    padding: 0; }
    @media (min-width: 768px) {
      .ss-hh-post.theme-row {
        margin: 0 -1.5rem;
        flex-direction: row;
        align-items: center; } }
    @media (min-width: 768px) {
      .ss-hh-post.theme-row .ss-hh-imgbox-wrap {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        width: 54.25%;
        max-width: 54.25%;
        flex: 0 0 54.25%; } }
    @media (min-width: 768px) {
      .ss-hh-post.theme-row .ss-hh-desc-content {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        width: 45.75%;
        max-width: 45.75%;
        flex: 0 0 45.75%; } }
    @media (min-width: 1200px) {
      .ss-hh-post.theme-row .ss-hh-desc {
        padding: 0 3.9rem;
        max-width: 45rem; } }
    .ss-hh-post.theme-row .ss-hh-imgbox::before {
      padding-top: 69.72624798711755%; }

.ss-hh-imgbox {
  margin: 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  background-color: #f5f5f5;
  border-radius: 1rem;
  overflow: hidden; }
  .ss-hh-imgbox::before {
    width: 100%;
    padding-top: 75.47169811320755%;
    content: '';
    display: block; }

.ss-hh-caption-lbl {
  padding: 0.8rem 2rem;
  left: 2.4rem;
  bottom: 2.5rem;
  position: absolute;
  background: #f5f5f5;
  opacity: 0.96;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  line-height: 1.286;
  font-weight: 600;
  color: #404041 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  display: block; }

.ss-hh-desc-content {
  padding: 2rem 0 1rem 0; }
  @media (min-width: 1200px) {
    .ss-hh-desc-content {
      padding-bottom: 2.8rem; } }

.ss-hh-desc > h4 {
  margin-bottom: 0; }
  .ss-hh-desc > h4 > a {
    color: #222 !important;
    text-decoration: none !important; }

.ss-hh-author-category {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  text-decoration: none !important;
  display: block; }

.ss-hh-nav {
  margin: 0 -1.3rem;
  padding: 2.4rem 0 0 0;
  list-style: none;
  display: flex; }
  .ss-hh-nav > li {
    margin: 0 1.3rem;
    padding: 0;
    list-style: none;
    display: flex; }
    .ss-hh-nav > li > a {
      width: 3.6rem;
      height: 3.6rem;
      font-size: 3.6rem;
      color: #1d1d1d;
      display: flex;
      align-items: center;
      justify-content: center; }

.ss-ii {
  margin-bottom: 24rem;
  position: relative;
  z-index: 2;
  background-color: #f5f5f5;
  border-bottom: 2.8rem solid #e3cfb3; }
  .ss-ii .ss-section-inner {
    padding-top: 26.8rem; }
    @media (min-width: 768px) {
      .ss-ii .ss-section-inner {
        padding-top: 28rem; } }
    @media (min-width: 992px) {
      .ss-ii .ss-section-inner {
        padding-top: 32.8rem; } }
  .ss-ii-imgbox {
    margin: 0 auto -15rem auto;
    width: 100%;
    max-width: 27.5rem;
    position: relative;
    display: block;
    filter: drop-shadow(0px 0.8rem 3rem rgba(0, 0, 0, 0.18)); }
    .ss-ii-imgbox::before {
      width: 100%;
      padding-top: 100%;
      content: '';
      display: block; }

.ss-jj-imgbox {
  margin-top: 3rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  @media (min-width: 992px) {
    .ss-jj-imgbox {
      margin-top: 4rem; } }
  @media (min-width: 1200px) {
    .ss-jj-imgbox {
      margin-top: 6rem; } }
  .ss-jj-imgbox::before {
    width: 100%;
    padding-top: 43.50877192982456%;
    content: '';
    display: block; }
  .ss-jj-imgbox.large {
    margin-top: 0; }
    .ss-jj-imgbox.large::before {
      padding-top: 42.64957264957265%; }

.ss-kk {
  background-color: #e7e7e7; }
  .ss-kk .ss-section-inner {
    padding: 5rem 0; }
    @media (min-width: 992px) {
      .ss-kk .ss-section-inner {
        padding: 6rem 0; } }
    @media (min-width: 1200px) {
      .ss-kk .ss-section-inner {
        padding: 8.1rem 0 7.7rem 0; } }
  .ss-kk-box {
    max-width: 80rem;
    padding: 3rem 2rem;
    background-color: #fff;
    box-shadow: 0px 0.1rem 2.2rem rgba(0, 0, 0, 0.037205);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 768px) {
      .ss-kk-box {
        padding: 3rem;
        flex-direction: row; } }
    @media (min-width: 992px) {
      .ss-kk-box {
        padding: 7rem 5.1rem 7.3rem 6.5rem; } }
    .ss-kk-box-left {
      margin-bottom: 3rem;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; }
      @media (min-width: 768px) {
        .ss-kk-box-left {
          margin-bottom: 0;
          width: 37rem;
          max-width: 37rem;
          flex: 0 0 37rem; } }
    .ss-kk-box-right {
      flex: 1; }
      @media (min-width: 768px) {
        .ss-kk-box-right {
          padding-left: 3rem; } }
      @media (min-width: 992px) {
        .ss-kk-box-right {
          padding-left: 7.2rem; } }
  .ss-kk-form .ss-checkradio-text {
    font-size: 1.8rem;
    color: #222; }
  .ss-kk-form .btn {
    width: 100%; }
  .ss-kk-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-kk-list > li {
      margin: 0 0 2rem 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center; }
      @media (min-width: 992px) {
        .ss-kk-list > li {
          margin: 0 0 3.8rem 0; } }
      .ss-kk-list > li:last-child {
        margin-bottom: 0; }
    .ss-kk-list-icon {
      flex-shrink: 0;
      margin-right: 1.9rem;
      width: 4rem;
      height: 4rem;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #539cc1;
      background-color: rgba(83, 156, 193, 0.1);
      border-radius: 50%; }
    .ss-kk-list-text {
      font-size: 1.8rem;
      line-height: 1.556;
      font-weight: 400;
      color: #222;
      display: block; }

.ss-ll-box {
  background: #f6f6f6;
  border-radius: 1rem;
  overflow: hidden; }
  .ss-ll-box .row {
    align-items: center; }

.ss-ll-left {
  position: relative; }

.ss-ll-right {
  padding: 3rem 2rem; }
  @media (min-width: 1200px) {
    .ss-ll-right {
      padding: 2rem 4.5rem; } }
  .ss-ll-right h2 {
    margin-bottom: 2.6rem;
    padding-bottom: 3.8rem;
    position: relative; }
    .ss-ll-right h2::before {
      width: 14.1rem;
      height: 0.1rem;
      bottom: 0;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      background-color: #979797; }

.ss-ll-client-detail {
  display: flex;
  align-items: center; }

.ss-ll-client-avtar {
  margin: 0;
  width: 5.8rem;
  height: 5.8rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  background-color: #222;
  position: relative; }
  .ss-ll-client-avtar::before {
    width: 100%;
    padding-top: 100%;
    content: '';
    display: block; }
  .ss-ll-client-avtar > strong {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    line-height: 1.333;
    font-weight: 600;
    color: #fff !important;
    text-decoration: none !important;
    text-transform: uppercase; }

.ss-ll-client-name {
  padding-left: 1rem;
  font-size: 1.8rem;
  line-height: 1.556;
  font-weight: 400;
  color: #222;
  text-decoration: none !important; }
  @media (min-width: 1200px) {
    .ss-ll-client-name {
      padding-left: 1.7rem; } }

.ss-ll-imgbox {
  margin: 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 1rem;
  overflow: hidden; }
  .ss-ll-imgbox::before {
    width: 100%;
    padding-top: 100.8403361344538%;
    content: '';
    display: block; }

.ss-ll-social {
  top: 2.3rem;
  right: 1.8rem;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column; }
  .ss-ll-social > li {
    margin: 1.1rem;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-ll-social > li > a {
      width: 5.1rem;
      height: 5.1rem;
      font-size: 2.6rem;
      color: #222;
      background-color: #f5f5f5;
      border-radius: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
      .ss-ll-social > li > a:hover {
        color: #fff;
        background-color: #222; }

@media (min-width: 1200px) {
  .ss-ll .badge-row {
    margin-bottom: 4.1rem; } }

.ss-ll-nav {
  margin: 0 -1rem;
  padding: 2.6rem 0 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .ss-ll-nav > li {
    margin: 0.5rem 1rem;
    list-style: none; }
    .ss-ll-nav > li > a {
      padding: 0.6rem 1.5rem;
      font-size: 1.4rem;
      line-height: 1.75;
      font-weight: 600;
      color: #222;
      text-transform: uppercase;
      background-color: rgba(245, 245, 245, 0.96);
      border-radius: 0.3rem;
      text-decoration: none !important;
      display: block; }
      .ss-ll-nav > li > a:hover, .ss-ll-nav > li > a.active {
        color: #fff;
        background-color: #b2b2b3; }

.ss-mm-content {
  min-height: 20rem;
  position: relative; }

.ss-mm-article {
  max-width: 95.8rem;
  padding: 0 0 0 6.6rem;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .ss-mm-article {
      padding: 0 6.6rem; } }
  @media (min-width: 1200px) {
    .ss-mm-article > p {
      margin-bottom: 3rem; } }
  .ss-mm-article > p > a {
    font-weight: 700; }
  @media (min-width: 992px) {
    .ss-mm-article > ul {
      padding-left: 5rem; } }
  @media (min-width: 1200px) {
    .ss-mm-article > ul {
      padding-left: 10rem; } }
  .ss-mm-article > ul > li {
    list-style: none;
    padding: 0;
    margin-bottom: 2.5rem;
    position: relative; }
    .ss-mm-article > ul > li::before {
      top: 0.8rem;
      left: -3rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: #2a2a2a;
      content: '';
      display: block;
      position: absolute; }
  .ss-mm-article-head {
    padding: 2rem 0 4rem 0;
    display: flex;
    align-items: center; }
    @media (min-width: 992px) {
      .ss-mm-article-head {
        padding: 3rem 0 6rem 0; } }
    @media (min-width: 1200px) {
      .ss-mm-article-head {
        padding: 5.3rem 0 7.9rem 0; } }
    .ss-mm-article-head > h4 {
      margin-bottom: 0;
      padding-right: 5.4rem; }
    .ss-mm-article-head::after {
      width: 100%;
      height: 0.1rem;
      content: '';
      display: block;
      flex: 1;
      background-color: rgba(151, 151, 151, 0.5); }
  .ss-mm-article-info {
    max-width: 96.8rem;
    margin: 0 auto;
    padding: 2rem 1rem 2rem 6.6rem;
    background-color: rgba(234, 234, 234, 0.1);
    border-radius: 1rem; }
    @media (min-width: 992px) {
      .ss-mm-article-info {
        padding: 4rem 1rem 4rem 6.6rem; } }
    @media (min-width: 1200px) {
      .ss-mm-article-info {
        margin-top: 8.7rem;
        padding: 7rem 5rem 9.6rem 7.7rem; } }
    .ss-mm-article-info > :last-child {
      margin-bottom: 0; }
    .ss-mm-article-info > h3 {
      margin-bottom: 0.6rem;
      color: #539cc1; }
    .ss-mm-article-info > h4 {
      padding-bottom: 4rem;
      margin-bottom: 3.9rem;
      position: relative; }
      .ss-mm-article-info > h4::before {
        width: 100%;
        max-width: 24.8rem;
        height: 0.1rem;
        bottom: 0;
        left: 0;
        position: absolute;
        content: '';
        display: block;
        background-color: #979797; }

.ss-mm-social {
  top: 0rem;
  left: 0rem;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column; }
  .ss-mm-social > li {
    margin: 1.1rem 0;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-mm-social > li > a {
      width: 5.1rem;
      height: 5.1rem;
      font-size: 2.6rem;
      color: #222;
      background-color: #f5f5f5;
      border-radius: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
      .ss-mm-social > li > a:hover {
        color: #fff;
        background-color: #222; }

.ss-nn .ss-cc-head {
  padding: 9rem 0 6.6rem 0; }

.ss-nn-culture {
  padding: 0 3rem;
  background: rgba(234, 234, 234, 0.35);
  border-radius: 2rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end; }
  @media (min-width: 992px) {
    .ss-nn-culture {
      flex-direction: row; } }
  .ss-nn-culture-left {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 992px) {
      .ss-nn-culture-left {
        margin-top: -7.4rem;
        padding-top: 0;
        width: 37.6rem;
        max-width: 37.6rem;
        flex: 0 0 37.6rem; } }
  .ss-nn-culture-right {
    padding-top: 2rem;
    flex: 1;
    align-self: flex-start; }
    @media (min-width: 992px) {
      .ss-nn-culture-right {
        padding: 4.2rem 0 2.2rem 4.2rem; } }
    @media (min-width: 1200px) {
      .ss-nn-culture-right {
        padding-right: 2.2rem;
        padding-left: 7rem; } }
  .ss-nn-culture-imgbox {
    margin: 0 auto;
    width: 100%;
    max-width: 37.6rem;
    position: relative;
    display: block; }
    .ss-nn-culture-imgbox::before {
      width: 100%;
      padding-top: 104.5212765957447%;
      content: '';
      display: block; }

.ss-nn-row-b .col,
.ss-nn-row-b [class*=col-] {
  margin-top: 2.5rem; }
  @media (min-width: 1200px) {
    .ss-nn-row-b .col,
    .ss-nn-row-b [class*=col-] {
      margin-top: 4.5rem; } }

.ss-nn-box {
  height: 100%;
  background: rgba(234, 234, 234, 0.35);
  border-radius: 2rem;
  overflow: hidden;
  text-align: center; }
  .ss-nn-box-head {
    background: #64ba79;
    padding: 1.4rem;
    color: #fff; }
    .ss-nn-box-head h4 {
      margin-bottom: 0; }
  .ss-nn-box .ss-cc-desc {
    margin-bottom: 0;
    padding: 2rem; }
    @media (min-width: 1200px) {
      .ss-nn-box .ss-cc-desc {
        padding: 2.9rem 3rem 3.4rem 3rem; } }

.ss-oo-row-a {
  padding: 3rem 0; }
  @media (min-width: 992px) {
    .ss-oo-row-a {
      padding: 5rem 0; } }
  @media (min-width: 1200px) {
    .ss-oo-row-a {
      padding: 10rem 0; } }

.ss-oo .ss-cc-head {
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .ss-oo .ss-cc-head {
      margin-bottom: 4rem; } }
  @media (min-width: 1200px) {
    .ss-oo .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-oo-imgbox {
  margin: 0 0 3rem 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  @media (min-width: 768px) {
    .ss-oo-imgbox {
      margin-bottom: 0; } }
  .ss-oo-imgbox::before {
    width: 100%;
    padding-top: 94%;
    content: '';
    display: block; }

.ss-oo-list {
  margin: 0;
  padding: 1.3rem 0 0 0;
  list-style: none;
  display: block; }
  .ss-oo-list > li {
    margin: 0 0 2rem 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start; }
    @media (min-width: 1200px) {
      .ss-oo-list > li {
        margin: 0 0 3.2rem 0; } }
    .ss-oo-list > li:last-child {
      margin-bottom: 0; }
  .ss-oo-list-icon {
    margin-right: 1.6rem;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    font-size: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 1200px) {
      .ss-oo-list-icon {
        margin-right: 2.1rem; } }
  .ss-oo-list-desc > h4 {
    margin-bottom: 0.7rem; }
  .ss-oo-list-desc > p {
    color: rgba(34, 34, 34, 0.5); }

.ss-oo.m-b-no-title {
  margin-top: -5rem; }
  @media (min-width: 992px) {
    .ss-oo.m-b-no-title {
      margin-top: -10rem; } }
  @media (min-width: 1200px) {
    .ss-oo.m-b-no-title {
      margin-top: -15rem; } }

.ss-pp-expert {
  padding: 0;
  background: rgba(234, 234, 234, 0.35);
  border-radius: 2rem; }
  .ss-pp-expert > .row {
    flex-direction: column-reverse;
    align-items: center; }
    @media (min-width: 992px) {
      .ss-pp-expert > .row {
        flex-direction: row; } }
  .ss-pp-expert-imgbox {
    margin: 0 auto;
    width: 100%;
    max-width: 46.8rem;
    position: relative;
    display: block; }
    @media (min-width: 992px) {
      .ss-pp-expert-imgbox {
        margin: 0; } }
    .ss-pp-expert-imgbox::before {
      width: 100%;
      padding-top: 116.2393162393162%;
      content: '';
      display: block; }
  .ss-pp-expert-right {
    text-align: center;
    padding: 4rem 2rem; }
    @media (min-width: 992px) {
      .ss-pp-expert-right {
        text-align: left;
        padding: 2rem 2rem 2rem 0; } }
    @media (min-width: 1200px) {
      .ss-pp-expert-right {
        padding-right: 7rem; } }
    .ss-pp-expert-right .badge-row {
      margin-bottom: 2rem; }
    .ss-pp-expert-right .btn {
      min-width: 17.7rem; }

.ss-qq .ss-cc-block {
  padding: 3rem 0; }
  @media (min-width: 992px) {
    .ss-qq .ss-cc-block {
      max-width: 42.6rem;
      padding: 4rem 0; } }
  @media (min-width: 1400px) {
    .ss-qq .ss-cc-block {
      padding: 7.3rem 0; } }

.ss-qq-advising {
  padding: 3rem 2rem;
  background: #eaeaea;
  border-radius: 3rem; }
  @media (min-width: 992px) {
    .ss-qq-advising {
      padding: 3rem; } }
  @media (min-width: 1200px) {
    .ss-qq-advising {
      padding: 4rem; } }
  @media (min-width: 1400px) {
    .ss-qq-advising {
      padding: 6.7rem 7.7rem 5.6rem 7.7rem; } }
  .ss-qq-advising-cell {
    padding: 0.7rem 0.8rem 0.8rem 0.7rem;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
    @media (min-width: 576px) {
      .ss-qq-advising-cell {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%; } }
  .ss-qq-advising-logos {
    display: flex;
    flex-wrap: wrap; }
  .ss-qq-advising-logo {
    margin: 0;
    width: 100%;
    max-width: 100%;
    position: relative;
    display: block;
    background-color: #fff;
    box-shadow: 0px 0.1rem 0.2rem rgba(0, 0, 0, 0.18);
    border-radius: 1rem;
    overflow: hidden; }
    .ss-qq-advising-logo > .lazy-loader {
      padding: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .ss-qq-advising-logo > .lazy-loader > .lazyload {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%; }
    .ss-qq-advising-logo::before {
      width: 100%;
      padding-top: 100%;
      content: '';
      display: block; }
  .ss-qq-advising-btnrow {
    padding-top: 2rem;
    display: flex;
    justify-content: center; }
    @media (min-width: 1400px) {
      .ss-qq-advising-btnrow {
        padding-top: 5.4rem; } }
    .ss-qq-advising-btnrow .btn {
      min-width: 17rem; }

.ss-rr .ss-cc-head {
  text-align: center;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .ss-rr .ss-cc-head {
      margin-bottom: 0; } }

.ss-rr-team-box {
  margin-top: 2rem;
  display: flex;
  align-items: center; }
  @media (min-width: 1200px) {
    .ss-rr-team-box {
      margin-top: 6.4rem; } }

.ss-rr-team-avtar {
  margin-right: 2rem;
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f5f5; }
  @media (min-width: 1200px) {
    .ss-rr-team-avtar {
      width: 8rem;
      height: 8rem; } }
  .ss-rr-team-avtar::before {
    width: 100%;
    padding-top: 100%;
    content: '';
    display: block; }

.ss-rr-team-desc {
  flex: 1; }
  .ss-rr-team-desc > strong {
    font-size: 1.8rem;
    line-height: 1.333;
    font-weight: 600;
    color: #222;
    display: block;
    text-decoration: none; }
    @media (min-width: 1200px) {
      .ss-rr-team-desc > strong {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .ss-rr-team-desc > strong {
        font-size: 2.4rem; } }
  .ss-rr-team-desc > span {
    font-size: 1.4rem;
    line-height: 1.556;
    font-weight: 400;
    color: rgba(34, 34, 34, 0.5);
    display: block;
    text-decoration: none; }
    @media (min-width: 1200px) {
      .ss-rr-team-desc > span {
        font-size: 1.8rem; } }

.ss-ss .ss-cc-head {
  margin-bottom: 5rem;
  text-align: center; }
  @media (min-width: 1200px) {
    .ss-ss .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-ss-coaching-row {
  padding: 0;
  display: flex;
  align-items: center; }
  @media (min-width: 1200px) {
    .ss-ss-coaching-row {
      padding: 4rem 0 2.4rem 0; } }

.ss-ss-coaching-box {
  margin-bottom: 4rem;
  display: flex;
  align-items: center; }
  @media (min-width: 1200px) {
    .ss-ss-coaching-box {
      margin-bottom: 9.6rem; } }
  .ss-ss-coaching-box > h3 {
    margin-bottom: 0; }

.ss-ss-coaching-icon {
  margin-right: 2rem;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1; }
  @media (min-width: 1200px) {
    .ss-ss-coaching-icon {
      margin-right: 3rem;
      width: 7.1rem;
      height: 7.1rem;
      font-size: 4.2rem; } }
  .ss-ss-coaching-icon::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 1rem;
    background-color: #f5f5f5;
    transform: rotate(45deg); }
  .ss-ss-coaching-icon.light-blue::before {
    background-color: rgba(83, 158, 185, 0.1); }
  .ss-ss-coaching-icon.light-green::before {
    background-color: rgba(88, 165, 169, 0.1); }
  .ss-ss-coaching-icon.green::before {
    background-color: rgba(100, 186, 121, 0.1); }

.ss-ss-coaching-text {
  margin-bottom: 0;
  font-size: 2.4rem;
  line-height: 1.333;
  font-weight: 600;
  color: #222;
  text-decoration: none !important; }

.ss-ss-process {
  margin-bottom: 4rem;
  padding: 3rem 3rem 2.2rem 3rem;
  background-color: rgba(234, 234, 234, 0.15);
  border-radius: 2rem; }
  @media (min-width: 1200px) {
    .ss-ss-process {
      padding: 4.1rem 6.8rem 2.2rem 6.8rem; } }

.ss-tt.tt-b .ss-cc-head {
  margin-bottom: 15.9rem; }

.ss-tt-expert-group .ss-tt-expert-row:last-child {
  margin-bottom: 0; }

.ss-tt-expert-row {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .ss-tt-expert-row {
      margin-bottom: 8rem; } }
  @media (min-width: 1200px) {
    .ss-tt-expert-row {
      margin-bottom: 10rem; } }
  @media (min-width: 1400px) {
    .ss-tt-expert-row {
      margin-bottom: 14.9rem;
      padding-top: 0.6rem; } }

.ss-tt-expert-box {
  padding: 2rem 0; }
  @media (min-width: 992px) {
    .ss-tt-expert-box {
      padding: 3rem 0 1.5rem 0; } }
  @media (min-width: 1200px) {
    .ss-tt-expert-box {
      padding: 4rem 0 2rem 0; } }
  @media (min-width: 1400px) {
    .ss-tt-expert-box {
      padding: 5.2rem 0 3.1rem 0; } }
  .ss-tt-expert-box:hover .ss-tt-overlay {
    opacity: 1;
    overflow: visible; }

.ss-tt-expert-imgwrap {
  position: relative;
  cursor: pointer; }

.ss-tt-expert-imgbox {
  margin-bottom: 2.3rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-tt-expert-imgbox::before {
    width: 100%;
    padding-top: 100%;
    content: '';
    display: block; }

.ss-tt-expert-category {
  margin-bottom: 0;
  font-size: 1.8rem;
  line-height: 1.45;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.5);
  display: block; }

@media (min-width: 768px) {
  .ss-tt-expert-info > div {
    min-height: 12rem; } }

.ss-tt-expert-info .h4 {
  margin-bottom: 1rem; }

.ss-tt-expert-info hr {
  margin: 1.5rem 0; }

.ss-tt-overlay {
  width: 100%;
  height: 100%;
  padding: 2rem;
  top: 0rem;
  left: 0rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  border-radius: 2rem;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }

.ss-tt-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex; }
  .ss-tt-social > li {
    margin: 0.6rem;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-tt-social > li > a {
      width: 4rem;
      height: 4rem;
      font-size: 3.3rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
      .ss-tt-social > li > a:hover {
        color: #6cceff; }

.ss-tt-readlink {
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 1.429;
  font-weight: 600;
  color: #539cc1;
  display: block; }

.ss-uu-back-row {
  padding: 5rem 0; }
  @media (min-width: 992px) {
    .ss-uu-back-row {
      padding: 6.2rem 0 5.6rem 0; } }
  .ss-uu-back-row .btn > i {
    color: #539cc1; }

@media (min-width: 992px) {
  .ss-uu-bio {
    padding-right: 1.5rem; } }

.ss-uu-bio h2 {
  margin-bottom: 0.7rem;
  color: #539cc1; }

.ss-uu-bio p {
  margin-bottom: 3rem; }

.ss-uu-bio-left {
  margin-bottom: 3rem;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 768px) {
    .ss-uu-bio-left {
      float: left;
      width: calc(30% - 3rem);
      max-width: 30%;
      flex: 0 0 30%;
      margin-right: 3rem; } }
  @media (min-width: 1200px) {
    .ss-uu-bio-left {
      width: calc(30% - 7rem);
      margin-right: 7rem; } }
  .ss-uu-bio-left .ss-ll-nav {
    padding-top: 0.8rem; }

.ss-uu-bio-right {
  margin-bottom: 3rem;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 768px) {
    .ss-uu-bio-right {
      float: left;
      width: 70%;
      max-width: 70%;
      flex: 0 0 70%; } }

.ss-uu-bio-imgbox {
  margin: 0 0 1.3rem 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-uu-bio-imgbox::before {
    width: 100%;
    padding-top: 97.16666666666667%;
    content: '';
    display: block; }

.ss-uu-bio-social {
  margin: 0 0rem 2.6rem 0rem;
  padding: 0;
  list-style: none;
  display: flex; }
  @media (min-width: 1200px) {
    .ss-uu-bio-social {
      padding-top: 2.3rem; } }
  .ss-uu-bio-social > li {
    margin: 0.7rem 0.8rem 0.8rem 0.7rem;
    padding: 0;
    list-style: none;
    display: block; }
    .ss-uu-bio-social > li:first-child {
      margin-left: 0; }
    .ss-uu-bio-social > li > a {
      width: 5.1rem;
      height: 5.1rem;
      font-size: 2.6rem;
      color: #222;
      background-color: #f5f5f5;
      border-radius: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
      .ss-uu-bio-social > li > a:hover {
        color: #fff;
        background-color: #222; }

.ss-vv-box {
  padding: 4rem 0 3rem 0;
  background: rgba(234, 234, 234, 0.35);
  border-radius: 2rem; }
  @media (min-width: 992px) {
    .ss-vv-box {
      padding: 5rem 0 3rem 0; } }
  @media (min-width: 1200px) {
    .ss-vv-box {
      padding: 7.4rem 0 5.1rem 0; } }
  .ss-vv-box > .h4 {
    margin-bottom: 3rem;
    text-align: center; }
    @media (min-width: 992px) {
      .ss-vv-box > .h4 {
        margin-bottom: 4rem; } }
    @media (min-width: 1200px) {
      .ss-vv-box > .h4 {
        margin-bottom: 5.4rem; } }

.ss-vv-slider {
  border-radius: 2rem; }
  .ss-vv-slider .ss-tt-expert-box {
    padding-top: 0; }
  .ss-vv-slider-wrap {
    padding: 0 4rem;
    position: relative; }
    @media (min-width: 1200px) {
      .ss-vv-slider-wrap {
        padding: 0 10rem; } }
    @media (min-width: 1400px) {
      .ss-vv-slider-wrap {
        padding: 0 13rem; } }
  .ss-vv-slider-arrow {
    width: 4rem;
    font-size: 2.6rem;
    height: 27.2rem;
    top: 0;
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222; }
    @media (min-width: 1200px) {
      .ss-vv-slider-arrow {
        width: 7.4rem;
        background-color: rgba(178, 178, 179, 0.15); } }
    @media (min-width: 1400px) {
      .ss-vv-slider-arrow {
        width: 8.2rem; } }
    .ss-vv-slider-arrow.next {
      border-radius: 1.9rem 0 0 1.9rem;
      right: 0rem; }
    .ss-vv-slider-arrow.prev {
      border-radius: 0 1.9rem 1.9rem 0;
      left: 0rem; }

.ss-ww .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .ss-ww .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-ww-clients > .col,
.ss-ww-clients > [class*=col-] {
  margin: 1.5rem 0; }

.ss-ww-clients-imgbox {
  margin: 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  background-color: #f6f6f6; }
  .ss-ww-clients-imgbox > .lazy-loader {
    padding: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ss-ww-clients-imgbox > .lazy-loader > .lazyload {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; }
  .ss-ww-clients-imgbox::before {
    width: 100%;
    padding-top: 100%;
    content: '';
    display: block; }

.ss-xx-additional {
  margin: 0 -1.5rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .ss-xx-additional > li {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 0 1.5rem 0;
    padding: 0 1.5rem;
    display: block;
    list-style: none; }
    @media (min-width: 576px) {
      .ss-xx-additional > li {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%; } }
    @media (min-width: 992px) {
      .ss-xx-additional > li {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%; } }
    @media (min-width: 1400px) {
      .ss-xx-additional > li {
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%; } }
  .ss-xx-additional-lbl {
    font-size: 1.8rem;
    line-height: 1.556em;
    font-weight: normal;
    color: #404041 !important;
    text-decoration: none !important;
    display: block; }

.ss-yy .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .ss-yy .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-yy-slider {
  padding-bottom: 12.6rem; }
  .ss-yy-slider .swiper-slide {
    height: auto; }
  .ss-yy-slider .swiper-pagination-bullets {
    bottom: 3.6rem; }
  .ss-yy-slider .swiper-pagination-bullet {
    margin: 0 !important;
    padding: 0.8rem;
    width: auto;
    height: auto;
    background-color: transparent;
    opacity: 1; }
    .ss-yy-slider .swiper-pagination-bullet::before {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      content: '';
      background-color: #efefef;
      border-radius: 50%; }
    .ss-yy-slider .swiper-pagination-bullet-active::before {
      background-color: #539cc1; }
  .ss-yy-slider-box {
    padding: 1.6rem 1.1rem 1.6rem 1.6rem;
    background-color: rgba(234, 234, 234, 0.15);
    border-radius: 2rem;
    height: 100%; }
    @media (min-width: 992px) {
      .ss-yy-slider-box {
        min-height: 26.5rem;
        padding: 2.2rem 1.1rem 2.9rem 2.2rem; } }
    .ss-yy-slider-box-head {
      display: flex;
      align-items: center; }
      .ss-yy-slider-box-head-r {
        flex: 1; }
    .ss-yy-slider-box-desc {
      margin-bottom: 2.5rem; }
  .ss-yy-slider-user-expert {
    margin-right: 1.4rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: background-color 0.25s, color 0.25s;
    overflow: hidden;
    position: relative; }
    @media (min-width: 992px) {
      .ss-yy-slider-user-expert {
        width: 7rem;
        height: 7rem; } }
    .ss-yy-slider-user-expert::before {
      width: 100%;
      padding-top: 100%;
      content: '';
      display: block; }
  .ss-yy-slider-expert-name {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    color: #222;
    text-transform: uppercase;
    display: block; }
  .ss-yy-slider-expert-category {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: #222;
    display: block; }

.ss-zz-slider {
  padding-bottom: 5.9rem; }
  .ss-zz-slider-row {
    align-items: center; }
  .ss-zz-slider-wrap {
    max-width: 108.8rem;
    margin: 0 auto; }
  .ss-zz-slider .swiper-slide {
    height: auto; }
  .ss-zz-slider-controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .ss-zz-slider-controls {
        padding-top: 2.4rem; } }
  .ss-zz-slider-arrow {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0.8rem 1.2rem;
    display: flex; }
  .ss-zz-slider .swiper-pagination-bullets {
    position: static;
    display: flex; }
  .ss-zz-slider .swiper-pagination-bullet {
    margin: 0 !important;
    padding: 1rem;
    width: auto;
    height: auto;
    background-color: transparent;
    opacity: 1; }
    .ss-zz-slider .swiper-pagination-bullet::before {
      width: 1rem;
      height: 1rem;
      display: block;
      content: '';
      background-color: #d8d8d8;
      border-radius: 50%; }
    .ss-zz-slider .swiper-pagination-bullet-active::before {
      background-color: #539cc1; }
  .ss-zz-slider-left {
    padding: 3rem 2rem;
    border-radius: 2rem;
    position: relative;
    background-color: #f9fafa; }
    @media (min-width: 1200px) {
      .ss-zz-slider-left {
        max-width: 56.8rem;
        padding: 6.9rem 5rem 6.4rem 5rem; } }
    .ss-zz-slider-left-inr {
      max-width: 28.3rem;
      position: relative; }
  .ss-zz-slider-right {
    padding: 4rem 0; }
    @media (min-width: 1200px) {
      .ss-zz-slider-right {
        padding-left: 5.5rem; } }
  .ss-zz-slider-box-bg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.35;
    pointer-events: none;
    z-index: 0; }

.ss-zz-technology {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none; }
  .ss-zz-technology > li {
    margin: 0 1.4rem 0.5rem 0;
    padding: 0;
    display: block;
    list-style: none; }
  .ss-zz-technology-icon {
    margin-bottom: 0.5rem;
    width: 4.8rem;
    height: 4.8rem;
    font-size: 4.8rem;
    border-radius: 1rem;
    background-color: #fff;
    filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.0702579));
    display: flex;
    align-items: center;
    justify-content: center; }
  .ss-zz-technology-text {
    font-size: 1rem;
    line-height: 1.3rem;
    color: #222;
    text-align: center;
    display: block; }

.ss-aaa .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 1200px) {
    .ss-aaa .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-aaa-cp-row {
  margin-bottom: 7rem; }
  .ss-aaa-cp-row .col,
  .ss-aaa-cp-row [class*=col-] {
    margin: 1.5rem 0; }

.ss-aaa-cp-box {
  height: 100%;
  padding: 1.6rem;
  background: #f9f9f9;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none !important; }
  @media (min-width: 1400px) {
    .ss-aaa-cp-box {
      padding: 2.5rem 1.9rem 2.5rem 2.3rem; } }
  .ss-aaa-cp-box.active {
    background: #fff;
    box-shadow: 0px 0.8rem 3rem rgba(0, 0, 0, 0.18); }

.ss-aaa-cp-icon {
  flex-shrink: 0;
  margin-right: 1.6rem;
  width: 5.2rem;
  height: 5.2rem;
  font-size: 3.2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 0.6rem; }
  @media (min-width: 1400px) {
    .ss-aaa-cp-icon {
      margin-right: 2.3rem; } }

.ss-aaa-cp-desc {
  flex: 1; }
  .ss-aaa-cp-desc > p {
    color: #404041 !important;
    font-weight: 600; }

.ss-aaa-dicision-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .ss-aaa-dicision-row {
      flex-direction: row; } }
  .ss-aaa-dicision-row .btn-spacer {
    padding-top: 3rem; }
    .ss-aaa-dicision-row .btn-spacer > .btn {
      min-width: 13rem;
      margin-right: 0.5rem; }
      @media (min-width: 992px) {
        .ss-aaa-dicision-row .btn-spacer > .btn {
          margin-right: 2.7rem;
          min-width: 15.6rem; } }

.ss-aaa-dicision-left {
  margin-bottom: 4rem;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 992px) {
    .ss-aaa-dicision-left {
      margin-bottom: 0;
      width: 42.73504273504274%;
      max-width: 42.73504273504274%;
      flex: 0 0 42.73504273504274%; } }

.ss-aaa-dicision-right {
  flex: 1; }
  @media (min-width: 992px) {
    .ss-aaa-dicision-right {
      padding-left: 3rem; } }
  @media (min-width: 1200px) {
    .ss-aaa-dicision-right {
      padding-left: 6.6rem; } }
  .ss-aaa-dicision-right a {
    margin-right: 2rem; }

.ss-aaa-dicision-imgbox {
  margin: 0;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  background-color: #f5f5f5;
  border: 0.1rem solid #ebebeb;
  border-radius: 2rem;
  overflow: hidden; }
  .ss-aaa-dicision-imgbox::before {
    width: 100%;
    padding-top: 87.2%;
    content: '';
    display: block; }

.ss-aaa-list {
  margin: 0 -1rem;
  padding: 0 0 3rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .ss-aaa-list li::before {
    display: none; }
  .ss-aaa-list-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    font-size: 1.8rem;
    color: #222; }
    @media (min-width: 768px) {
      .ss-aaa-list-box {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%; } }
    @media (min-width: 992px) {
      .ss-aaa-list-box {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
        padding: 1rem; } }
    @media (min-width: 1400px) {
      .ss-aaa-list-box {
        padding: 1.7rem 1rem; } }

.ss-aaa-check-icon {
  margin-top: 0.1rem;
  margin-right: 1.7rem;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.ss-bbb .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 1200px) {
    .ss-bbb .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-bbb-slider-wrap {
  margin-top: 8rem;
  position: relative; }
  @media (min-width: 1200px) {
    .ss-bbb-slider-wrap {
      margin-top: 0; } }

.ss-bbb-slider-box-bg {
  display: block;
  width: 100%;
  max-width: 52.7rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.15;
  pointer-events: none;
  z-index: 0; }
  @media (min-width: 768px) {
    .ss-bbb-slider-box-bg {
      opacity: 0.35; } }
  @media (min-width: 1200px) {
    .ss-bbb-slider-box-bg {
      opacity: 1; } }

.ss-bbb-slider-left {
  margin-bottom: 3rem;
  padding: 3rem;
  border-radius: 2rem;
  position: relative; }
  @media (min-width: 768px) {
    .ss-bbb-slider-left {
      padding: 5rem; } }
  @media (min-width: 992px) {
    .ss-bbb-slider-left {
      margin-bottom: 0; } }
  .ss-bbb-slider-left .ss-cc-block > h3 {
    margin-bottom: 2rem; }
  .ss-bbb-slider-left-inr {
    max-width: 33.5rem; }

.ss-bbb-slider-right {
  height: 100%;
  padding: 3rem;
  background: rgba(234, 234, 234, 0.15);
  border-radius: 2rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .ss-bbb-slider-right {
      padding: 5rem 2rem 3.5rem 2rem; } }
  @media (min-width: 1200px) {
    .ss-bbb-slider-right {
      padding: 7.9rem 3rem 3.5rem 3rem; } }
  @media (min-width: 1200px) {
    .ss-bbb-slider-right .ss-cc-desc p {
      font-size: 2.4rem; } }
  .ss-bbb-slider-right .btn-spacer {
    margin-top: auto; }
    .ss-bbb-slider-right .btn-spacer .btn {
      margin: 0 auto;
      width: 100%;
      max-width: 37rem; }

.ss-bbb-slider-arrow {
  z-index: 11;
  margin-top: -5rem;
  top: 0;
  width: 5rem;
  height: 5rem;
  font-size: 2.6rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 992px) {
    .ss-bbb-slider-arrow {
      top: 50%;
      margin-top: -2.5rem; } }
  .ss-bbb-slider-arrow.prev {
    left: 40%;
    margin-left: -5rem; }
    @media (min-width: 992px) {
      .ss-bbb-slider-arrow.prev {
        left: -3.2rem;
        margin-left: 0; } }
    @media (min-width: 1200px) {
      .ss-bbb-slider-arrow.prev {
        left: -5.2rem; } }
    @media (min-width: 1367px) {
      .ss-bbb-slider-arrow.prev {
        left: -9.2rem; } }
  .ss-bbb-slider-arrow.next {
    right: 40%;
    margin-right: -5rem; }
    @media (min-width: 992px) {
      .ss-bbb-slider-arrow.next {
        right: -3.2rem;
        margin-right: 0; } }
    @media (min-width: 1200px) {
      .ss-bbb-slider-arrow.next {
        right: -5.2rem; } }
    @media (min-width: 1367px) {
      .ss-bbb-slider-arrow.next {
        right: -9.2rem; } }

.ss-bbb-list {
  margin: 0;
  padding: 2.5rem 0 3.6rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .ss-bbb-list li::before {
    display: none; }
  .ss-bbb-list-box {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
    padding: 0.7rem 0 0.8rem 0;
    position: relative;
    display: flex;
    font-size: 1.6rem;
    line-height: 1.75;
    font-weight: 600; }

.ss-bbb-check-icon {
  margin-top: 0.1rem;
  margin-right: 1.7rem;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.ss-bbb-eda-logo {
  margin: 0 auto 3rem auto;
  width: 100%;
  max-width: 16.9rem;
  position: relative;
  display: block; }
  @media (min-width: 992px) {
    .ss-bbb-eda-logo {
      margin-bottom: 6.5rem; } }
  .ss-bbb-eda-logo::before {
    width: 100%;
    padding-top: 18.93491124260355%;
    content: '';
    display: block; }

.ss-bbb-thing-text {
  font-size: 1.8rem;
  line-height: 1.333;
  color: #222; }
  @media (min-width: 1200px) {
    .ss-bbb-thing-text {
      font-size: 2.4rem; } }

.ss-ccc {
  flex-direction: column; }
  .ss-ccc-sec-a {
    padding: 9.5rem 0 6.8rem 0; }
    .ss-ccc-sec-a .btn-spacer {
      margin-top: 3.7rem; }
  .ss-ccc-eda-logo {
    margin: 0 auto 3rem auto;
    width: 100%;
    max-width: 16.9rem;
    position: relative;
    display: block; }
    @media (min-width: 992px) {
      .ss-ccc-eda-logo {
        margin-bottom: 5.6rem; } }
    .ss-ccc-eda-logo::before {
      width: 100%;
      padding-top: 18.93491124260355%;
      content: '';
      display: block; }
  .ss-ccc-sec-b {
    width: 100%;
    position: relative; }
    .ss-ccc-sec-b-bg {
      transform: translateY(-50%);
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      display: block; }
      .ss-ccc-sec-b-bg-one {
        width: 100%;
        height: 2rem;
        display: block; }
        @media (min-width: 992px) {
          .ss-ccc-sec-b-bg-one {
            height: 4.8rem; } }
      .ss-ccc-sec-b-bg-two {
        width: 100%;
        height: 6.6rem;
        display: block; }
        @media (min-width: 992px) {
          .ss-ccc-sec-b-bg-two {
            height: 13.3rem; } }
    .ss-ccc-sec-b-imgbox {
      margin: 0 auto;
      width: 100%;
      max-width: 97rem;
      position: relative;
      display: block; }
      .ss-ccc-sec-b-imgbox::before {
        width: 100%;
        padding-top: 57.62886597938144%;
        content: '';
        display: block; }

.ss-ddd-platform {
  margin: 5rem 0;
  background: rgba(234, 234, 234, 0.25);
  border-radius: 2rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center; }
  @media (min-width: 992px) {
    .ss-ddd-platform {
      margin: 10rem 0;
      flex-direction: row; } }
  @media (min-width: 1200px) {
    .ss-ddd-platform {
      margin: 14rem 0; } }
  .ss-ddd-platform-group .ss-ddd-platform:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .ss-ddd-platform.reverse {
      flex-direction: row-reverse; } }
  .ss-ddd-platform.reverse .ss-ddd-platform-box-pattern {
    left: auto;
    right: -0.8rem; }
    @media (min-width: 992px) {
      .ss-ddd-platform.reverse .ss-ddd-platform-box-pattern {
        right: -1.2rem; } }
  .ss-ddd-platform-left {
    padding: 3rem; }
    @media (min-width: 768px) {
      .ss-ddd-platform-left {
        padding: 3rem 4rem; } }
    @media (min-width: 992px) {
      .ss-ddd-platform-left {
        flex: 1; } }
    @media (min-width: 1200px) {
      .ss-ddd-platform-left {
        padding: 3.8rem 7.1rem; } }
    .ss-ddd-platform-left .ss-aaa-list {
      padding: 1.9rem 0 0 0; }
    .ss-ddd-platform-left .ss-aaa-list-box {
      padding: 0.7rem 1rem 0.8rem 1rem; }
  .ss-ddd-platform-right {
    padding: 3rem 3rem 0 3rem;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    position: relative; }
    @media (min-width: 992px) {
      .ss-ddd-platform-right {
        padding: 4rem 4rem 0 4rem; } }
    @media (min-width: 992px) {
      .ss-ddd-platform-right {
        padding: 0;
        width: 38.2rem;
        max-width: 38.2rem;
        flex: 0 0 38.2rem; } }
    @media (min-width: 1200px) {
      .ss-ddd-platform-right {
        width: 51.2rem;
        max-width: 51.2rem;
        flex: 0 0 51.2rem; } }
  .ss-ddd-platform-box-pattern {
    width: 100%;
    top: 1.5rem;
    bottom: 1.5rem;
    left: -0.8rem;
    position: absolute;
    border-radius: 2rem; }
    @media (min-width: 992px) {
      .ss-ddd-platform-box-pattern {
        left: -1.2rem; } }
  .ss-ddd-platform-imgwrap {
    position: relative; }
    @media (min-width: 992px) {
      .ss-ddd-platform-imgwrap {
        margin: -2.5rem 0; } }
  .ss-ddd-platform-imgbox {
    width: 100%;
    max-width: 100%;
    position: relative;
    display: block;
    background-color: #f5f5f5;
    border: 0.1rem solid #dadada;
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer; }
    .ss-ddd-platform-imgbox::before {
      width: 100%;
      padding-top: 78.125%;
      content: '';
      display: block; }
    .ss-ddd-platform-imgbox:hover .ss-ddd-platform-overlay {
      opacity: 1;
      visibility: visible; }
  .ss-ddd-platform-overlay {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
    -o-transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s;
    transition: all 0.2s cubic-bezier(0.42, 0, 1, 1) 0s; }
    .ss-ddd-platform-overlay .btn {
      min-width: 17rem; }

.ss-eee .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 1200px) {
    .ss-eee .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-eee-template-mainbox {
  padding: 2rem 3rem;
  border-radius: 2rem;
  background-color: #f5f5f5; }
  @media (min-width: 992px) {
    .ss-eee-template-mainbox {
      padding: 5rem 4.8rem; } }
  .ss-eee-template-mainbox .col,
  .ss-eee-template-mainbox [class*=col-] {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }

.ss-eee-template-box {
  padding: 0.9rem 1.4rem 3.2rem 1.4rem;
  height: 100%;
  background-color: #fff;
  border-radius: 1rem;
  text-align: center;
  cursor: pointer; }
  .ss-eee-template-box:hover {
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.18); }
    .ss-eee-template-box:hover .btn-light {
      color: #fff !important;
      background-color: #222 !important;
      border-color: #222 !important; }
  .ss-eee-template-box .h4 {
    font-size: 1.8rem; }

.ss-eee-template-imgbox {
  margin: 0 auto 0.4rem auto;
  width: 100%;
  max-width: 21rem;
  position: relative;
  display: block; }
  .ss-eee-template-imgbox::before {
    width: 100%;
    padding-top: 71.42857142857143%;
    content: '';
    display: block; }

.ss-eee-template-body .btn-spacer {
  padding-top: 1.1rem; }
  @media (min-width: 1200px) {
    .ss-eee-template-body .btn-spacer .btn {
      min-width: 18rem; } }

.ss-fff .ss-cc-head {
  margin-bottom: 5rem; }
  @media (min-width: 1200px) {
    .ss-fff .ss-cc-head {
      margin-bottom: 6.6rem; } }

.ss-fff-report {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .ss-fff-report {
      flex-direction: row; } }
  .ss-fff-report-left {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 992px) {
      .ss-fff-report-left {
        width: 40rem;
        max-width: 40rem;
        flex: 0 0 40rem; } }
    @media (min-width: 1200px) {
      .ss-fff-report-left {
        width: 50rem;
        max-width: 50rem;
        flex: 0 0 50rem; } }
  .ss-fff-report-right {
    flex: 1;
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .ss-fff-report-right {
        padding-left: 3rem; } }
    @media (min-width: 1400px) {
      .ss-fff-report-right {
        padding-left: 6.9rem; } }
  .ss-fff-report-imgbox {
    margin: 0 auto 4rem auto;
    width: 100%;
    max-width: 100%;
    position: relative;
    display: block;
    border-radius: 2rem;
    overflow: hidden;
    background-color: #f5f5f5;
    border: 0.1rem solid #d7d7d7; }
    @media (min-width: 992px) {
      .ss-fff-report-imgbox {
        margin-bottom: 0; } }
    .ss-fff-report-imgbox::before {
      width: 100%;
      padding-top: 81.8%;
      content: '';
      display: block; }
  .ss-fff-report-tabs {
    margin: 0 -1.5rem 1rem -1.5rem;
    flex-wrap: wrap; }
    @media (min-width: 1200px) {
      .ss-fff-report-tabs {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1400px) {
      .ss-fff-report-tabs {
        margin-bottom: 3.5rem; } }
    .ss-fff-report-tabs .nav-item {
      margin: 0 1rem 1.5rem 1rem; }
      @media (min-width: 1400px) {
        .ss-fff-report-tabs .nav-item {
          margin: 0 1.5rem 1.5rem 1.5rem; } }
    .ss-fff-report-tabs .nav-link {
      padding: 1.2rem 1.5rem;
      flex-shrink: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.5;
      text-transform: uppercase;
      color: #222;
      border-radius: 1rem;
      background-color: #f5f5f5;
      border: none;
      outline: none; }
      @media (min-width: 1200px) {
        .ss-fff-report-tabs .nav-link {
          padding: 1.4rem 1rem;
          width: 18rem;
          font-size: 1.6rem; } }
      @media (min-width: 1400px) {
        .ss-fff-report-tabs .nav-link {
          padding: 1.8rem 1rem; } }
      .ss-fff-report-tabs .nav-link.active {
        background-color: #539cc1;
        color: #fff; }
  .ss-fff-report-pane > .btn-spacer {
    padding-top: 1rem; }
    @media (min-width: 1200px) {
      .ss-fff-report-pane > .btn-spacer {
        padding-top: 2rem; } }
    @media (min-width: 1400px) {
      .ss-fff-report-pane > .btn-spacer {
        padding-top: 4.6rem; } }
    .ss-fff-report-pane > .btn-spacer > .btn {
      min-width: 20rem; }

.ss-ggg .ss-section-bg {
  background-color: #539cc1; }
  .ss-ggg .ss-section-bg::after {
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(49, 144, 193, 0.896361), rgba(49, 144, 193, 0.896361)); }

.ss-ggg .ss-section-inner {
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media (min-width: 768px) {
    .ss-ggg .ss-section-inner {
      padding-top: 8rem;
      padding-bottom: 10.5rem; } }
  @media (min-width: 1200px) {
    .ss-ggg .ss-section-inner {
      padding: 12.2rem 0 12.9rem 0; } }

.ss-ggg-content {
  max-width: 77rem;
  margin: 0 auto; }
  .ss-ggg-content .ss-icon-quote-left {
    font-size: 5.2rem;
    margin-bottom: 1.9rem; }
    @media (min-width: 768px) {
      .ss-ggg-content .ss-icon-quote-left {
        font-size: 7.7rem; } }
  .ss-ggg-content .blockquote {
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: 600;
    margin: 0; }
    @media (min-width: 768px) {
      .ss-ggg-content .blockquote {
        font-size: 3.2rem; } }
  .ss-ggg-content .blockquote-footer {
    font-size: 1.6rem;
    line-height: 1.33;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-top: 3.2rem; }
    @media (min-width: 1200px) {
      .ss-ggg-content .blockquote-footer {
        margin-top: 6.2rem; } }
    .ss-ggg-content .blockquote-footer::before {
      display: none; }
    .ss-ggg-content .blockquote-footer cite {
      font-size: 1.4rem;
      line-height: 1.556;
      display: block;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      text-transform: none;
      font-style: normal;
      margin-top: 0; }
      @media (min-width: 768px) {
        .ss-ggg-content .blockquote-footer cite {
          font-size: 1.6rem; } }
      @media (min-width: 768px) {
        .ss-ggg-content .blockquote-footer cite {
          font-size: 1.8rem; } }

.ss-hhh-l {
  margin-bottom: 5rem; }
  @media (min-width: 768px) {
    .ss-hhh-l .ss-cc-block {
      max-width: 27rem; } }
  .ss-hhh-l .ss-cc-btns {
    margin: 0; }
    .ss-hhh-l .ss-cc-btns .btn {
      margin: 0; }
  .ss-hhh-l .ss-v-subscribe-box {
    margin-top: 5rem; }
    @media (min-width: 768px) {
      .ss-hhh-l .ss-v-subscribe-box {
        max-width: 27rem; } }

.ss-iii {
  background-color: rgba(83, 156, 193, 0.1); }
  .ss-iii-row {
    display: flex;
    align-items: center;
    padding-top: 3rem; }
    @media (max-width: 767px) {
      .ss-iii-row {
        flex-wrap: wrap; } }
  .ss-iii-l {
    width: 1%;
    flex: 1 1 auto;
    margin-right: 3rem; }
    @media (max-width: 767px) {
      .ss-iii-l {
        width: 100%;
        margin-right: 0;
        margin-bottom: 3rem; } }
    .ss-iii-l .form-group {
      max-width: 61rem;
      margin-bottom: 0; }
  .ss-iii-r {
    width: 33.5rem;
    max-width: 100%;
    flex-shrink: 0; }
    @media (max-width: 767px) {
      .ss-iii-r {
        width: 100%; } }
  .ss-iii-img {
    display: block;
    width: 100%;
    max-width: 33.5rem;
    position: relative;
    margin: auto; }
    .ss-iii-img::before {
      width: 100%;
      display: block;
      content: '';
      padding-top: 106.86%; }

.ss-jjj-l {
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    .ss-jjj-l {
      margin-bottom: 0; } }

.ss-jjj-r {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .ss-jjj-r {
      align-items: flex-end; } }
  @media (min-width: 768px) {
    .ss-jjj-r .ss-v-subscribe-box {
      max-width: 27rem; } }

.ss-jjj-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  @media (min-width: 1200px) {
    .ss-jjj-links {
      padding-right: 6rem; } }
  .ss-jjj-links > :last-child {
    margin-bottom: 0; }
  .ss-jjj-links > a {
    margin-bottom: 1.6rem; }

.ss-kkk .tab-pane > :last-child {
  margin-bottom: 0; }

.ss-kkk ul.checklist.two-column {
  column-count: 1;
  column-gap: 1rem; }
  @media (min-width: 768px) {
    .ss-kkk ul.checklist.two-column {
      column-count: 2; } }
  .ss-kkk ul.checklist.two-column > li {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    min-height: 2.8rem; }

.ss-kkk ul.checklist.three-column {
  column-count: 1;
  column-gap: 1rem; }
  @media (min-width: 768px) {
    .ss-kkk ul.checklist.three-column {
      column-count: 2; } }
  @media (min-width: 1200px) {
    .ss-kkk ul.checklist.three-column {
      column-count: 3; } }
  .ss-kkk ul.checklist.three-column > li {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    min-height: 2.8rem; }

.ss-kkk-maintab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4.2rem 0 5rem 0; }
  @media (min-width: 1200px) {
    .ss-kkk-maintab {
      margin: 4.2rem 0 6.9rem 0; } }
  @media (max-width: 575px) {
    .ss-kkk-maintab > li {
      width: 100%;
      flex: 0 0 100%; } }
  .ss-kkk-maintab > li > a {
    font-size: 1.6rem;
    font-weight: 600;
    display: block;
    padding: 1.2rem 2rem;
    min-width: 18.6rem;
    border: 1px solid #ddd;
    border-radius: 0.3rem;
    color: #222 !important;
    text-decoration: none !important;
    position: relative;
    margin: 0 -0.3rem;
    background-color: #fff;
    text-align: center; }
    @media (max-width: 575px) {
      .ss-kkk-maintab > li > a {
        margin: 0.3rem; } }
    .ss-kkk-maintab > li > a.active {
      background-color: #222;
      border-color: #222;
      color: #fff !important;
      z-index: 10; }

.ss-kkk-odcp {
  margin: 5rem 0; }
  @media (min-width: 1200px) {
    .ss-kkk-odcp {
      margin: 6.9rem 0; } }
  .ss-kkk-odcp-l-desc {
    max-width: 16rem; }

.ss-kkk-box {
  border: 1px solid #ddd;
  border-radius: 2rem;
  padding: 4rem; }

.ss-kkk-lp {
  margin: 5rem 0; }
  @media (min-width: 1200px) {
    .ss-kkk-lp {
      margin: 6.9rem 0; } }
  .ss-kkk-lp > .h4 {
    margin-bottom: 5rem; }
    @media (min-width: 1200px) {
      .ss-kkk-lp > .h4 {
        margin: 6.9rem 0; } }
  @media (min-width: 992px) {
    .ss-kkk-lp-l-desc {
      max-width: 20rem; } }
  .ss-kkk-lp-tabnav {
    display: flex;
    margin: 0 -1.5rem 0 -1.5rem;
    padding: 0;
    list-style: none;
    flex-wrap: wrap; }
    @media (max-width: 991px) {
      .ss-kkk-lp-tabnav {
        margin: 0 -1rem 0 -1rem; } }
    .ss-kkk-lp-tabnav > li {
      width: 16.66%;
      flex: 0 0 16.66%;
      max-width: 16.66%;
      list-style: none;
      margin: 0 0 4.3rem 0;
      padding: 0 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 991px) {
        .ss-kkk-lp-tabnav > li {
          padding: 0 1rem; } }
      @media (max-width: 767px) {
        .ss-kkk-lp-tabnav > li {
          margin: 0 0 3rem 0; } }
      @media (max-width: 991px) {
        .ss-kkk-lp-tabnav > li:first-child {
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%; } }
      @media (max-width: 991px) {
        .ss-kkk-lp-tabnav > li:not(:first-child) {
          width: 20%;
          flex: 0 0 20%;
          max-width: 20%; } }
      @media (max-width: 767px) {
        .ss-kkk-lp-tabnav > li:not(:first-child) {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%; } }
  .ss-kkk-lp-tabitem {
    padding: 0.9rem 0.9rem 2rem 0.9rem;
    border: 1px solid #ddd;
    border-radius: 1rem;
    color: inherit !important;
    text-decoration: none !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; }
    .ss-kkk-lp-tabitem.active {
      border-color: #539cc1;
      box-shadow: 0 0 0 0.1rem #539cc1;
      background-color: rgba(83, 156, 193, 0.05); }
      .ss-kkk-lp-tabitem.active .ss-kkk-lp-dot .ss-icon-circle-fill {
        display: block; }
      .ss-kkk-lp-tabitem.active .ss-kkk-lp-arrow {
        display: block; }
    .ss-kkk-lp-tabitem .h4 {
      margin: 0; }
    .ss-kkk-lp-tabitem small {
      font-size: 1.4rem;
      font-weight: 400;
      display: block; }
  .ss-kkk-lp-dot {
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #ccc;
    color: #539cc1;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-top: 1rem; }
    .ss-kkk-lp-dot .ss-icon-circle-fill {
      display: none; }
  .ss-kkk-lp-arrow {
    width: 0;
    height: 0;
    border-left: 1.3rem solid transparent;
    border-right: 1.3rem solid transparent;
    border-top: 1.3rem solid #539cc1;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    margin-top: 0.1rem; }
  .ss-kkk-lp-sep {
    background-color: #979797;
    opacity: 0.5;
    margin: 5rem 0; }
  .ss-kkk-lp-ao-c {
    margin-top: 3.6rem; }
    .ss-kkk-lp-ao-c > :last-child {
      margin-bottom: 0 !important; }
    .ss-kkk-lp-ao-c:nth-child(1) {
      margin-top: 0; }
    .ss-kkk-lp-ao-c:nth-child(2) {
      margin-top: 3.6rem; }
      @media (min-width: 768px) {
        .ss-kkk-lp-ao-c:nth-child(2) {
          margin-top: 0; } }
    .ss-kkk-lp-ao-c-head {
      font-size: 1.6rem;
      margin-bottom: 2.6rem; }
      @media (min-width: 768px) {
        .ss-kkk-lp-ao-c-head {
          font-size: 1.8rem; } }
    .ss-kkk-lp-ao-c .ss-checkradio {
      padding: 1rem 2rem;
      border: 1px solid #ccc;
      border-radius: 1rem;
      margin-bottom: 2rem;
      align-items: center;
      min-height: 7.4rem; }
    .ss-kkk-lp-ao-c .ss-checkradio-text {
      margin-left: 2rem; }

@media (max-width: 767px) {
  .ss-kkk-salesform > .row > .col {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.ss-kkk-sol-tabnav {
  display: flex;
  margin: 0 -1.5rem 0 -1.5rem;
  padding: 0;
  list-style: none;
  flex-wrap: wrap; }
  @media (max-width: 991px) {
    .ss-kkk-sol-tabnav {
      margin: 0 -1rem 0 -1rem; } }
  .ss-kkk-sol-tabnav > li {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    list-style: none;
    margin: 0 0 6rem 0;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 991px) {
      .ss-kkk-sol-tabnav > li {
        padding: 0 1rem; } }
    @media (max-width: 767px) {
      .ss-kkk-sol-tabnav > li {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 3rem 0; } }

.ss-kkk-sol-tabitem {
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 4rem;
  color: inherit !important;
  text-decoration: none !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-weight: 400; }
  .ss-kkk-sol-tabitem.active {
    border-color: #539cc1;
    box-shadow: 0 0 0 0.1rem #539cc1; }
    .ss-kkk-sol-tabitem.active .ss-kkk-lp-arrow {
      display: block; }

.ss-kkk-sol-t {
  margin-bottom: 4rem; }
  .ss-kkk-sol-t ul.checklist li:not(:last-child) {
    margin-bottom: 1.5rem; }

.ss-kkk-sol-b {
  margin-top: auto; }
  .ss-kkk-sol-b .btn-outline-primary {
    color: #539cc1 !important;
    background-color: transparent !important;
    border-color: rgba(51, 51, 51, 0.2) !important; }
    .ss-kkk-sol-b .btn-outline-primary > [class*="ss-icon-"] {
      font-size: 1.4rem; }

.ss-kkk-sol-area-c {
  margin-top: 2rem; }
  .ss-kkk-sol-area-c:nth-child(1) {
    margin-top: 0; }
  .ss-kkk-sol-area-c:nth-child(2) {
    margin-top: 2rem; }
    @media (min-width: 768px) {
      .ss-kkk-sol-area-c:nth-child(2) {
        margin-top: 0; } }

.ss-kkk-sol-clps > .p2 {
  padding: 0 2rem 2rem 2rem; }

.ss-kkk-sol-clps-g {
  border: 1px solid #ccc;
  border-radius: 1rem; }

.ss-kkk-sol-clps-t {
  display: flex;
  align-items: center; }
  .ss-kkk-sol-clps-t .ss-checkradio {
    width: 1%;
    flex: 1 1 auto;
    padding: 1rem 2rem;
    align-items: center;
    min-height: 7.4rem; }
  .ss-kkk-sol-clps-t .ss-checkradio-text {
    margin-left: 2rem; }

.ss-kkk-sol-clps-arrow {
  width: 5rem;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #539cc1 !important;
  transform: rotate(180deg);
  transition: transform 0.15s, color 0.15s; }
  .ss-kkk-sol-clps-arrow.collapsed {
    transform: rotate(0deg);
    color: #b3b3b3 !important; }

.ss-gc-a-img {
  display: block;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 2rem;
  overflow: hidden; }
  .ss-gc-a-img::before {
    width: 100%;
    display: block;
    content: '';
    padding-top: 43.6%; }

.ss-gc-b-row > .col-12 {
  margin-bottom: 3rem; }
  .ss-gc-b-row > .col-12:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .ss-gc-b-row > .col-12 {
      margin-bottom: 0; } }

.ss-gc-b-post {
  color: inherit !important;
  text-decoration: none !important; }

.ss-gc-b-thumb {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #f5f5f5;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .ss-gc-b-thumb {
      margin-bottom: 3rem; } }
  .ss-gc-b-thumb::before {
    width: 100%;
    content: '';
    display: block;
    padding-top: 73.17%; }

.ss-gc-c-post {
  margin-bottom: 3rem;
  color: inherit !important;
  text-decoration: none !important; }
  @media (min-width: 992px) {
    .ss-gc-c-post {
      margin-bottom: 0; } }
  .ss-gc-c-post .entry-no {
    font-size: 10rem;
    color: #539cc1;
    display: block;
    line-height: 1;
    margin-bottom: 0.5rem; }
  .ss-gc-c-post .entry-title {
    margin-bottom: 2rem; }

@media (min-width: 992px) {
  .ss-gc-d.right-aligned .ss-gc-d-l {
    align-items: flex-end; } }

@media (min-width: 992px) {
  .ss-gc-d.right-aligned .ss-gc-d-r {
    order: -1; } }

.ss-gc-d-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }

.ss-gc-d-l {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-gc-d-l {
      width: 42%;
      flex: 0 0 42%;
      max-width: 42%;
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .ss-gc-d-l .ss-cc-block {
      max-width: 37rem;
      padding-top: 5.2rem; } }

.ss-gc-d-r {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-d-r {
      width: 58%;
      flex: 0 0 58%;
      max-width: 58%; } }
  .ss-gc-d-r-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem; }
  .ss-gc-d-r-l {
    width: 57.3%;
    flex: 0 0 57.3%;
    max-width: 57.3%;
    padding: 0 1.5rem; }
    @media (max-width: 575px) {
      .ss-gc-d-r-l {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 3rem; } }
    .ss-gc-d-r-l-img {
      display: block;
      position: relative;
      overflow: hidden;
      background-color: #f5f5f5;
      border-radius: 2rem; }
      .ss-gc-d-r-l-img::before {
        width: 100%;
        display: block;
        content: '';
        padding-top: 125.74%; }
  .ss-gc-d-r-r {
    width: 42.7%;
    flex: 0 0 42.7%;
    max-width: 42.7%;
    padding: 0 1.5rem; }
    @media (max-width: 575px) {
      .ss-gc-d-r-r {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%; } }
    .ss-gc-d-r-r-img {
      display: block;
      position: relative;
      overflow: hidden;
      background-color: #f5f5f5;
      border-radius: 2rem;
      margin-bottom: 3rem; }
      .ss-gc-d-r-r-img::before {
        width: 100%;
        display: block;
        content: '';
        padding-top: 86.24%; }

@media (min-width: 992px) {
  .ss-gc-e.right-aligned .ss-gc-e-r {
    order: -1; } }

.ss-gc-e-box {
  padding: 3rem;
  border: 1px solid #ddd;
  border-radius: 2rem; }

.ss-gc-e-row {
  display: flex;
  margin: 0 -1.5rem;
  align-items: center;
  flex-wrap: wrap; }

.ss-gc-e-l {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-e-l {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  .ss-gc-e-l .ss-cc-block {
    margin-bottom: 3rem; }
    @media (min-width: 992px) {
      .ss-gc-e-l .ss-cc-block {
        max-width: 37rem;
        margin: auto; } }

.ss-gc-e-r {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-e-r {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%; } }

.ss-gc-e-img {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 1rem; }
  .ss-gc-e-img::before {
    width: 100%;
    display: block;
    content: '';
    padding-top: 100%; }
  .ss-gc-e-img-grid {
    display: flex;
    margin: 0 -1.5rem;
    flex-wrap: wrap; }
  .ss-gc-e-img-col {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 1.5rem;
    margin-top: 3rem; }
    @media (min-width: 576px) {
      .ss-gc-e-img-col {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; } }
    .ss-gc-e-img-col:nth-child(1) {
      margin-top: 0; }
    @media (min-width: 576px) {
      .ss-gc-e-img-col:nth-child(2) {
        margin-top: 0; } }

@media (min-width: 992px) {
  .ss-gc-f.right-aligned .ss-gc-f-r {
    order: -1; } }

.ss-gc-f-box {
  border: 1px solid #ddd;
  position: relative;
  overflow: hidden;
  border-radius: 2rem; }

.ss-gc-f-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.ss-gc-f-l {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  align-self: stretch; }
  @media (min-width: 992px) {
    .ss-gc-f-l {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%; } }

.ss-gc-f-r {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 992px) {
    .ss-gc-f-r {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .ss-gc-f-r .ss-cc-block {
      max-width: 37rem;
      margin: auto; } }

.ss-gc-f-img {
  display: block;
  position: relative;
  background-color: #f5f5f5;
  height: 100%;
  min-height: 34rem; }
  @media (min-width: 768px) {
    .ss-gc-f-img {
      min-height: 61rem; } }

.ss-gc-g-sep {
  background-color: #ddd;
  opacity: 1;
  margin: 3.3rem 0; }
  @media (min-width: 768px) {
    .ss-gc-g-sep {
      margin: 5.3rem 0; } }

.ss-gc-g-p-l .ss-cc-block {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-gc-g-p-l .ss-cc-block {
      max-width: 37rem;
      margin-bottom: 0; } }

.ss-gc-g-s-col {
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .ss-gc-g-s-col {
      margin-top: 2rem; }
      .ss-gc-g-s-col:nth-child(1), .ss-gc-g-s-col:nth-child(2) {
        margin-top: 0; } }
  .ss-gc-g-s-col > strong {
    font-size: 6.4rem;
    line-height: 1;
    font-weight: 600; }
  .ss-gc-g-s-col > span {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600; }

.ss-gc-h-l .ss-cc-block {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-gc-h-l .ss-cc-block {
      margin-bottom: 0; } }

.ss-gc-i.theme-b .ss-section-inner {
  padding: 5rem 0; }
  @media (min-width: 992px) {
    .ss-gc-i.theme-b .ss-section-inner {
      padding: 8rem 0; } }

@media (min-width: 576px) {
  .ss-gc-i.theme-c .ss-cc-desc {
    column-count: 2;
    column-gap: 3rem; } }

.ss-gc-i.theme-d .ss-cc-desc {
  padding: 3rem;
  background-color: rgba(234, 234, 234, 0.35);
  border-radius: 2rem; }
  @media (min-width: 576px) {
    .ss-gc-i.theme-d .ss-cc-desc {
      column-count: 2;
      column-gap: 3rem; } }
  @media (min-width: 992px) {
    .ss-gc-i.theme-d .ss-cc-desc {
      padding: 6rem; } }

.ss-gc-j-content {
  max-width: 23rem;
  margin: 0 auto 3rem auto;
  text-align: center; }
  @media (min-width: 768px) {
    .ss-gc-j-content {
      margin: auto; } }

.ss-gc-j-head {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1.6rem; }
  .ss-gc-j-head strong {
    font-size: 7rem;
    font-weight: 700;
    line-height: 0.9; }
    @media (min-width: 992px) {
      .ss-gc-j-head strong {
        font-size: 10rem; } }
  .ss-gc-j-head sup {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1;
    margin: 2rem 0 0 0.5rem; }
    @media (min-width: 992px) {
      .ss-gc-j-head sup {
        margin: 2.5rem 0 0 0.5rem; } }

.ss-gc-k-img {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .ss-gc-k-img {
      margin-bottom: 0; } }
  .ss-gc-k-img::before {
    width: 100%;
    display: block;
    content: '';
    padding-top: 112.43%; }

.ss-gc-k-box {
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 1rem;
  min-height: 12.5rem;
  margin-bottom: 1rem; }
  @media (min-width: 992px) {
    .ss-gc-k-box {
      padding: 2.5rem;
      margin-bottom: 2rem; } }
  @media (min-width: 1400px) {
    .ss-gc-k-box {
      padding: 3rem; } }

.ss-gc-l-icons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }
  .ss-gc-l-icons-c {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem; }
    @media (min-width: 576px) {
      .ss-gc-l-icons-c {
        width: 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%; } }
    @media (min-width: 768px) {
      .ss-gc-l-icons-c {
        margin-bottom: 5rem; } }
    .ss-gc-l-icons-c > [class*="ss-icon-"] {
      font-size: 4.1rem;
      color: #539cc1;
      margin-bottom: 1.2rem; }
    .ss-gc-l-icons-c > strong {
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase; }

.ss-gc-m-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }

.ss-gc-m-l {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-m-l {
      width: 55.84%;
      flex: 0 0 55.84%;
      max-width: 55.84%; } }
  .ss-gc-m-l .ss-cc-block {
    margin-top: 2rem; }
    @media (min-width: 992px) {
      .ss-gc-m-l .ss-cc-block {
        max-width: 57rem; } }

.ss-gc-m-r {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-m-r {
      width: 44.16%;
      flex: 0 0 44.16%;
      max-width: 44.16%; } }

.ss-gc-m-img {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin-top: 3rem; }
  @media (min-width: 992px) {
    .ss-gc-m-img {
      margin-top: 0; } }
  .ss-gc-m-img::before {
    width: 100%;
    display: block;
    content: '';
    padding-top: 93%; }

.ss-gc-n.theme-b .ss-section-inner {
  padding: 5rem 0; }
  @media (min-width: 992px) {
    .ss-gc-n.theme-b .ss-section-inner {
      padding: 9rem 0; } }

.ss-gc-n-col {
  text-align: center; }
  @media (max-width: 767px) {
    .ss-gc-n-col:not(:nth-child(1)), .ss-gc-n-col:not(:nth-child(2)) {
      margin-top: 2rem; } }
  .ss-gc-n-col > strong {
    font-size: 4rem;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 0.7rem; }
  .ss-gc-n-col > span {
    font-size: 1.8rem;
    display: block; }

.ss-gc-o .ss-section-bg {
  background-color: #f5f5f5; }

.ss-gc-o .ss-section-inner {
  padding: 5rem 0; }
  @media (min-width: 992px) {
    .ss-gc-o .ss-section-inner {
      padding: 9rem 0; } }
  @media (min-width: 1200px) {
    .ss-gc-o .ss-section-inner {
      padding: 12.8rem 0; } }

.ss-gc-o .ss-cc-block {
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.08);
  padding: 2.4rem; }
  @media (min-width: 576px) {
    .ss-gc-o .ss-cc-block {
      padding: 4.4rem; } }

.ss-gc-p-head {
  max-width: 57rem;
  margin: 0 auto 4rem auto; }
  @media (min-width: 992px) {
    .ss-gc-p-head {
      margin: 0 auto 6rem auto; } }
  .ss-gc-p-head .h2 {
    max-width: 45.5rem;
    margin-left: auto;
    margin-right: auto; }

.ss-gc-p-row > .col-12 {
  margin-bottom: 3rem; }
  .ss-gc-p-row > .col-12:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .ss-gc-p-row > .col-12 {
      margin-bottom: 0; } }

.ss-gc-p-post {
  color: inherit !important;
  text-decoration: none !important;
  border: 1px solid #ddd;
  border-radius: 1rem;
  position: relative;
  overflow: hidden; }
  .ss-gc-p-post .entry-body {
    padding: 2rem;
    text-align: center; }
    @media (min-width: 576px) {
      .ss-gc-p-post .entry-body {
        padding: 3rem; } }
  .ss-gc-p-post .entry-footer {
    margin-top: 2rem; }

.ss-gc-p-thumb {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5; }
  .ss-gc-p-thumb::before {
    width: 100%;
    content: '';
    display: block;
    padding-top: 60.54%; }

.ss-gc-q-row > .col-12 {
  margin-bottom: 3rem; }
  .ss-gc-q-row > .col-12:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .ss-gc-q-row > .col-12 {
      margin-bottom: 0; } }

.ss-gc-q-post {
  color: inherit !important;
  text-decoration: none !important; }
  .ss-gc-q-post .entry-body {
    text-align: center; }

.ss-gc-q-thumb {
  max-width: 15rem;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin: 0 auto 2.6rem auto; }
  .ss-gc-q-thumb::before {
    width: 100%;
    content: '';
    display: block;
    padding-top: 85.333%; }

.ss-gc-r-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }

.ss-gc-r-l {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem; }
  @media (min-width: 992px) {
    .ss-gc-r-l {
      width: 44.16%;
      flex: 0 0 44.16%;
      max-width: 44.16%; } }

.ss-gc-r-r {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .ss-gc-r-r {
      width: 55.84%;
      flex: 0 0 55.84%;
      max-width: 55.84%;
      align-items: flex-end; } }
  .ss-gc-r-r .ss-cc-block {
    margin-top: 2rem; }
    @media (min-width: 992px) {
      .ss-gc-r-r .ss-cc-block {
        max-width: 57rem; } }

.ss-gc-r-img {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .ss-gc-r-img {
      margin-bottom: 0; } }
  .ss-gc-r-img::before {
    width: 100%;
    display: block;
    content: '';
    padding-top: 89.6%; }

.ss-gc-r-subrow .col-6 strong {
  font-size: 4.8rem;
  display: block;
  line-height: 1;
  margin-bottom: 0.5rem; }
  @media (min-width: 768px) {
    .ss-gc-r-subrow .col-6 strong {
      font-size: 6.4rem; } }

.ss-gc-s-head {
  max-width: 57rem;
  margin: 0 auto 4rem auto; }
  @media (min-width: 992px) {
    .ss-gc-s-head {
      margin: 0 auto 6rem auto; } }

.ss-gc-s-prgm {
  padding: 2.4rem;
  border: 1px solid #ddd;
  border-radius: 2rem;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .ss-gc-s-prgm {
      padding: 4.4rem; } }
  .ss-gc-s-prgm:last-child {
    margin-bottom: 0; }
  .ss-gc-s-prgm-l {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem; }
    @media (min-width: 992px) {
      .ss-gc-s-prgm-l {
        width: 33%;
        flex: 0 0 33%;
        max-width: 33%;
        padding-right: 4rem;
        margin-bottom: 0; } }
    .ss-gc-s-prgm-l .badge-row {
      margin-bottom: 2rem; }
    .ss-gc-s-prgm-l > :last-child {
      margin-bottom: 0; }
  .ss-gc-s-prgm-m {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 2rem; }
    @media (min-width: 992px) {
      .ss-gc-s-prgm-m {
        width: 1%;
        flex: 1 1 auto;
        padding: 0 4rem;
        margin-bottom: 0; } }
    .ss-gc-s-prgm-m .checklist {
      max-width: 45rem;
      margin: 0; }
      .ss-gc-s-prgm-m .checklist li:not(:last-child) {
        margin-bottom: 1.5rem; }
  .ss-gc-s-prgm-r {
    flex-shrink: 0;
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .ss-gc-s-prgm-r {
        padding: 0 4rem; } }
    .ss-gc-s-prgm-r .btn {
      min-width: 13rem;
      text-transform: uppercase;
      margin-bottom: 2rem; }
      .ss-gc-s-prgm-r .btn:last-child {
        margin-bottom: 0; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.ss-b-video-poster {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2rem;
  cursor: pointer; }

.ss-b-video-src {
  border-radius: 2rem; }
