.ss {
    &-e {
        &-head {
            @media (min-width: 1200px) {
                margin-bottom: 6.6rem;
            }

            .ss-cc-block {
                text-align: center;

                h2 {
                    margin-bottom: 2rem;
                }

                .ss-cc-desc {
                    max-width: 54rem;
                    margin: 0 auto;
                }
            }
        }

        &-benefits {
            &-box {
                margin: 1.5rem 0;
                padding: 3rem 2.2rem 2.6rem 3rem;
                background: rgba($gray-35, 0.15);
                border-radius: 1rem;
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            &-header {
                margin-bottom: 2.5rem;
                display: flex;
                align-items: center;

                .h4 {
                    margin: 0;
                }
            }

            &-desc {
                margin-bottom: 1.8rem;
            }

            &-footer {
                margin-top: auto;
                display: flex;
                padding-right: 0.8rem;

                > .btn {
                    margin-left: auto;
                }
            }

            &-icon {
                margin-right: 1.3rem;
                width: 4.6rem;
                max-width: 4.6rem;
                flex: 0 0 4.6rem;
                height: 4.6rem;
                font-size: 2.7rem;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                @media (min-width: 1200px) {
                    margin-right: 2.3rem;
                }

                &.light-blue {
                    background-color: $light-blue;
                }

                &.light-green {
                    background-color: $light-green;
                }

                &.green {
                    background-color: $green;
                }
            }
        }
    }
}
